import { useState, useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import { isPersonObjectDataCurrent } from '../utils/MemberUtils';

import useNTContactsData from '../../../state/nationalTeamContacts/UseNTContactsData';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';

import { formatPhoneNumber } from '../../../../common/utils/validation';
import { DEFAULT_ID } from '../../../../common/utils/Constants';

const SELF_CONTACT_NAME = 'Self'; //TODO - Move to Constants?

const INITIAL_STATE = {
  displayDeleteContactModal: false,
  displayDeleteAddressModal: false,
  deleteContactObj: {},
  deleteAddressObj: {}
};

const useNationalTeamContacts = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { basePersonState } = useBasePersonData();
  const { nationalTeamContactsState, getNTContacts, putNTContacts } = useNTContactsData();
  const [gridState, setGridState] = useState([]);
  const [state, setState] = useState(INITIAL_STATE);

  const onAddContactClicked = (event) => {
    try {
      event?.preventDefault();

      navigate(navRoutes.MEMBER_NTCONTACTS_DETAIL?.route);
    } catch (e) {
      console.log(e); //TODO: Add error handling
    }
  };

  // const onAddAddressClicked = (event, personNationalTeamContactId) => {
  //   try {
  //     event?.preventDefault();
  //     const contactObj = nationalTeamContactsState.objData.personNationalTeamContact.find((x) => x.personNationalTeamContactId === personNationalTeamContactId);

  //     navigate(navRoutes.MEMBER_NT_CONTACTS_DETAIL_ADDRESS?.route, { state: { contactObj } });
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const onEditContactClicked = (event, personNationalTeamContactId) => {
    try {
      event?.preventDefault();
      const contactObj = nationalTeamContactsState.objData.personNationalTeamContact.find((x) => x.personNationalTeamContactId === personNationalTeamContactId);

      navigate(navRoutes.MEMBER_NTCONTACTS_DETAIL?.route, { state: { contactObj } });
    } catch (e) {
      console.log(e);
    }
  };

  // const onEditAddressClicked = (event, personNationalTeamContactId, personNationalTeamContactAddressId) => {
  //   try {
  //     event?.preventDefault();
  //     const contactObj = nationalTeamContactsState.objData.personNationalTeamContact.find((x) => x.personNationalTeamContactId === personNationalTeamContactId);
  //     const contactAddressObj = contactObj.personNationalTeamContactAddress.find((x) => x.personNationalTeamContactAddressId === personNationalTeamContactAddressId);

  //     navigate(navRoutes.MEMBER_NT_CONTACTS_DETAIL_ADDRESS?.route, { state: { contactObj, contactAddressObj } });
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const onDeleteContactClicked = (event, contactObj) => {
    try {
      event?.preventDefault();

      setState({
        ...state,
        displayDeleteContactModal: true,
        deleteContactObj: contactObj
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onDeleteAddressClicked = (event, contactAddressObj) => {
    try {
      event?.preventDefault();

      setState({
        ...state,
        displayDeleteAddressModal: true,
        deleteAddressObj: contactAddressObj
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onDeleteContactModalConfirmClicked = async (event) => {
    try {
      event?.preventDefault();
      const personId = nationalTeamContactsState.objData.personId;
      const newPersonNTContactsObj = JSON.parse(JSON.stringify(nationalTeamContactsState.objData));
      const targetIndex = newPersonNTContactsObj.personNationalTeamContact.findIndex((x) => x.personNationalTeamContactId === state.deleteContactObj.contactId);
      newPersonNTContactsObj.personNationalTeamContact[targetIndex].isDeleted = true;

      setState({ ...INITIAL_STATE });

      const putNTContactsResult = await putNTContacts(personId, newPersonNTContactsObj);

      if (putNTContactsResult) {
        setGridState(createNewGridState(putNTContactsResult.objData.personNationalTeamContact));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onDeleteAddressModalConfirmClicked = async (event) => {
    try {
      event?.preventDefault();
      const personId = nationalTeamContactsState.objData.personId;
      const newPersonNTContactsObj = JSON.parse(JSON.stringify(nationalTeamContactsState.objData));
      const targetContactIndex = newPersonNTContactsObj.personNationalTeamContact.findIndex((x) => x.personNationalTeamContactId === state.deleteAddressObj.contactId);
      const targetAddressIndex = newPersonNTContactsObj.personNationalTeamContact[targetContactIndex].personNationalTeamContactAddress.findIndex((x) => x.personNationalTeamContactAddressId === state.deleteAddressObj.addressId);
      newPersonNTContactsObj.personNationalTeamContact[targetContactIndex].personNationalTeamContactAddress.splice(targetAddressIndex, 1);

      setState({ ...INITIAL_STATE });

      const putNTContactsResult = await putNTContacts(personId, newPersonNTContactsObj);

      if (putNTContactsResult) {
        setGridState(createNewGridState(putNTContactsResult.objData.personNationalTeamContact));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onDeleteModalCancelClicked = (event) => {
    try {
      event?.preventDefault();

      setState({ ...INITIAL_STATE });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    async function loadNTContacts() {
      try {
        if (nationalTeamContactsState.isObjLoaded === false
          || !isPersonObjectDataCurrent(basePersonState.objData.personId, nationalTeamContactsState.objData)) {
          const getNTContactsResult = await getNTContacts(basePersonState.objData.personId);

          setGridState(createNewGridState(getNTContactsResult.objData.personNationalTeamContact));
        } else if (nationalTeamContactsState.isObjLoaded === true) {
          setGridState(createNewGridState(nationalTeamContactsState.objData.personNationalTeamContact));
        }
      } catch (e) {
        console.log(e);
      }
    }
    loadNTContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState]);

  function createNewGridState(personNationalTeamContact) {
    const newGridState = [];

    if (Array.isArray(personNationalTeamContact) && personNationalTeamContact.length > 0) {
      for (const contact of personNationalTeamContact) {
        if (contact.contactRelationshipType?.typeName === SELF_CONTACT_NAME && personNationalTeamContact.filter((x) => x.contactRelationshipType?.typeName === SELF_CONTACT_NAME).length === 1) {
          newGridState.unshift({
            contactId: contact.personNationalTeamContactId || DEFAULT_ID,
            name: `${contact.firstName || ''} ${contact.lastName || ''}`,
            relationship: contact.contactRelationshipType?.typeName || '',
            email: contact.emailAddress || '',
            phoneNumber: contact.personNationalTeamContactPhone[0]?.phoneNumber ? formatPhoneNumber(contact.personNationalTeamContactPhone[0]?.phoneNumber) : '',
            canDelete: false,
            contactAddresses: formatAddressesForGrid(contact.personNationalTeamContactAddress)
          });
        } else {
          newGridState.push({
            contactId: contact.personNationalTeamContactId || DEFAULT_ID,
            name: `${contact.firstName || ''} ${contact.lastName || ''}`,
            relationship: contact.contactRelationshipType?.typeName || '',
            email: contact.emailAddress || '',
            phoneNumber: contact.personNationalTeamContactPhone[0]?.phoneNumber ? formatPhoneNumber(contact.personNationalTeamContactPhone[0]?.phoneNumber) : '',
            canDelete: true,
            contactAddresses: formatAddressesForGrid(contact.personNationalTeamContactAddress)
          });
        }
      }
    }

    return newGridState;
  }

  function formatAddressesForGrid(personNationalTeamContactAddress) {
    const newContactAddresses = [];

    if (Array.isArray(personNationalTeamContactAddress) && personNationalTeamContactAddress.length > 0) {
      for (const contactAddress of personNationalTeamContactAddress) {
        let addressTypeNames = '';

        for (const addressType of contactAddress.personNationalTeamContactAddressType) {
          if (addressType.addressType?.typeName) {
            if (addressTypeNames) {
              addressTypeNames += `, ${addressType.addressType?.typeName}`;
            } else {
              addressTypeNames = addressType.addressType?.typeName;
            }
          }
        }

        newContactAddresses.push({
          addressId: contactAddress.personNationalTeamContactAddressId || DEFAULT_ID,
          contactId: contactAddress.personNationalTeamContactId || DEFAULT_ID,
          address: `${contactAddress.address?.address1 || ''}${contactAddress.address?.address2 ? ` ${contactAddress.address.address2}` : ''}`,
          city: contactAddress.address?.city || '',
          stateCode: contactAddress.address?.stateCode || '',
          postalCode: contactAddress.address?.postalCode || '',
          addressTypeNames
        });
      }
    }

    return newContactAddresses;
  }

  return {
    isLoading: nationalTeamContactsState.isObjLoading,
    gridData: gridState,
    state,
    // onAddAddressClicked,
    onAddContactClicked,
    onEditContactClicked,
    // onEditAddressClicked,
    onDeleteContactClicked,
    onDeleteAddressClicked,
    onDeleteContactModalConfirmClicked,
    onDeleteAddressModalConfirmClicked,
    onDeleteModalCancelClicked
  };
};

export default useNationalTeamContacts;