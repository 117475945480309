import { useEffect, useState } from 'react';

import validate from './NTAttachmentFormValidation';

import usePersonNationalTeamFileTypeData from '../../../state/personNationalTeamFileType/UsePersonNationalTeamFileTypeData';

import useForm from '../../../../common/utils/UseForm';
import { formatValidFileTypes, getFileNameFromUrl, openFileInNewTab } from '../../../../common/utils/FileUtils';
import { DEFAULT_ID } from '../../../../common/utils/Constants';

const BASE_UPLOAD_URL = import.meta.env.VITE_MEMBER_DOCS_NATIONAL_TEAM_ATTACHMENTS_BASE_UPLOAD_URL;

const INITIAL_FORM_STATE = {
  fileTypeId: DEFAULT_ID,
  fileTypeName: '',
  attachmentDescription: '',
  attachmentName: '',
  attachmentUrl: '',
  attachmentFile: {}
};

const INITIAL_FILE_TYPE_STATE = {
  hasTemplate: false,
  validFileTypes: [],
  validFileTypeNames: ''
};

const useNTAttachmentForm = (attachmentObj, onSubmitFormCallback, onIsDirtyChanged, onErrorCaught) => {
  const { personNationalTeamFileTypeState, nationalTeamAttachmentTemplateState, getPersonNationalTeamAttachmentFileTypes,
    getNTAttachmentFileTemplate
  } = usePersonNationalTeamFileTypeData();
  const { formState, errorState, isDirty, handleSubmit, updateFormState, setFormData, setFormState,
    setErrors, setIsDirty
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);
  const [fileTypeState, setFileTypeState] = useState(INITIAL_FILE_TYPE_STATE);

  const onDownloadFileTemplateClicked = async (event) => {
    try {
      event?.preventDefault();

      const getNTAttachmentFileTemplateResult = await getNTAttachmentFileTemplate(formState.fileTypeName);

      openFileInNewTab(getNTAttachmentFileTemplateResult.objData?.downloadUrl);
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : setErrors({ formError: e?.message });
    }
  };

  const onFileLoad = (event) => {
    try {
      event?.preventDefault();

      setErrors({ ...errorState, attachmentFile: '' });

      if (event.target.files[0]) {
        const selectedFile = event.target.files[0];

        if (fileTypeState.validFileTypes.includes(selectedFile.type)) {
          setFormState({
            ...formState,
            attachmentFile: selectedFile,
            attachmentName: formState.attachmentName || selectedFile.name // if attachmentName is empty, set to file name
          });
          setIsDirty(true);
        } else {
          setErrors({ ...errorState, attachmentFile: `File type not permitted. Please provide a ${fileTypeState.validFileTypeNames} file.` });
        }
      }
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : setErrors({ formError: e?.message });
    }
  };

  const onFileUnload = (event) => {
    try {
      event?.preventDefault();

      setFormState({
        ...formState,
        attachmentFile: {},
        attachmentUrl: ''
      });
      setIsDirty(true);
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : setErrors({ formError: e?.message });
    }
  };

  const onFileTypeChanged = (fileTypeId, fileTypeName) => {
    try {
      const targetFileType = personNationalTeamFileTypeState.arrayData.find((x) => x.personNationalAttachmentFileTypeId === fileTypeId);

      if (targetFileType) {
        const [validFileTypes, validFileTypeNames] = formatValidFileTypes(targetFileType.validFileTypes);

        if (formState.attachmentFile.size > 0 && validFileTypes.includes(formState.attachmentFile.type)) {
          setFormState({
            ...formState,
            fileTypeId,
            fileTypeName
          });
        } else {
          setFormState({
            ...formState,
            fileTypeId,
            fileTypeName,
            attachmentUrl: '',
            attachmentFile: {}
          });
        }

        setIsDirty(true);
        setFileTypeState({
          ...fileTypeState,
          hasTemplate: targetFileType.hasTemplate,
          validFileTypes,
          validFileTypeNames
        });
      }
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : setErrors({ formError: e?.message });
    }
  };

  useEffect(() => {
    try {
      if (!personNationalTeamFileTypeState.isArrayLoaded) {
        getPersonNationalTeamAttachmentFileTypes();
      } else {
        const targetFileType = attachmentObj
          ? personNationalTeamFileTypeState.arrayData.find((x) => x.personNationalAttachmentFileTypeId === attachmentObj.personNationalAttachmentFileTypeId)
          : personNationalTeamFileTypeState.arrayData[0];
        const [validFileTypes, validFileTypeNames] = formatValidFileTypes(targetFileType.validFileTypes);

        setFormData({
          ...INITIAL_FORM_STATE,
          fileTypeId: targetFileType.personNationalAttachmentFileTypeId,
          fileTypeName: targetFileType.typeName,
          attachmentDescription: attachmentObj?.attachmentDescription || '',
          attachmentName: attachmentObj?.attachmentName || '',
          attachmentUrl: attachmentObj?.attachmentUrl || '',
          attachmentFile: {}
        });

        setFileTypeState({
          ...fileTypeState,
          hasTemplate: targetFileType.hasTemplate,
          validFileTypes,
          validFileTypeNames
        });
      }
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : setErrors({ formError: e?.message });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachmentObj, personNationalTeamFileTypeState.isArrayLoaded]);

  useEffect(() => {
    try {
      if (onIsDirtyChanged) {
        onIsDirtyChanged(isDirty);
      }
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : setErrors({ formError: e?.message });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  return {
    isLoading: nationalTeamAttachmentTemplateState.isObjLoading,
    hasTemplate: fileTypeState.hasTemplate,
    validFileTypeNames: fileTypeState.validFileTypeNames,
    uploadedFileName: formState.attachmentFile?.name || getFileNameFromUrl(formState.attachmentUrl, BASE_UPLOAD_URL) || formState.attachmentName,
    isDirty,
    formState,
    errorState,
    onFileLoad,
    onFileUnload,
    handleSubmit,
    updateFormState,
    onFileTypeChanged,
    onDownloadFileTemplateClicked
  };
};

export default useNTAttachmentForm;