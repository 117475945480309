import { useEffect } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

import validate from './NationalTeamSponsorsDetailValidation';

import usePersonNationalTeamData from '../../../state/personNationalTeam/UsePersonNationalTeamData';
import useSponsorData from '../../../state/sponsor/UseSponsorData';
import useNavRoutes from '../../../../common/state/security/UseNavRoutes';

const useNationalTeamSponsorsDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { personNationalTeamState, putPersonNationalTeam } = usePersonNationalTeamData();
  const { sponsorState } = useSponsorData();
  const { formState, errorState, handleSubmit, updateFormState, setFormData, onValueTextPairChanged,
    isDirty
  } = useForm(getInitialFormState(), submitFormCallback, validate);

  const onSaveClicked = (e) => {
    if (isDirty === false && formState.personSponsorId > 0) {
      onCancelClicked(e);
    }
    else {
      handleSubmit(e);
    }
  };

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_NT_SPONSORS?.route);
  }

  useEffect(() => {
    if (sponsorState.isLoaded === true) {
      if (location.state && location.state.personSponsorId && personNationalTeamState.isObjLoaded === true) {
        const personSponsorId = location.state.personSponsorId;

        // Edit
        if (personSponsorId > 0) {
          const selectedSponsor = personNationalTeamState.objData.sponsors.find(e => e.personSponsorId === personSponsorId);
          setFormData({
            ...formState,
            personSponsorId: personSponsorId,
            sponsorId: selectedSponsor?.sponsorId || Constants.DEFAULT_ID,
            effectiveDate: selectedSponsor?.effectiveDate ? formatDate(selectedSponsor.effectiveDate) : Constants.BLANK_DATE_STRING,
            expirationDate: selectedSponsor?.expirationDate ? formatDate(selectedSponsor.expirationDate) : Constants.BLANK_DATE_STRING
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, personNationalTeamState, sponsorState]);

  function createPutPersonNationaTeamObject(formState) {
    const personNationalTeamObjToPut = JSON.parse(JSON.stringify(personNationalTeamState.objData));

    // Is Edit
    if (formState.personSponsorId > 0) {
      const index = personNationalTeamObjToPut.sponsors.findIndex(e => e.personSponsorId === formState.personSponsorId);

      personNationalTeamObjToPut.sponsors[index] =
      {
        ...personNationalTeamObjToPut.sponsors[index],
        sponsorId: formState.sponsorId,
        effectiveDate: formState.effectiveDate,
        expirationDate: formState.expirationDate,
        sponsorType: null
      }
    }
    // Is Add
    else {
      let sponsors = personNationalTeamObjToPut.sponsors;

      sponsors.push({
        personId: personNationalTeamObjToPut.personId,
        sponsorId: formState.sponsorId,
        effectiveDate: formState.effectiveDate,
        expirationDate: formState.expirationDate,
        sponsor: null
      });

      personNationalTeamObjToPut.sponsors = sponsors;
    }

    return personNationalTeamObjToPut;
  }

  async function submitFormCallback(formState) {
    if (personNationalTeamState.isObjLoading === false) {
      const personNationalTeamObjToPut = createPutPersonNationaTeamObject(formState);
      if (personNationalTeamObjToPut !== null) {
        const putPersonNationalTeamResult = await putPersonNationalTeam(personNationalTeamState.objData.personId, personNationalTeamObjToPut);

        if (putPersonNationalTeamResult) {
          navigate(navRoutes.MEMBER_NT_SPONSORS?.route);
        }
      }
    }
  }

  function getInitialFormState() {
    return {
      personSponsorId: Constants.DEFAULT_ID,
      sponsorId: Constants.DEFAULT_ID,
      effectiveDate: Constants.BLANK_DATE_STRING,
      expirationDate: Constants.BLANK_DATE_STRING
    };
  }

  return {
    Constants,
    personNationalTeamState,
    formState,
    errorState,
    sponsorState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    onSaveClicked,
    onCancelClicked
  };
};

export default useNationalTeamSponsorsDetail;