import React, { Fragment } from 'react';

import useMemberConvertToLifeMembership from './UseMemberConvertToLifeMembership';
import LargeCancelMembershipGrid from './LargeMemberConvertToLifeMembershipGrid';
import SmallCancelMembershipGrid from './SmallConvertToLifeMembershipGrid';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';
import { FormatMoneyWithSymbol } from '../../../../common/utils/FormatMoney';

import global from '../../../../common/components/GlobalStyle.module.css';

const MemberConvertToLifeMembership = () => {
  const {
    modalState,
    onModalCanceled,
    onOpenConvertToLifeModalClicked,
    onConvertToLifeMembership,
    personConvertToLifeMembershipState,
  } = useMemberConvertToLifeMembership();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Convert to Life Membership</Headings.H3>
        </div>
      </div>
      <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
        <div className='col-xs-12'>
          <span className={global.UsasLabel}>Membership(s) To Convert</span>
          <LargeCancelMembershipGrid state={personConvertToLifeMembershipState} onOpenConvertToLifeModalClicked={onOpenConvertToLifeModalClicked} />
          <SmallCancelMembershipGrid state={personConvertToLifeMembershipState} onOpenConvertToLifeModalClicked={onOpenConvertToLifeModalClicked} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={720}
        title="Convert To Life Membership"
        onModalCanceled={onModalCanceled}
        displayPopUp={modalState.displayPopUp}>
        <div className="row">
          <div className="col-xs-12">
            <p className={['col-xs-12 text-center', global.Bold].join(' ')}>You are about to convert your membership to a lifetime membership</p>
            <p className={['col-xs-12 text-center', global.Bold].join(' ')}>Team Name (Code) - {modalState.teamName} ({modalState.teamCode})</p>
            <p className='col-xs-12 text-center'><strong>Upgraded Membership:</strong> {modalState.offeringName}</p>
            <p className='col-xs-12 text-center'><strong>Price:</strong> {FormatMoneyWithSymbol(1000)}</p>
            <p className={['col-xs-12 text-center', global.Bold].join(' ')}>Are you sure you want to convert this membership? You cannot undo this action.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin text-center">
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>&nbsp;
            <PrimaryButton type="button" onClick={(e) => onConvertToLifeMembership(e)}>Yes, Convert Membership</PrimaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={personConvertToLifeMembershipState?.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={personConvertToLifeMembershipState?.isSaving} />
    </Fragment >
  );
};

export default MemberConvertToLifeMembership;