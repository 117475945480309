import { Fragment } from 'react';

import useNationalTeamContacts from './UseNationalTeamContacts';

import NTContactsGrid from '../../../components/grids/nationalTeamContacts/NTContactsGrid';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import { LOADING_MSG } from '../../../../common/utils/Constants';

const NationalTeamContactsRead = () => {
  const {
    isLoading,
    gridData
  } = useNationalTeamContacts();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>National Team Contact Info</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <NTContactsGrid
            gridData={gridData}
            isLoading={isLoading} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default NationalTeamContactsRead;