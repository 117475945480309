import { useEffect } from 'react';

import { isPersonObjectDataCurrent } from '../utils/MemberUtils';

import usePersonNationalTeamData from '../../../state/personNationalTeam/UsePersonNationalTeamData';

import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';

const useNationalTeamFrequentFlyer = () => {
  const { basePersonState } = useBasePersonData();
  const { personNationalTeamState, getPersonNationalTeam, putPersonNationalTeam } = usePersonNationalTeamData();

  const onSubmitFrequentFlyerForm = (frequentFlyerDetails) => {
    const personId = basePersonState.objData.personId;
    const newPersonNTObj = JSON.parse(JSON.stringify(personNationalTeamState.objData));
    newPersonNTObj.frequentFlyerDetails = frequentFlyerDetails;

    putPersonNationalTeam(personId, newPersonNTObj);
  };

  useEffect(() => {
    if (personNationalTeamState.isObjLoaded === false
      || !isPersonObjectDataCurrent(basePersonState.objData.personId, personNationalTeamState.objData)) {
      getPersonNationalTeam(basePersonState.objData.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState]);

  return {
    isLoading: personNationalTeamState.isObjLoading,
    personId: basePersonState.objData.personId,
    gridData: personNationalTeamState.objData?.frequentFlyerDetails || [],
    onSubmitFrequentFlyerForm
  };
};

export default useNationalTeamFrequentFlyer;