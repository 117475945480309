import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import usePersonNationalTeamData from '../../../state/personNationalTeam/UsePersonNationalTeamData';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';

import {
  DEFAULT_MAX_DATE, DEFAULT_PHONE_NUMBER_AREA_CODE, DEFAULT_PHONE_NUMBER_AREA_CODE_MEXICO
} from '../../../../common/utils/Constants';

const INITIAL_STATE = {
  supportStaffObj: null
};

const useNationalTeamSupportStaffDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { personNationalTeamState, putPersonNationalTeam } = usePersonNationalTeamData();
  const [state, setState] = useState(INITIAL_STATE);

  const onCancelClicked = (event) => {
    event?.preventDefault();

    navigate(navRoutes.MEMBER_NT_SUPPORT_STAFF?.route);
  };

  const onSubmitSupportStaffAddForm = async (formState) => {
    const personId = personNationalTeamState.objData.personId;
    const newNTPersonObj = JSON.parse(JSON.stringify(personNationalTeamState.objData));
    newNTPersonObj.supportStaffMembers.push({
      personId,
      firstName: formState.firstName.trim(),
      lastName: formState.lastName.trim(),
      email: formState.email.trim(),
      phoneNumber: formState.phoneNumber.trim() !== DEFAULT_PHONE_NUMBER_AREA_CODE && formState.phoneNumber.trim() !== DEFAULT_PHONE_NUMBER_AREA_CODE_MEXICO ? formState.phoneNumber.trim() : '',
      nationalTeamSupportStaffType: [{
        effectiveDate: formState.effectiveDate,
        expirationDate: formState.hasNoExpirationDate === true ? DEFAULT_MAX_DATE : formState.expirationDate,
        supportStaffTypeId: formState.supportStaffTypeId,
      }]
    });

    const putPersonNationalTeamResult = await putPersonNationalTeam(personId, newNTPersonObj);

    if (putPersonNationalTeamResult) {
      navigate(navRoutes.MEMBER_NT_SUPPORT_STAFF?.route);
    }
  };

  const onSubmitSupportStaffEditForm = async (formState) => {
    const personId = personNationalTeamState.objData.personId;
    const newNTPersonObj = JSON.parse(JSON.stringify(personNationalTeamState.objData));
    const targetIndex = newNTPersonObj.supportStaffMembers.findIndex((x) => x.nationalTeamSupportStaffId === state.supportStaffObj.nationalTeamSupportStaffId);
    newNTPersonObj.supportStaffMembers[targetIndex] = {
      ...newNTPersonObj.supportStaffMembers[targetIndex],
      firstName: formState.firstName.trim(),
      lastName: formState.lastName.trim(),
      email: formState.email.trim(),
      phoneNumber: formState.phoneNumber.trim() !== DEFAULT_PHONE_NUMBER_AREA_CODE && formState.phoneNumber.trim() !== DEFAULT_PHONE_NUMBER_AREA_CODE_MEXICO ? formState.phoneNumber.trim() : ''
    };

    const putPersonNationalTeamResult = await putPersonNationalTeam(personId, newNTPersonObj);

    if (putPersonNationalTeamResult) {
      navigate(navRoutes.MEMBER_NT_SUPPORT_STAFF?.route);
    }

  };

  useEffect(() => {
    if (location.state?.supportStaffObj) {
      setState({ ...state, supportStaffObj: location.state.supportStaffObj });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading: personNationalTeamState.isObjLoading,
    supportStaffObj: state.supportStaffObj || null,
    onCancelClicked,
    onSubmitSupportStaffAddForm,
    onSubmitSupportStaffEditForm,
  };
};

export default useNationalTeamSupportStaffDetail;