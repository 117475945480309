import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import useNTAttachmentData from '../../../state/nationalTeamAttachment/UseNTAttachmentData';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';

import { openFileInNewTab } from '../../../../common/utils/FileUtils';

const INITIAL_STATE = {
  attachmentObj: undefined
};

const useNationalTeamAttachmentDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { basePersonState } = useBasePersonData();
  const { nationalTeamAttachmentUploadState, nationalTeamAttachmentUpdateState, nationalTeamAttachmentDownloadState,
    nationalTeamAttachmentState, postNTAttachment, putNTAttachment, getNTAttachment, getNTAttachmentsByPersonId
  } = useNTAttachmentData();
  const [state, setState] = useState(INITIAL_STATE);

  const onCancelClicked = (event) => {
    event?.preventDefault();

    navigate(navRoutes.MEMBER_NT_ATTACHMENTS?.route);
  };

  const onDownloadFileClicked = async (event, attachmentUrl) => {
    event?.preventDefault();

    const getNTAttachmentResult = await getNTAttachment(attachmentUrl);

    openFileInNewTab(getNTAttachmentResult.objData?.downloadUrl);
  };

  const onSubmitAttachmentForm = async (formState) => {
    if (state.attachmentObj) {
      const newAttachmentObj = {
        ...state.attachmentObj,
        personId: basePersonState.objData.personId,
        attachmentDescription: formState.attachmentDescription,
        attachmentName: formState.attachmentName
      };

      const putNTAttachmentResult = await putNTAttachment(newAttachmentObj.personNationalAttachmentId, newAttachmentObj, formState.attachmentFile?.size > 0 ? formState.attachmentFile : undefined);

      if (putNTAttachmentResult) {
        await getNTAttachmentsByPersonId(basePersonState.objData.personId);

        navigate(navRoutes.MEMBER_NT_ATTACHMENTS?.route);
      }
    } else {
      const newAttachmentObj = {
        personId: basePersonState.objData.personId,
        personNationalAttachmentFileTypeId: formState.fileTypeId,
        attachmentDescription: formState.attachmentDescription,
        attachmentName: formState.attachmentName
      };

      const postNTAttachmentResult = await postNTAttachment(formState.attachmentFile, newAttachmentObj);

      if (postNTAttachmentResult) {
        await getNTAttachmentsByPersonId(basePersonState.objData.personId);

        navigate(navRoutes.MEMBER_NT_ATTACHMENTS?.route);
      }
    }
  }

  useEffect(() => {
    if (location?.state?.attachmentObj) {
      setState({
        ...state,
        attachmentObj: location.state.attachmentObj
      });
    } else {
      setState({
        ...state,
        attachmentObj: null
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isSaving: nationalTeamAttachmentUploadState.isObjLoading || nationalTeamAttachmentUpdateState.isObjLoading,
    isLoading: nationalTeamAttachmentDownloadState.isObjLoading || nationalTeamAttachmentState.isArrayLoading,
    attachmentObj: state.attachmentObj,
    onCancelClicked,
    onDownloadFileClicked,
    onSubmitAttachmentForm
  };
};

export default useNationalTeamAttachmentDetail;
