import useNTSupportStaffEditForm from './UseNTSupportStaffEditForm';

import Input from '../../../../common/components/inputs/Input';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import InternationalPhoneInput from '../../../../common/components/inputs/InternationalPhoneInput';

import global from '../../../../common/components/GlobalStyle.module.css';

const NTSupportStaffEditForm = ({ supportStaffObj, submitButtonText = 'Save', secondaryButtonText = 'Cancel',
  onSubmitFormCallback, onSecondaryButtonClicked, onIsDirtyChanged = undefined, onErrorCaught = undefined }) => {
  const {
    isDirty,
    formState,
    errorState,
    handleSubmit,
    updateFormState
  } = useNTSupportStaffEditForm(supportStaffObj, onSubmitFormCallback, onIsDirtyChanged, onErrorCaught);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="First Name*"
            name="firstName"
            value={formState.firstName}
            error={errorState.firstName}
            message={errorState.firstName}
            onChange={(value) => { updateFormState('firstName', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Last Name*"
            name="lastName"
            value={formState.lastName}
            error={errorState.lastName}
            message={errorState.lastName}
            onChange={(value) => { updateFormState('lastName', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Email"
            name="email"
            value={formState.email}
            error={errorState.email}
            message={errorState.email}
            onChange={(value) => { updateFormState('email', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <InternationalPhoneInput
            label="Phone Number"
            name="phoneNumber"
            value={formState.phoneNumber}
            error={errorState.phoneNumber}
            message={errorState.phoneNumber}
            onChange={(value) => updateFormState('phoneNumber', value)} />
        </div>
      </div>
      {errorState.errorOnSubmitAction &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
          </div>
        </div>
      }
      {errorState.formError &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{errorState.formError || ''}</p>
          </div>
        </div>
      }
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="submit" isDisabled={isDirty === false}>{submitButtonText}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default NTSupportStaffEditForm;
