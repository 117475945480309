import Textarea from '../../../../common/components/inputs/Textarea';

import DeleteIcon from '../../../../common/components/icons/DeleteIcon';

import global from '../../../../common/components/GlobalStyle.module.css';
import style from './FunFactsFormGrid.module.css';

const GridRow = ({ funFact, onUpdateFormGridState }) => (
  <tr>
    <td>{funFact.name || ''}</td>
    <td style={{ width: "60%" }}>
      <Textarea
        className={funFact.value ? style.GridTextarea : [style.GridTextarea, style.Blank].join(' ')}
        name={`value${funFact.id}`}
        value={funFact.value}
        onChange={(newValue) => { onUpdateFormGridState(funFact.id, newValue) }} />
    </td>
    <td>
      {funFact.hasValue === true &&
        <button className={global.IconButtonMargin} type="button" onClick={() => onUpdateFormGridState(funFact.id, '')}>
          <DeleteIcon toolTipText='Clear Value' />
        </button>
      }
    </td>
  </tr>
);

const FunFactsFormGridLarge = ({ formGridState, onUpdateFormGridState }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Fun Fact</th>
        <th>Description</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(formGridState) && formGridState.length > 0
        ? formGridState.map((funFact, i) =>
          <GridRow key={i} funFact={funFact} onUpdateFormGridState={onUpdateFormGridState} />)
        : <tr><td colSpan="3">No Fun Facts Info</td></tr>
      }
    </tbody>
  </table>
);

export default FunFactsFormGridLarge;