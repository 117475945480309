/* eslint-disable no-debugger */
import React, { Fragment } from 'react';

import useMemberTimes from './UseMemberTimes';
import MemberTimesGrid from './MemberTimesGrid';

import ReportPeriodDropdown from '../../../../common/components/dropdowns/reportPeriodDropdown/ReportPeriodDropdown';
import Headings from '../../../../common/components/headings/Headings';
import Dropdown from '../../../../common/components/dropdowns/Dropdown';
import YesNoSwitch from '../../../../common/components/yesNoSwitch/YesNoSwitch';
import Combobox from '../../../../common/components/comboboxes/Combobox';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import ViewReport from '../../../../common/components/embeddedReports/ViewReport';

import Constants from '../../../../common/utils/Constants';

import GlobalStyle from '../../../../common/components/GlobalStyle.module.css';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

const MemberTimes = () => {
  const {
    personSwimTimeState,
    environmentVariableState,
    formState,
    errorState,
    gridState,
    filterState,
    organizationState,
    isStateLoading,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onEditTimeClicked,
    USAS_ORGANIZATION_ID,
    onViewReport,
    viewState,
    setViewState,
    INITIAL_VIEW_STATE
  } = useMemberTimes();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Times</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className='col-xs-12 col-sm-6 col-md-4'>
            <Dropdown 
              label="Organization"
              name="organization"
              options={organizationState.timeRecognizedOrganizationsAsOptions}
              value={formState.organizationId}
              error={errorState.organizationId}
              message={errorState.organizationId}
              isLoading={isStateLoading}
              onChange={(id, value) => { onValueTextPairChanged(id, 'organizationId', value, 'organizationName'); }} />
          </div>
          <div className='col-xs-12 col-sm-6 col-md-4'>
            <Combobox
              label="Event Course"
              name="eventCourse"
              items={filterState.eventCourses}
              valueToMatch={formState.eventCourseName}
              error={errorState.eventCourseId}
              message={errorState.eventCourseId}
              isLoading={isStateLoading}
              onChange={(id, value) => { onValueTextPairChanged(id, 'eventCourseId', value, 'eventCourseName'); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label="Session"
              name="session"
              options={filterState.sessions}
              value={formState.sessionId}
              error={errorState.sessionId}
              message={errorState.sessionId}
              isLoading={isStateLoading}
              onChange={(id, value) => { onValueTextPairChanged(id, 'sessionId', value, 'sessionName'); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReportPeriodDropdown
              label='Season'
              name='reportPeriodId'
              value={formState.reportPeriodId}
              error={errorState.reportPeriodId}
              message={errorState.reportPeriodId}
              onChange={(newValue, newValueLabel) =>
                onValueTextPairChanged(newValue, 'reportPeriodId', newValueLabel, 'reportPeriodName')}
              organizationId={USAS_ORGANIZATION_ID}
              defaultOption={true} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <YesNoSwitch
              label="Show Best Times Only?"
              name="showBestTimes"
              checked={formState.showBestTimes}
              error={errorState.showBestTimes}
              message={errorState.showBestTimes}
              onChange={(value) => { onFormValueChanged('showBestTimes', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <p style={{ marginTop: "30px" }}><b>Total Times:</b> {Array.isArray(gridState.arrayData) ? gridState.arrayData.length : 0}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <p className={GlobalStyle.ErrorMessage}>{errorState.filter ? errorState.filter : ''}</p>
          </div>
        </div>
        <div className='row'>
          <div className="col-xs-12">
            <PrimaryButton type="submit">Search for Times</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onViewReport}>Individual Times Report</SecondaryButton>
          </div>
        </div>
        <div className="row usas-extra-bottom-margin">
          <div className='col-xs-12'>
            <MemberTimesGrid
              gridData={gridState.arrayData}
              isLoading={gridState.isArrayLoading}
              onEdit={onEditTimeClicked} />
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={personSwimTimeState.isArrayLoading || environmentVariableState.isLoading || gridState.isArrayLoading} />
      {viewState.showReport === true &&
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <ViewReport
              reportInfoId={viewState.reportInfoId}
              reportParameters={viewState.reportParameters}
              onBackToGrid={() => setViewState({ INITIAL_VIEW_STATE })}
            />
          </div>
        </div>
      }
    </Fragment>
  );
};

export default MemberTimes;