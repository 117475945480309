import { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';

import global from '../../../../common/components/GlobalStyle.module.css';

const DetailGrid = ({ attachmentDescription }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th colSpan='3'>Description</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td colSpan='3'>{attachmentDescription}</td>
      </tr>
    </tbody>
  </table>
);

const GridRow = ({ attachment, onEditClicked, onDeleteClicked }) => (
  <Fragment>
    <tr>
      <td>{attachment.personNationalAttachmentFileTypeName || ''}</td>
      <td>{attachment.attachmentName || ''}</td>
      <td className='pull-right'>
        <button className={global.IconButtonMargin} type="button" onClick={(e) => onEditClicked(e, attachment)}>
          <EditIcon toolTipText='Edit Attachment' />
        </button>
        <button className={global.IconButtonMargin} type="button" onClick={(e) => onDeleteClicked(e, attachment)}>
          <DeleteIcon toolTipText='Delete Attachment' />
        </button>
      </td>
    </tr>
    {attachment.attachmentDescription && (
      <tr className={global.Expanded}>
        <td colSpan="3">
          <DetailGrid attachmentDescription={attachment.attachmentDescription} />
        </td>
      </tr>
    )}
  </Fragment>
);

const NTAttachmentsGridLarge = ({ gridData, isLoading, onEditClicked, onDeleteClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>File Type</th>
        <th>File Name</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="3">Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0
          ? gridData.map((attachment, i) => <GridRow key={i} attachment={attachment} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />)
          : <tr><td colSpan="3">No Attachments</td></tr>
      }
    </tbody>
  </table>
);

export default NTAttachmentsGridLarge;