import { DEFAULT_ID } from '../../../../common/utils/Constants';
import { isValidNumber } from '../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  // Height
  if (!(formState.height?.trim())) {
    errors.height = 'Height is required';
  } else {
    const height = parseInt(formState.height);

    if (isNaN(height) === true || isValidNumber(height) === false) {
      errors.height = 'Height must be a valid number';
    } else if (height < 48 || height > 84) {
      errors.height = 'Height is not in a valid range';
    }
  }

  // Weight
  if (!(formState.weight?.trim())) {
    errors.weight = 'Weight is required';
  } else {
    const weight = parseInt(formState.weight);

    if (isNaN(weight) === true || isValidNumber(weight) === false) {
      errors.weight = 'Weight must be a valid number';
    } else if (weight < 50 || weight > 250) {
      errors.weight = 'Weight is not in a valid range';
    }
  }

  // College Graduation Year
  if (formState.collegeGraduationYear?.trim()) {
    const collegeGraduationYear = parseInt(formState.collegeGraduationYear);
    if (isNaN(collegeGraduationYear) === true || isValidNumber(collegeGraduationYear) === false) {
      errors.collegeGraduationYear = 'A valid 4 digit year must be entered';
    } else if (collegeGraduationYear < 1900 || collegeGraduationYear > 2500) {
      errors.collegeGraduationYear = 'A valid 4 digit year must be entered';
    }
  }

  // High School Graduation Year
  if (!(formState.highSchoolGraduationYear?.trim())) {
    errors.highSchoolGraduationYear = 'High School Graduation is required';
  } else {
    const highSchoolGraduationYear = parseInt(formState.highSchoolGraduationYear);

    if (isNaN(highSchoolGraduationYear) === true || isValidNumber(highSchoolGraduationYear) === false) {
      errors.highSchoolGraduationYear = 'A valid 4 digit year must be entered';
    } else if (highSchoolGraduationYear < 1900 || highSchoolGraduationYear > 2500) {
      errors.highSchoolGraduationYear = 'A valid 4 digit year must be entered';
    }
  }

  // College Name
  if (formState.college?.trim()) {
    if (formState.college.trim().length > 200) {
      errors.college = 'A valid College Name must be entered';
    }
  }

  // High School Name
  if (!(formState.highSchool?.trim())) {
    errors.highSchool = 'High School Name is required';
  } else if (formState.highSchool.trim().length > 200) {
    errors.highSchool = 'A valid High School Name must be entered';
  }

  // High School City
  if (!(formState.highSchoolCity?.trim())) {
    errors.highSchoolCity = 'High School City is required';
  } else if (formState.highSchoolCity.trim().length > 200) {
    errors.highSchoolCity = 'A valid High School City must be entered';
  }

  // Hometown
  if (!(formState.hometown?.trim())) {
    errors.hometown = 'Hometown is required';
  } else if (formState.hometown.trim().length > 200) {
    errors.hometown = 'A valid Hometown must be entered';
  }

  // Residence
  if (!(formState.residence?.trim())) {
    errors.residence = 'Residence is required';
  } else if (formState.residence.trim().length > 200) {
    errors.residence = 'A valid Residence must be entered';
  }

  // Birthplace
  if (!(formState.birthplace?.trim())) {
    errors.birthplace = 'Birthplace is required';
  } else if (formState.birthplace.trim().length > 200) {
    errors.birthplace = 'A valid Birthplace must be entered';
  }

  // Birthplace Country
  if (formState.birthplaceCountryName?.trim()) {
    if (formState.birthplaceCountry === DEFAULT_ID || formState.birthplaceCountry === '') {
      errors.birthplaceCountry = 'Must be a valid country';
    }
  } else {
    errors.birthplaceCountry = 'Birthplace Country is required';
  }

  // High School State
  if (formState.highSchoolStateName?.trim()) {
    if (formState.highSchoolState === DEFAULT_ID || formState.highSchoolState === '') {
      errors.highSchoolState = 'Must be a valid state';
    }
  } else {
    errors.highSchoolState = 'High School State is required';
  }

  // Hometown State
  if (formState.hometownStateName?.trim()) {
    if (formState.hometownState === DEFAULT_ID || formState.hometownState === '') {
      errors.hometownState = 'Must be a valid state';
    }
  } else {
    errors.hometownState = 'Hometown State is required';
  }

  // Residence State
  if (formState.residenceStateName?.trim()) {
    if (formState.residenceState === DEFAULT_ID || formState.residenceState === '') {
      errors.residenceState = 'Must be a valid state';
    }
  } else {
    errors.residenceState = 'Residence State is required';
  }

  // Birthplace State
  if (formState.isBirthCountryUSA === true || formState.isBirthCountryMexico === true || formState.isBirthCountryCanada === true) {
    if (formState.birthplaceStateName?.trim()) {
      if (formState.birthplaceState === DEFAULT_ID || formState.birthplaceState === '') {
        errors.birthplaceState = 'Must be a valid state';
      }
    } else {
      errors.birthplaceState = 'Birthplace State is required';
    }
  }

  return errors;
};

const NTRegistrationBioInfoFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default NTRegistrationBioInfoFormValidation;