import { createContext, useState } from 'react';

import PersonNationalTeamData from './PersonNationalTeamData';

export const PersonNationalTeamStateContext = createContext();

const PersonNationalTeamContextProvider = ({ children }) => {
  const stateHook = useState(PersonNationalTeamData.INITIAL_PERSON_NATIONAL_TEAM_STATE);

  return (
    <PersonNationalTeamStateContext.Provider value={stateHook}>
      {children}
    </PersonNationalTeamStateContext.Provider>
  );
};

export default PersonNationalTeamContextProvider;