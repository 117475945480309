import { useEffect } from 'react';

import validate from './NTSupportStaffEditRoleFormValidation';

import useForm from '../../../../common/utils/UseForm';
import { BLANK_DATE_STRING, DEFAULT_MAX_DATE } from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

const INITIAL_FORM_STATE = {
  effectiveDate: BLANK_DATE_STRING,
  expirationDate: BLANK_DATE_STRING,
  hasNoExpirationDate: false
};

const useNTSupportStaffEditRoleForm = (supportStaffRoleObj, onSubmitFormCallback, onIsDirtyChanged, onErrorCaught) => {
  const { isDirty, formState, errorState, handleSubmit, updateFormState, setFormData, setErrors
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  useEffect(() => {
    try {
      if (supportStaffRoleObj) {
        const hasNoExpirationDate = supportStaffRoleObj.expirationDate
          ? formatDate(supportStaffRoleObj.expirationDate) === DEFAULT_MAX_DATE
          : false;

        setFormData({
          ...INITIAL_FORM_STATE,
          effectiveDate: supportStaffRoleObj.effectiveDate ? formatDate(supportStaffRoleObj.effectiveDate) : BLANK_DATE_STRING,
          expirationDate: supportStaffRoleObj.expirationDate ? formatDate(supportStaffRoleObj.expirationDate) : BLANK_DATE_STRING,
          hasNoExpirationDate
        });
      }
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : setErrors({ formError: e?.message });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportStaffRoleObj]);

  useEffect(() => {
    try {
      if (onIsDirtyChanged) {
        onIsDirtyChanged(isDirty);
      }
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : setErrors({ formError: e?.message });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  return {
    isDirty,
    formState,
    errorState,
    handleSubmit,
    updateFormState
  };
};

export default useNTSupportStaffEditRoleForm;