import { useEffect, useState } from 'react';

import useNTAttachmentData from '../../../state/nationalTeamAttachment/UseNTAttachmentData';
import usePersonNationalTeamFileTypeData from '../../../state/personNationalTeamFileType/UsePersonNationalTeamFileTypeData';

import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';

import { formatValidFileTypes, getFileNameFromUrl, openFileInNewTab } from '../../../../common/utils/FileUtils';

const PASSPORT_FILE_TYPE_ID = 3;

const INITIAL_STATE = {
  passportAttachmentObj: undefined,
  passportFileName: '',
  displaySamplePassport: false,
  displayValidationPopup: false,
  displayRemovePopup: false,
  validationMessage: ''
};

const INITIAL_FILE_TYPE_STATE = {
  validFileTypes: [],
  validFileTypeNames: ''
};

const useMemberPassportUpload = () => {
  const { basePersonState } = useBasePersonData();
  const { personNationalTeamFileTypeState, getPersonNationalTeamAttachmentFileTypes,
  } = usePersonNationalTeamFileTypeData();
  const { nationalTeamAttachmentState, nationalTeamAttachmentDownloadState, nationalTeamAttachmentUploadState, nationalTeamAttachmentDeleteState,
    getNTAttachmentsByPersonId, getNTAttachment, postNTAttachment, deleteNTAttachment, BASE_UPLOAD_URL
  } = useNTAttachmentData();
  const [state, setState] = useState(INITIAL_STATE);
  const [fileTypeState, setFileTypeState] = useState(INITIAL_FILE_TYPE_STATE);

  const onUploadPassport = async (event) => {
    event.preventDefault();

    if (event.target.files[0]) {
      const file = event.target.files[0];
      const fileType = event.target.files[0].type;
      const fileName = event.target.files[0].name;
      const personId = basePersonState.objData.personId;
      if (fileTypeState.validFileTypes.includes(fileType)) {
        const newAttachmentObj = {
          personId,
          personNationalAttachmentFileTypeId: PASSPORT_FILE_TYPE_ID,
          attachmentDescription: '',
          attachmentName: fileName
        };

        const postNTAttachmentResult = await postNTAttachment(file, newAttachmentObj);

        if (postNTAttachmentResult) {
          await getNTAttachmentsByPersonId(basePersonState.objData.personId);
        }
      } else {
        setState({
          ...state,
          displayValidationPopup: true,
          validationMessage: `File type not permitted. Please provide a ${fileTypeState.validFileTypeNames} file.`
        });
      }
    }
  };

  const onDownloadUploadedFile = async (event) => {
    event?.preventDefault();

    const getNTAttachmentResult = await getNTAttachment(state.passportAttachmentObj.attachmentUrl);

    openFileInNewTab(getNTAttachmentResult.objData?.downloadUrl);
  };

  const onViewSamplePassport = () => {
    setState({ ...state, displaySamplePassport: !state.displaySamplePassport });
  };

  const onRemoveFileClicked = (event) => {
    event?.preventDefault();

    setState({
      ...state,
      displayRemovePopup: true
    });
  };

  const onRemovePopupConfirmClicked = async (event) => {
    event?.preventDefault();

    const attachmentId = state.passportAttachmentObj.personNationalAttachmentId;
    const attachmentUrl = state.passportAttachmentObj.attachmentUrl;

    setState({ ...INITIAL_STATE });

    const deleteNTAttachmentResult = await deleteNTAttachment(attachmentId, attachmentUrl);

    if (deleteNTAttachmentResult) {
      await getNTAttachmentsByPersonId(basePersonState.objData.personId);
    }
  };

  const onPopupCancelClicked = (event) => {
    event?.preventDefault();

    setState({
      ...state,
      displayRemovePopup: false,
      displayValidationPopup: false,
      validationMessage: ''
    });
  };

  useEffect(() => {
    if (personNationalTeamFileTypeState.isArrayLoaded === false) {
      getPersonNationalTeamAttachmentFileTypes();
    } else if (nationalTeamAttachmentState.isArrayLoaded === false && basePersonState.isObjLoaded === true) {
      getNTAttachmentsByPersonId(basePersonState.objData.personId);
    } else if (nationalTeamAttachmentState.isArrayLoaded === true) {
      const passportAttachmentObj = nationalTeamAttachmentState.arrayData.find((x) => x.personNationalAttachmentFileTypeId === PASSPORT_FILE_TYPE_ID);
      const targetFileType = personNationalTeamFileTypeState.arrayData.find((x) => x.personNationalAttachmentFileTypeId === PASSPORT_FILE_TYPE_ID);
      const [validFileTypes, validFileTypeNames] = formatValidFileTypes(targetFileType.validFileTypes);

      if (passportAttachmentObj) {
        setState({
          ...state,
          passportAttachmentObj,
          passportFileName: getFileNameFromUrl(passportAttachmentObj.attachmentUrl, BASE_UPLOAD_URL)
        });
      }

      setFileTypeState({
        ...fileTypeState,
        validFileTypes,
        validFileTypeNames
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState, personNationalTeamFileTypeState.isArrayLoaded, nationalTeamAttachmentState.isArrayLoaded]);

  return {
    isSaving: nationalTeamAttachmentUploadState.isObjLoading || nationalTeamAttachmentDeleteState.isObjLoading,
    isLoading: nationalTeamAttachmentState.isArrayLoading || nationalTeamAttachmentDownloadState.isObjLoading || personNationalTeamFileTypeState.isArrayLoading,
    state,
    fileTypeState,
    onUploadPassport,
    onDownloadUploadedFile,
    onViewSamplePassport,
    onRemoveFileClicked,
    onRemovePopupConfirmClicked,
    onPopupCancelClicked
  };
};

export default useMemberPassportUpload;