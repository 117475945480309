import { Fragment } from 'react';

import NTContactsGridLarge from './NTContactsGridLarge';
import NTContactsGridSmall from './NTContactsGridSmall';

const NTContactsGrid = ({ gridData, isLoading, onAddAddressClicked, onEditClicked, onEditAddressClicked,
  onDeleteClicked, onDeleteAddressClicked }) => (
  <Fragment>
    <NTContactsGridLarge
      gridData={gridData}
      isLoading={isLoading}
      onAddAddressClicked={onAddAddressClicked}
      onEditClicked={onEditClicked}
      onDeleteClicked={onDeleteClicked}
      onEditAddressClicked={onEditAddressClicked}
      onDeleteAddressClicked={onDeleteAddressClicked} />
    <NTContactsGridSmall
      gridData={gridData}
      isLoading={isLoading}
      onAddAddressClicked={onAddAddressClicked}
      onEditClicked={onEditClicked}
      onDeleteClicked={onDeleteClicked}
      onEditAddressClicked={onEditAddressClicked}
      onDeleteAddressClicked={onDeleteAddressClicked} />
  </Fragment>
);

export default NTContactsGrid;