import { Fragment } from 'react';

import EditIcon from '../../../../../../common/components/icons/EditIcon';
import PlusIcon from '../../../../../../common/components/icons/PlusIcon';
import SGColumnHeader from '../../../../../../common/components/grids/SGColumnHeader';

import { convertNumberToCurrencyString } from '../../../../../../common/utils/CurrencyUtils';
import { formatDate } from '../../../../../../common/utils/DateFunctions';
import useSortableGrid from '../../../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../../../common/utils/SGConstants';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ offeringInstance, additionalInstance, onEditAdditionalInstance }) => (
  <tr>
    {additionalInstance.registrationStartDate && additionalInstance.registrationEndDate
      ? <td colSpan='4'>{formatDate(additionalInstance.registrationStartDate)} &nbsp; - &nbsp; {formatDate(additionalInstance.registrationEndDate)}</td>
      : <td colSpan='4'>&nbsp;</td>
    }
    <td>
      {additionalInstance.canEditAdditionalInstance === true &&
        <button className={global.IconButtonMargin} type="button" onClick={(e) => onEditAdditionalInstance(e, offeringInstance, additionalInstance.offeringInstanceOrgUnitId)}>
          <EditIcon toolTipText='Edit Offering Period' />
        </button>
      }
    </td>
  </tr>
);

const GridRowDetailTable = ({ offeringInstance, additionalInstances, onEditAdditionalInstance }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th colSpan='4'>Offering Periods</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {additionalInstances.map((additionalInstance, i) => <DetailTableRow key={i} offeringInstance={offeringInstance} additionalInstance={additionalInstance} onEditAdditionalInstance={onEditAdditionalInstance} />)}
    </tbody>
  </table>
);

const GridRow = ({ offeringInstance, onEditInstance, onAddAdditionalInstance, onEditAdditionalInstance }) => (
  <Fragment>
    <tr>
      <td>{offeringInstance.offeringName || ''}</td>
      <td>{offeringInstance.ageStart} &nbsp; - &nbsp; {offeringInstance.ageEnd}</td>
      {offeringInstance.registrationStartDate && offeringInstance.registrationEndDate
        ? <td>{formatDate(offeringInstance.registrationStartDate)} &nbsp; - &nbsp; {formatDate(offeringInstance.registrationEndDate)}</td>
        : <td></td>
      }
      <td>${convertNumberToCurrencyString(offeringInstance.feeAmount)}{offeringInstance.renewalFeeAmount && offeringInstance.renewalFeeAmount !== offeringInstance.feeAmount ? ` ($${convertNumberToCurrencyString(offeringInstance.renewalFeeAmount)})` : ''}</td>
      <td>
        {offeringInstance.canAddAdditionalInstance === true &&
          <button className={global.IconButtonMargin} type="button" onClick={(e) => onAddAdditionalInstance(e, offeringInstance)}>
            <PlusIcon toolTipText='Add Offering Period' />
          </button>
        }
        {offeringInstance.canEditInstance === true &&
          <button className={global.IconButtonMargin} type="button" onClick={(e) => onEditInstance(e, offeringInstance)}>
            <EditIcon toolTipText='Edit Offering' />
          </button>
        }
      </td>
    </tr>
    {Array.isArray(offeringInstance.offeringInstanceOrgUnit) && offeringInstance.offeringInstanceOrgUnit.length > 0 &&
      <tr className={global.Expanded}>
        <td colSpan='5'>
          <GridRowDetailTable offeringInstance={offeringInstance} additionalInstances={offeringInstance.offeringInstanceOrgUnit} onEditAdditionalInstance={onEditAdditionalInstance} />
        </td>
      </tr>
    }
  </Fragment>
);

const OfferingInstanceOrgUnitGridLarge = ({ gridData, isLoading, noResultsMessage, onEditInstance,
  onAddAdditionalInstance, onEditAdditionalInstance }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(gridData);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Offering Name'} columnField={'offeringName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <th>Age Range</th>
          <th>Start Date - End Date</th>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Price'} columnField={'feeAmount'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="5">Loading...</td></tr>
          : sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((offeringInstance, i) => <GridRow key={i} offeringInstance={offeringInstance} onEditInstance={onEditInstance} onAddAdditionalInstance={onAddAdditionalInstance} onEditAdditionalInstance={onEditAdditionalInstance} />)
            : <tr><td colSpan="5">{noResultsMessage}</td></tr>
        }
      </tbody>
    </table>
  );
};

export default OfferingInstanceOrgUnitGridLarge;