import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import useNTRegistrationData from "../../../state/nationalTeamRegistration/UseNTRegistrationData";
import useNTRegistrationLeftNavData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationLeftNav/UseNTRegistrationLeftNavData";
import useNTRegistrationPersonData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationPerson/UseNTRegistrationPersonData";

import useNavRoutes from "../../../../common/state/security/UseNavRoutes";

import { LEFT_NAV_ITEM_NAME_SUPPORT_STAFF } from "../utils/NTRegistrationConstants";
import { DEFAULT_MAX_DATE, DEFAULT_PHONE_NUMBER_AREA_CODE, DEFAULT_PHONE_NUMBER_AREA_CODE_MEXICO } from "../../../../common/utils/Constants";

const INITIAL_STATE = {
  supportStaffObj: null
};

const useNTRegistrationSupportStaffDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { updatePageHasUnsavedChanges, onNTRegistrationError } = useNTRegistrationData();
  const { updateCurrentLeftNavData } = useNTRegistrationLeftNavData();
  const { nationalTeamRegistrationPersonState, putNTRegistrationPerson } = useNTRegistrationPersonData();
  const [state, setState] = useState(INITIAL_STATE);

  const onCancelClicked = (event) => {
    try {
      event?.preventDefault();
      navigate(navRoutes.NT_REGISTRATION_SUPPORT_STAFF?.route);
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onSubmitSupportStaffAddForm = async (formState) => {
    try {
      const personId = nationalTeamRegistrationPersonState.objData.personId;
      const newNTPersonObj = JSON.parse(JSON.stringify(nationalTeamRegistrationPersonState.objData));
      newNTPersonObj.supportStaffMembers.push({
        personId,
        firstName: formState.firstName.trim(),
        lastName: formState.lastName.trim(),
        email: formState.email.trim(),
        phoneNumber: formState.phoneNumber.trim() !== DEFAULT_PHONE_NUMBER_AREA_CODE && formState.phoneNumber.trim() !== DEFAULT_PHONE_NUMBER_AREA_CODE_MEXICO ? formState.phoneNumber.trim() : '',
        nationalTeamSupportStaffType: [{
          effectiveDate: formState.effectiveDate,
          expirationDate: formState.hasNoExpirationDate === true ? DEFAULT_MAX_DATE : formState.expirationDate,
          supportStaffTypeId: formState.supportStaffTypeId,
        }]
      });

      const putNTRegistrationPersonResult = await putNTRegistrationPerson(personId, newNTPersonObj);

      if (putNTRegistrationPersonResult) {
        navigate(navRoutes.NT_REGISTRATION_SUPPORT_STAFF?.route);
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onSubmitSupportStaffEditForm = async (formState) => {
    try {
      const personId = nationalTeamRegistrationPersonState.objData.personId;
      const newNTPersonObj = JSON.parse(JSON.stringify(nationalTeamRegistrationPersonState.objData));
      const targetIndex = newNTPersonObj.supportStaffMembers.findIndex((x) => x.nationalTeamSupportStaffId === state.supportStaffObj.nationalTeamSupportStaffId);
      newNTPersonObj.supportStaffMembers[targetIndex] = {
        ...newNTPersonObj.supportStaffMembers[targetIndex],
        firstName: formState.firstName.trim(),
        lastName: formState.lastName.trim(),
        email: formState.email.trim(),
        phoneNumber: formState.phoneNumber.trim() !== DEFAULT_PHONE_NUMBER_AREA_CODE && formState.phoneNumber.trim() !== DEFAULT_PHONE_NUMBER_AREA_CODE_MEXICO ? formState.phoneNumber.trim() : ''
      };

      const putNTRegistrationPersonResult = await putNTRegistrationPerson(personId, newNTPersonObj);

      if (putNTRegistrationPersonResult) {
        navigate(navRoutes.NT_REGISTRATION_SUPPORT_STAFF?.route);
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  useEffect(() => {
    try {
      if (location.state?.supportStaffObj) {
        updateCurrentLeftNavData(LEFT_NAV_ITEM_NAME_SUPPORT_STAFF);

        setState({ ...state, supportStaffObj: location.state.supportStaffObj });
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading: nationalTeamRegistrationPersonState.isObjLoading,
    supportStaffObj: state.supportStaffObj || null,
    onCancelClicked,
    onSubmitSupportStaffAddForm,
    onSubmitSupportStaffEditForm,
    updatePageHasUnsavedChanges,
    onNTRegistrationError
  };
};

export default useNTRegistrationSupportStaffDetail;