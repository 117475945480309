import { Fragment } from 'react';

import NTSupportStaffAddForm from '../../../components/forms/nationalTeamSupportStaff/NTSupportStaffAddForm';
import NTSupportStaffEditForm from '../../../components/forms/nationalTeamSupportStaff/NTSupportStaffEditForm';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import useNationalTeamSupportStaffDetail from './UseNationalTeamSupportStaffDetail';

import { LOADING_MSG } from '../../../../common/utils/Constants';

const NationalTeamSupportStaffDetail = () => {
  const {
    isLoading,
    supportStaffObj,
    onCancelClicked,
    onSubmitSupportStaffAddForm,
    onSubmitSupportStaffEditForm
  } = useNationalTeamSupportStaffDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{supportStaffObj ? 'Edit Support Staff' : 'Add Support Staff'}</Headings.H3>
        </div>
      </div>
      {supportStaffObj
        ? <NTSupportStaffEditForm supportStaffObj={supportStaffObj} onSubmitFormCallback={onSubmitSupportStaffEditForm} onSecondaryButtonClicked={onCancelClicked} />
        : <NTSupportStaffAddForm onSubmitFormCallback={onSubmitSupportStaffAddForm} onSecondaryButtonClicked={onCancelClicked} />
      }
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default NationalTeamSupportStaffDetail;
