import { useEffect, useState } from "react";

import { createUniqueId, validateContactAddressForm, validateContactInfoForm } from "./NTContactSPFormUtils";

import useCountryCombobox from "../../../../common/components/comboboxes/countryCombobox/UseCountryCombobox";
import useStatesCombobox from "../../../../common/components/comboboxes/statesCombobox/UseStatesCombobox";

import useAddressTypeData from "../../../../common/state/addressType/UseAddressTypeData";

import HierarchicalDataUtils from "../../../../common/utils/HierarchicalDataUtils";
import {
  COUNTRY_CODE_CANADA, COUNTRY_CODE_MEXICO, COUNTRY_CODE_UNITED_STATES, DEFAULT_ID, DEFAULT_PHONE_NUMBER_AREA_CODE
} from "../../../../common/utils/Constants";

const INITIAL_CONTACT_INFO_FORM_VALUES = {
  contactRelationshipTypeId: DEFAULT_ID,
  contactRelationshipTypeName: '--',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: DEFAULT_PHONE_NUMBER_AREA_CODE
};

const INITIAL_CONTACT_ADDRESS_FORM_VALUES = {
  addressType: [],
  address1: '',
  address2: '',
  city: '',
  stateCode: '',
  stateName: '',
  countryCode: '',
  countryName: '',
  postalCode: '',
  isCountryUSA: false,
  isCountryMexico: false,
  isCountryCanada: false
};

const INITIAL_MULTI_FORM_STATE = {
  contactInfo: {
    isDirty: false,
    values: {
      ...INITIAL_CONTACT_INFO_FORM_VALUES
    },
    errors: {}
  },
  contactAddresses: []
};

const INITIAL_SUBMIT_STATE = {
  isSubmitting: false,
  addressesSuggestions: [],
  submitErrors: []
};

const useNTContactSPForm = (contactObj, personNTFieldsKey, onSubmitFormCallback, onIsDirtyChanged, onErrorCaught) => {
  const { addressTypeState, getAddressTypes } = useAddressTypeData();
  const { getCountryNameById } = useCountryCombobox();
  const { getStateNameById } = useStatesCombobox();
  const [initialMultiFormState, setInitialMultiFormState] = useState(INITIAL_MULTI_FORM_STATE);
  const [multiFormState, setMultiFormState] = useState(INITIAL_MULTI_FORM_STATE);
  const [submitState, setSubmitState] = useState(INITIAL_SUBMIT_STATE);
  const [isDirtyState, setIsDirtyState] = useState(false);

  const onSubmitMultiForm = async (event) => {
    try {
      event?.preventDefault();

      const newMultiFormState = JSON.parse(JSON.stringify(multiFormState));
      const newAddressSuggestions = [];
      const newSubmitErrors = [];

      setSubmitState({
        ...submitState,
        isSubmitting: true,
        addressesSuggestions: [],
        submitErrors: []
      });

      if (newMultiFormState.contactInfo.isDirty === true) {
        const validateContactInfoFormResult = await validateContactInfoForm(newMultiFormState.contactInfo.values);

        if (Object.keys(validateContactInfoFormResult).length > 0) {
          newMultiFormState.contactInfo.errors = validateContactInfoFormResult;

          for (const error of Object.values(validateContactInfoFormResult)) {
            newSubmitErrors.push(`Contact Info Error: ${error}`);
          }
        }
      }

      for (const address of newMultiFormState.contactAddresses) {
        if (address.isDeleted === false && address.isDirty === true) {
          const addressValidationResult = await validateContactAddressForm(address.values, newSubmitErrors.length === 0);

          if (Object.keys(addressValidationResult.errors).length > 0) {
            address.errors = addressValidationResult.errors;

            for (const error of Object.values(addressValidationResult.errors)) {
              newSubmitErrors.push(`${address.isAdd ? 'New Address Error:' : 'Existing Address Error:'} ${error}`);
            }
          }
          if (Object.keys(addressValidationResult.addressSuggestions).length > 0) {
            newAddressSuggestions.push({ ...address, suggestedValues: addressValidationResult.addressSuggestions });
          }
        }
      }

      setSubmitState({
        ...submitState,
        isSubmitting: false,
        addressesSuggestions: newAddressSuggestions,
        submitErrors: newSubmitErrors
      });
      setMultiFormState(newMultiFormState);

      if (newSubmitErrors.length === 0 && newAddressSuggestions.length === 0) {
        onSubmitFormCallback(newMultiFormState);
      }
    } catch (e) {
      if (onErrorCaught) {
        onErrorCaught(e);

        setSubmitState({
          ...submitState,
          isSubmitting: false,
          addressesSuggestions: [],
          submitErrors: []
        });
      } else {
        setSubmitState({
          ...submitState,
          isSubmitting: false,
          addressesSuggestions: [],
          submitErrors: [e.message]
        });
      }
    }
  };

  const onCloseSuggestionsForm = (event) => {
    try {
      event?.preventDefault();
      setSubmitState({
        ...submitState,
        addressesSuggestions: []
      });

    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : setSubmitState({ ...submitState, isSubmitting: false, submitErrors: [e.message] });
    }
  };

  const onAddContactAddressClicked = (event) => {
    try {
      event?.preventDefault();

      const newMultiFormState = JSON.parse(JSON.stringify(multiFormState));
      newMultiFormState.contactAddresses.push(createContactAddressFormObj());

      setMultiFormState(newMultiFormState);
      setIsDirtyState(getIsDirty(newMultiFormState));
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : setSubmitState({ ...submitState, isSubmitting: false, submitErrors: [e.message] });
    }
  };

  const onDeleteContactAddressClicked = (event, id) => {
    try {
      event?.preventDefault();

      const targetAddressIndex = multiFormState.contactAddresses.findIndex((x) => x.id === id);

      if (targetAddressIndex >= 0) {
        const newMultiFormState = JSON.parse(JSON.stringify(multiFormState));

        if (multiFormState.contactAddresses[targetAddressIndex].isAdd === true) {
          newMultiFormState.contactAddresses.splice(targetAddressIndex, 1);
        } else {
          newMultiFormState.contactAddresses[targetAddressIndex].isDeleted = true;
          newMultiFormState.contactAddresses[targetAddressIndex].isDirty = true;
        }

        setMultiFormState(newMultiFormState);
        setIsDirtyState(getIsDirty(newMultiFormState));
      }
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : setSubmitState({ ...submitState, isSubmitting: false, submitErrors: [e.message] });
    }
  };

  const onRevertContactAddressClicked = (event, id) => {
    try {
      event?.preventDefault();

      const targetAddressIndex = multiFormState.contactAddresses.findIndex((x) => x.id === id);
      const targetInitialAddress = initialMultiFormState.contactAddresses.find((x) => x.id === id);

      if (targetAddressIndex >= 0 && targetInitialAddress) {
        const newMultiFormState = JSON.parse(JSON.stringify(multiFormState));

        newMultiFormState.contactAddresses[targetAddressIndex] = JSON.parse(JSON.stringify(targetInitialAddress));

        setMultiFormState(newMultiFormState);
        setIsDirtyState(getIsDirty(newMultiFormState));
      }
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : setSubmitState({ ...submitState, isSubmitting: false, submitErrors: [e.message] });
    }
  }

  const onUpdateContactFormObj = (newValue, valueKey, newValueLabel = undefined, valueLabelKey = undefined) => {
    try {
      const newMultiFormState = JSON.parse(JSON.stringify(multiFormState));

      newMultiFormState.contactInfo.values[valueKey] = newValue;
      newMultiFormState.contactInfo.isDirty = getIsFormObjDirty(newMultiFormState.contactInfo.values, initialMultiFormState.contactInfo.values);

      if (newValueLabel && valueLabelKey) {
        newMultiFormState.contactInfo.values[valueLabelKey] = newValueLabel;
      }

      setMultiFormState(newMultiFormState);
      setIsDirtyState(getIsDirty(newMultiFormState));
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : setSubmitState({ ...submitState, isSubmitting: false, submitErrors: [e.message] });
    }
  };

  const onUpdateContactAddressFormObj = (id, newValue, valueKey, newValueLabel = undefined, valueLabelKey = undefined) => {
    try {
      const newMultiFormState = JSON.parse(JSON.stringify(multiFormState));

      for (let i = 0; i < newMultiFormState.contactAddresses.length; i++) {
        if (newMultiFormState.contactAddresses[i].id === id) {
          newMultiFormState.contactAddresses[i].values[valueKey] = newValue;
          newMultiFormState.contactAddresses[i].isDirty = getIsFormObjDirty(newMultiFormState.contactAddresses[i].values, newMultiFormState.contactAddresses[i].isAdd === true ? INITIAL_CONTACT_ADDRESS_FORM_VALUES : initialMultiFormState.contactAddresses[i].values);

          if (newValueLabel && valueLabelKey) {
            newMultiFormState.contactAddresses[i].values[valueLabelKey] = newValueLabel;
          }

          if (valueKey === 'countryCode') {
            newMultiFormState.contactAddresses[i].values.isCountryUSA = newValue === COUNTRY_CODE_UNITED_STATES;
            newMultiFormState.contactAddresses[i].values.isCountryMexico = newValue === COUNTRY_CODE_MEXICO;
            newMultiFormState.contactAddresses[i].values.isCountryCanada = newValue === COUNTRY_CODE_CANADA;
          }

          break;
        }
      }

      setMultiFormState(newMultiFormState);
      setIsDirtyState(getIsDirty(newMultiFormState));
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : setSubmitState({ ...submitState, isSubmitting: false, submitErrors: [e.message] });
    }
  };

  useEffect(() => {
    async function loadNTContactForm() {
      try {
        if (addressTypeState.areOptionsLoaded !== true) {
          const getAddressTypesResult = await getAddressTypes();

          if (contactObj && getAddressTypesResult) {
            const newMultiFormState = createMultiFormState(contactObj, getAddressTypesResult.optionsNationalTeamOnly);
            const newInitialMultiFormState = JSON.parse(JSON.stringify(newMultiFormState));

            setMultiFormState(newMultiFormState);
            setInitialMultiFormState(newInitialMultiFormState);
          }
        } else if (contactObj && addressTypeState.areOptionsLoaded === true) {
          const newMultiFormState = createMultiFormState(contactObj, addressTypeState.optionsNationalTeamOnly);
          const newInitialMultiFormState = JSON.parse(JSON.stringify(newMultiFormState));

          setMultiFormState(newMultiFormState);
          setInitialMultiFormState(newInitialMultiFormState);
        }
      } catch (e) {
        onErrorCaught ? onErrorCaught(e) : setSubmitState({ ...submitState, isSubmitting: false, submitErrors: [e.message] });
      }
    }
    loadNTContactForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactObj, addressTypeState.areOptionsLoaded]);

  useEffect(() => {
    try {
      if (onIsDirtyChanged) {
        onIsDirtyChanged(isDirtyState);
      }
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : setSubmitState({ ...submitState, isSubmitting: false, submitErrors: [e.message] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirtyState]);

  function createMultiFormState(contactObj, addressTypeOptions) {
    const newMultiFormState = JSON.parse(JSON.stringify(INITIAL_MULTI_FORM_STATE));

    if (contactObj) {
      newMultiFormState.contactInfo = createContactInfoFormObj(contactObj, contactObj[`${personNTFieldsKey}ContactPhone`]);

      if (Array.isArray(contactObj[`${personNTFieldsKey}ContactAddress`])) {
        for (const contactAddressObj of contactObj[`${personNTFieldsKey}ContactAddress`]) {
          newMultiFormState.contactAddresses.push(createContactAddressFormObj(contactAddressObj[`${personNTFieldsKey}ContactAddressId`], contactAddressObj, contactAddressObj[`${personNTFieldsKey}ContactAddressType`], addressTypeOptions));
        }
      }
    }

    return newMultiFormState;
  }

  function createContactInfoFormObj(contactObj = undefined, contactPhonesArray = undefined) {
    if (contactObj) {
      return {
        isDirty: false,
        values: {
          ...INITIAL_CONTACT_INFO_FORM_VALUES,
          contactRelationshipTypeId: contactObj.contactRelationshipTypeId || DEFAULT_ID,
          contactRelationshipTypeName: contactObj.contactRelationshipType?.typeName || '--',
          firstName: contactObj.firstName || '',
          lastName: contactObj.lastName || '',
          email: contactObj.emailAddress || '',
          phoneNumber: Array.isArray(contactPhonesArray) && contactPhonesArray[0]?.phoneNumber ? contactPhonesArray[0].phoneNumber : DEFAULT_PHONE_NUMBER_AREA_CODE
        },
        errors: {}
      };
    } else {
      return {
        isDirty: false,
        values: {
          ...INITIAL_CONTACT_INFO_FORM_VALUES,
        },
        errors: {}
      };
    }
  }

  function createContactAddressFormObj(contactAddressId = undefined, contactAddressObj = undefined, contactAddressTypesArray = undefined, addressTypeOptions = []) {
    if (contactAddressId && contactAddressObj) {
      const addressType = HierarchicalDataUtils.GetNameIdPairs(addressTypeOptions, Array.isArray(contactAddressTypesArray) ? contactAddressTypesArray.map((x) => { return x.addressTypeId }) : []);

      return {
        id: contactAddressId,
        isAdd: false,
        isDirty: false,
        isDeleted: false,
        values: {
          ...INITIAL_CONTACT_ADDRESS_FORM_VALUES,
          addressType: addressType,
          address1: contactAddressObj.address.address1 || '',
          address2: contactAddressObj.address.address2 || '',
          city: contactAddressObj.address.city || '',
          countryCode: contactAddressObj.address.countryCode || '',
          countryName: contactAddressObj.address.countryCode ? getCountryNameById(contactAddressObj.address.countryCode) : '',
          stateCode: contactAddressObj.address.stateCode || '',
          stateName: contactAddressObj.address.stateCode ? getStateNameById(contactAddressObj.address.stateCode) : '',
          postalCode: contactAddressObj.address.postalCode || '',
          isCountryUSA: contactAddressObj.address.countryCode === COUNTRY_CODE_UNITED_STATES,
          isCountryMexico: contactAddressObj.address.countryCode === COUNTRY_CODE_MEXICO,
          isCountryCanada: contactAddressObj.address.countryCode === COUNTRY_CODE_CANADA
        },
        errors: {}
      };
    } else {
      return {
        id: createUniqueId(),
        isAdd: true,
        isDirty: true,
        isDeleted: false,
        values: {
          ...INITIAL_CONTACT_ADDRESS_FORM_VALUES,
          countryCode: COUNTRY_CODE_UNITED_STATES,
          countryName: getCountryNameById(COUNTRY_CODE_UNITED_STATES),
          isCountryUSA: true,
          isCountryMexico: false,
          isCountryCanada: false
        },
        errors: {}
      }
    }
  }

  function getIsDirty(newMultiFormState) {
    if (newMultiFormState.contactInfo.isDirty === true) {
      return true;
    }

    for (const address of newMultiFormState.contactAddresses) {
      if (address.isDirty === true) {
        return true;
      }
    }

    return false;
  }

  function getIsFormObjDirty(formObjValues, initialFormObjValues) {
    for (const key in formObjValues) {
      if (Array.isArray(formObjValues[key]) && Array.isArray(initialFormObjValues[key])) {
        if (formObjValues[key].length !== initialFormObjValues[key].length) {
          return true;
        }

        for (let i = 0; i < formObjValues[key].length; i++) {
          if (formObjValues[key][i].name !== initialFormObjValues[key][i].name) {
            return true;
          }
        }
      } else if (formObjValues[key] !== initialFormObjValues[key]) {
        return true;
      }
    }

    return false;
  }

  return {
    isSubmitting: submitState.isSubmitting,
    submitErrors: submitState.submitErrors,
    addressesSuggestions: submitState.addressesSuggestions,
    isLoading: addressTypeState.areOptionsLoaded !== true,
    isDirty: isDirtyState,
    multiFormState,
    onSubmitMultiForm,
    onCloseSuggestionsForm,
    onAddContactAddressClicked,
    onDeleteContactAddressClicked,
    onRevertContactAddressClicked,
    onUpdateContactFormObj,
    onUpdateContactAddressFormObj
  };
};

export default useNTContactSPForm;