import { DEFAULT_ID, DEFAULT_PHONE_NUMBER_AREA_CODE, DEFAULT_PHONE_NUMBER_AREA_CODE_MEXICO } from "../../../../common/utils/Constants";
import { isValid5DigitZip, isValid9DigitZip, isValidAddressLine, isValidCanadianZip, isValidEmail, isValidMexicanPhoneNumber, isValidName, isValidUSOrCanadianPhoneNumber, validateAddress } from "../../../../common/utils/validation";

const createUniqueId = () => {
  return `!${Math.round(Math.random() * 1000000)}`;
};

const validateContactInfoForm = async (formValues) => {
  let errors = {};

  // First Name
  if (formValues.firstName.trim() === '') {
    errors.firstName = 'First Name is required';
  } else if (!isValidName(formValues.firstName.trim())) {
    errors.firstName = 'First Name cannot contain numbers, special characters, or exceed 100 characters';
  }

  // Last Name
  if (formValues.lastName.trim() === '') {
    errors.lastName = 'Last Name is required';
  } else if (!isValidName(formValues.lastName.trim())) {
    errors.lastName = 'Last Name cannot contain numbers, special characters, or exceed 100 characters';
  }

  // Relationship
  if (formValues.contactRelationshipTypeId < 0) {
    errors.contactRelationshipTypeId = 'Relationship is required';
  }

  // Email
  if (formValues.email.trim() !== '') {
    if (!isValidEmail(formValues.email.trim())) {
      errors.email = 'Email must be in a valid format';
    } else if (formValues.email.trim().length > 512) {
      errors.email = 'Email cannot exceed 512 characters'
    }
  }

  // Phone Number
  if (formValues.phoneNumber.trim() !== '' && formValues.phoneNumber.trim() !== DEFAULT_PHONE_NUMBER_AREA_CODE
    && formValues.phoneNumber.trim() !== DEFAULT_PHONE_NUMBER_AREA_CODE_MEXICO) {
    if (!isValidUSOrCanadianPhoneNumber(formValues.phoneNumber) && !isValidMexicanPhoneNumber(formValues.phoneNumber)) {
      errors.phoneNumber = 'Must enter a valid US, Canadian, or Mexican phone number in +1/+52 (XXX) XXX-XXXX format'
    }
  }

  return errors;
};

const validateContactAddressForm = async (formValues, getAddressSuggestions = true) => {
  let errors = {};
  let addressSuggestions = {};

  // Address Type
  if (!(formValues.addressType.length > 0)) {
    errors.addressType = 'Address Type is required';
  }

  // Country
  if (formValues.countryName?.trim()) {
    if (formValues.countryCode === DEFAULT_ID || formValues.countryCode === '') {
      errors.countryCode = 'Must be a valid country';
    }
  } else {
    errors.countryCode = 'Country is required';
  }

  // Address Line 1
  if (formValues.address1.trim() === '') {
    errors.address1 = 'Address Line 1 is required';
  } else if (!isValidAddressLine(formValues.address1.trim())) {
    errors.address1 = 'Address Line 1 cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
  }

  // Address Line 2
  if (formValues.address2.trim() !== '') {
    if (!isValidAddressLine(formValues.address2.trim())) {
      errors.address2 = 'Address Line 2 cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
    }
  }

  // City
  if (formValues.city.trim() === '') {
    errors.city = 'City is required';
  } else if (!isValidAddressLine(formValues.city.trim())) {
    errors.city = 'City cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
  }

  // State
  if (formValues.isCountryUSA === true || formValues.isCountryMexico === true || formValues.isCountryCanada === true) {
    if (formValues.stateName !== '') {
      if (formValues.stateCode === DEFAULT_ID || formValues.stateCode === '') {
        errors.stateCode = 'Must be a valid state';
      }
    } else {
      errors.stateCode = 'State is required';
    }
  }

  // Zip Code
  if (formValues.postalCode.trim() === '') {
    errors.postalCode = 'Zip Code is required';
  } else if ((formValues.isCountryUSA === true) && !isValid5DigitZip(formValues.postalCode.trim()) && !isValid9DigitZip(formValues.postalCode.trim())) {
    errors.postalCode = 'United States Zip Code must be 5 digits, or 9 digits in XXXXX-XXXX format';
  } else if ((formValues.isCountryCanada === true) && !isValidCanadianZip(formValues.postalCode.trim())) {
    errors.postalCode = 'Canadian Zip Code must be valid and in A1A 1A1 format';
  } else if ((formValues.isCountryMexico === true) && !isValid5DigitZip(formValues.postalCode.trim())) {
    errors.postalCode = 'Mexican Zip Code must be 5 digits';
  }

  if (Object.keys(errors).length === 0 && getAddressSuggestions === true) {
    const validatedAddress = await validateAddress(formValues.address1?.trim(), formValues.address2?.trim(), formValues.city?.trim(), formValues.stateCode, formValues.postalCode?.trim());

    if (validatedAddress.isValid === true) {
      if (formValues.address1?.trim() !== validatedAddress.address1?.trim()) {
        addressSuggestions.address1 = validatedAddress.address1.trim();
      }

      if (formValues.address2?.trim() !== validatedAddress.address2?.trim()) {
        addressSuggestions.address2 = validatedAddress.address2.trim();
      }

      if (formValues.city?.trim() !== validatedAddress.city?.trim()) {
        addressSuggestions.city = validatedAddress.city.trim();
      }

      if (formValues.stateCode !== validatedAddress.stateCode) {
        addressSuggestions.stateCode = validatedAddress.stateCode;
      }

      if (formValues.postalCode?.trim() !== validatedAddress.postalCode?.trim()) {
        addressSuggestions.postalCode = validatedAddress.postalCode.trim();
      }
    }
  }

  return { errors, addressSuggestions };
};

const createPersonNTContactFromMultiFormState = (multiFormState, personId, personNTFieldsKey = 'personNationalTeam', existingPersonContact = undefined) => {
  const personNTContactInfo = createPersonNTContactInfo(multiFormState.contactInfo.values, personId, personNTFieldsKey, existingPersonContact);
  const personNTContactAddresses = [];

  for (const address of multiFormState.contactAddresses) {
    if (address.isDeleted !== true) {
      const existingPersonContactAddress = existingPersonContact?.[`${personNTFieldsKey}ContactAddress`]?.find((x) => x[`${personNTFieldsKey}ContactAddressId`] === address.id);

      personNTContactAddresses.push(createPersonNTContactAddress(address.values, personNTFieldsKey, existingPersonContact?.[`${personNTFieldsKey}ContactId`], existingPersonContactAddress));
    }
  }

  return {
    ...personNTContactInfo,
    [`${personNTFieldsKey}ContactAddress`]: personNTContactAddresses
  };
};

const createPersonNTContactInfo = (formValues, personId, personNTFieldsKey = 'personNationalTeam', existingPersonContact = undefined) => {
  if (existingPersonContact) {
    let phoneNumber = formValues.phoneNumber.trim() !== '' && formValues.phoneNumber.trim() !== DEFAULT_PHONE_NUMBER_AREA_CODE && formValues.phoneNumber.trim() !== DEFAULT_PHONE_NUMBER_AREA_CODE_MEXICO
      ? formValues.phoneNumber
      : null;

    return {
      ...existingPersonContact,
      contactRelationshipType: undefined,
      personId,
      firstName: formValues.firstName.trim(),
      lastName: formValues.lastName.trim(),
      emailAddress: formValues.email.trim(),
      contactRelationshipTypeId: formValues.contactRelationshipTypeId,
      [`${personNTFieldsKey}ContactPhone`]: phoneNumber
        ? [{
          [`${personNTFieldsKey}ContactId`]: existingPersonContact[`${personNTFieldsKey}ContactId`],
          phoneTypeId: 1 /*TODO Magic number*/,
          phoneNumber
        }] : []
    };
  } else {
    let phoneNumber = formValues.phoneNumber.trim() !== '' && formValues.phoneNumber.trim() !== DEFAULT_PHONE_NUMBER_AREA_CODE && formValues.phoneNumber.trim() !== DEFAULT_PHONE_NUMBER_AREA_CODE_MEXICO
      ? formValues.phoneNumber
      : null;

    return {
      personId,
      firstName: formValues.firstName.trim(),
      lastName: formValues.lastName.trim(),
      emailAddress: formValues.email.trim(),
      contactRelationshipTypeId: formValues.contactRelationshipTypeId,
      [`${personNTFieldsKey}ContactPhone`]: phoneNumber ? [{ phoneTypeId: 1 /*TODO Magic number*/, phoneNumber }] : []
    };
  }
};

const createPersonNTContactAddress = (formValues, personNTFieldsKey = 'personNationalTeam', personNTContactId = undefined, existingPersonContactAddress = undefined) => {
  if (existingPersonContactAddress) {
    return {
      ...existingPersonContactAddress,
      address: {
        ...existingPersonContactAddress.address,
        address1: formValues.address1.trim(),
        address2: formValues.address2.trim(),
        city: formValues.city.trim(),
        postalCode: formValues.postalCode.trim(),
        stateCode: formValues.stateCode,
        countryCode: formValues.countryCode,
      },
      [`${personNTFieldsKey}ContactAddressType`]: formValues.addressType.map((x) => { return { [`${personNTFieldsKey}ContactAddressId`]: existingPersonContactAddress[`${personNTFieldsKey}ContactAddressId`], addressTypeId: x.id } })
    };
  } else {
    return {
      [`${personNTFieldsKey}ContactId`]: personNTContactId,
      address: {
        address1: formValues.address1.trim(),
        address2: formValues.address2.trim(),
        city: formValues.city.trim(),
        postalCode: formValues.postalCode.trim(),
        stateCode: formValues.stateCode,
        countryCode: formValues.countryCode,
      },
      [`${personNTFieldsKey}ContactAddressType`]: formValues.addressType.map((x) => { return { addressTypeId: x.id } })
    };
  }
}

export {
  createUniqueId, validateContactInfoForm, validateContactAddressForm,
  createPersonNTContactFromMultiFormState, createPersonNTContactInfo,
  createPersonNTContactAddress
};