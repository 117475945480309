import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import useNTRegistrationData from "../../../state/nationalTeamRegistration/UseNTRegistrationData";
import useNTRegistrationLeftNavData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationLeftNav/UseNTRegistrationLeftNavData";
import useNTRegistrationProfileData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationProfile/UseNTRegistrationProfileData";

import useNavRoutes from "../../../../common/state/security/UseNavRoutes";

import { LEFT_NAV_ITEM_NAME_SOCIAL_MEDIA } from "../utils/NTRegistrationConstants";

const INITIAL_STATE = {
  socialMediaObj: null
};

const useNTRegistrationSocialMediaDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { updatePageHasUnsavedChanges, onNTRegistrationError } = useNTRegistrationData();
  const { updateCurrentLeftNavData } = useNTRegistrationLeftNavData();
  const { nationalTeamRegistrationProfileState, putNTRegistrationProfile } = useNTRegistrationProfileData();
  const [state, setState] = useState(INITIAL_STATE);

  const onCancelClicked = (event) => {
    try {
      event?.preventDefault();

      navigate(navRoutes.NT_REGISTRATION_SOCIAL_MEDIA?.route);
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onSubmitSocialMediaEditForm = async (formState) => {
    try {
      const personId = nationalTeamRegistrationProfileState.objData.personId;
      const newPersonProfileObj = JSON.parse(JSON.stringify(nationalTeamRegistrationProfileState.objData));
      const targetIndex = newPersonProfileObj.socialMedia.findIndex((x) => x.personSocialMediaId === state.socialMediaObj.personSocialMediaId);
      newPersonProfileObj.socialMedia[targetIndex] = {
        ...newPersonProfileObj.socialMedia[targetIndex],
        socialHandle: formState.socialHandle.trim()
      };

      const putNTRegistrationProfileResult = await putNTRegistrationProfile(personId, newPersonProfileObj);

      if (putNTRegistrationProfileResult) {
        navigate(navRoutes.NT_REGISTRATION_SOCIAL_MEDIA?.route);
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  useEffect(() => {
    try {
      if (location.state?.socialMediaObj) {
        updateCurrentLeftNavData(LEFT_NAV_ITEM_NAME_SOCIAL_MEDIA);

        setState({ ...state, socialMediaObj: location.state.socialMediaObj });
      } else {
        throw new Error('Failed to load Social Media Detail view');
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading: nationalTeamRegistrationProfileState.isObjLoading,
    socialMediaObj: state.socialMediaObj || null,
    onCancelClicked,
    onSubmitSocialMediaEditForm,
    updatePageHasUnsavedChanges,
    onNTRegistrationError
  };
};

export default useNTRegistrationSocialMediaDetail;