import { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import PlusIcon from '../../../../common/components/icons/PlusIcon';

import global from '../../../../common/components/GlobalStyle.module.css';

const DetailGridRowIcons = ({ address, onEditAddressClicked, onDeleteAddressClicked }) => (
  <Fragment>
    {onDeleteAddressClicked &&
      <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onDeleteAddressClicked(e, address)}>
        <DeleteIcon toolTipText='Delete Address' />
      </button>
    }
    {onEditAddressClicked &&
      <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEditAddressClicked(e, address.contactId, address.addressId)}>
        <EditIcon toolTipText='Edit Address' />
      </button>
    }
  </Fragment>
);

const DetailGridRow = ({ address, onEditAddressClicked, onDeleteAddressClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}>
      <span className='hidden-xs'>{address.address || <span>&nbsp;</span>}</span>&nbsp;
      <DetailGridRowIcons address={address} onEditAddressClicked={onEditAddressClicked} onDeleteAddressClicked={onDeleteAddressClicked} />
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Address
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {address.address || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          City
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {address.city || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          State
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {address.stateCode || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Zip Code
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {address.postalCode || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Type(s)
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {address.addressTypeNames || <span>&nbsp;</span>}
        </div>
      </div>
    </div>
  </div>
);

const DetailGrid = ({ addresses, onEditAddressClicked, onDeleteAddressClicked }) => (
  <div className={global.SmallTableDetailTable}>
    {Array.isArray(addresses) && addresses.length > 0 &&
      addresses.map((address, i) => <DetailGridRow key={i} address={address} onEditAddressClicked={onEditAddressClicked} onDeleteAddressClicked={onDeleteAddressClicked} />)
    }
  </div>
);

const SmallGridRowIcons = ({ contact, onAddAddressClicked, onEditClicked, onDeleteClicked }) => (
  <Fragment>
    {contact.canDelete && onDeleteClicked &&
      <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onDeleteClicked(e, contact)}>
        <DeleteIcon toolTipText='Delete Contact' />
      </button>
    }
    {onEditClicked &&
      <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEditClicked(e, contact.contactId)}>
        <EditIcon toolTipText='Edit Contact' />
      </button>
    }
    {onAddAddressClicked &&
      <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onAddAddressClicked(e, contact.contactId)}>
        <PlusIcon toolTipText='Add Address' />
      </button>
    }
  </Fragment>
);

const SmallGridRow = ({ contact, onAddAddressClicked, onEditClicked, onEditAddressClicked,
  onDeleteClicked, onDeleteAddressClicked }) => (
  <Fragment>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>
        <span className='hidden-xs'>{contact.name || ''}</span>&nbsp;
        <SmallGridRowIcons contact={contact} onAddAddressClicked={onAddAddressClicked} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
            Name
          </div>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
            {contact.name || <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Relationship
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {contact.relationship || <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Email
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {contact.email || <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Phone Number
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {contact.phoneNumber || <span>&nbsp;</span>}
          </div>
        </div>
      </div>
    </div>
    <DetailGrid addresses={contact.contactAddresses} onEditAddressClicked={onEditAddressClicked} onDeleteAddressClicked={onDeleteAddressClicked} />
  </Fragment>
);


const NTContactsGridSmall = ({ gridData, isLoading, onAddAddressClicked, onEditClicked, onEditAddressClicked,
  onDeleteClicked, onDeleteAddressClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>Loading...</div></div>
        </div>
      ) : Array.isArray(gridData) && gridData.length > 0
        ? gridData.map((contact, i) => <SmallGridRow key={i} contact={contact} onAddAddressClicked={onAddAddressClicked} onEditClicked={onEditClicked} onEditAddressClicked={onEditAddressClicked} onDeleteClicked={onDeleteClicked} onDeleteAddressClicked={onDeleteAddressClicked} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>No Contacts</div></div>
          </div>
        )
    }
  </div>
);

export default NTContactsGridSmall;