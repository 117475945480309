import { Fragment } from 'react';

import useNationalTeamAttachmentDetail from './UseNationalTeamAttachmentDetail';

import NTAttachmentForm from '../../../components/forms/nationalTeamAttachment/NTAttachmentForm';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import { LOADING_MSG, SAVING_MSG } from '../../../../common/utils/Constants';

const NationalTeamAttachmentDetail = () => {
  const {
    isSaving,
    isLoading,
    attachmentObj,
    onCancelClicked,
    onDownloadFileClicked,
    onSubmitAttachmentForm
  } = useNationalTeamAttachmentDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{attachmentObj ? 'Edit Attachment' : 'Add Attachment'}</Headings.H3>
        </div>
      </div>
      {attachmentObj !== undefined && (
        <NTAttachmentForm
          attachmentObj={attachmentObj}
          submitButtonText={attachmentObj ? 'Save' : 'Add Attachment'}
          onSubmitFormCallback={onSubmitAttachmentForm}
          onSecondaryButtonClicked={onCancelClicked}
          onDownloadUploadedFileClicked={onDownloadFileClicked} />
      )}
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default NationalTeamAttachmentDetail;