import { Fragment } from "react";

import useProfileBioInfo from './UseProfileBioInfo';

import PersonBioInfoForm from "../../../components/forms/personBioInfo/PersonBioInfoForm";

import Headings from "../../../../common/components/headings/Headings";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";

import { LOADING_MSG, SAVING_MSG } from "../../../../common/utils/Constants";

const ProfileBioInfo = () => {
  const {
    isSaving,
    isLoading,
    personBiosArray,
    onSubmitBioInfoForm
  } = useProfileBioInfo();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Biographical Info</Headings.H3>
        </div>
      </div>
      <PersonBioInfoForm
        personBiosArray={personBiosArray}
        onSubmitFormCallback={onSubmitBioInfoForm} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default ProfileBioInfo;