import { Fragment } from "react";

import useOfferingInstanceOrgUnitEditForm from "./UseOfferingInstanceOrgUnitEditForm";

import ReadOnly from "../../../../../../common/components/readOnly/ReadOnly";
import DatePicker from "../../../../../../common/components/datepickers/DatePicker";
import CurrencyInput from "../../../../../../common/components/inputs/CurrencyInput";
import PrimaryButton from "../../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../../common/components/buttons/SecondaryButton";

import { convertNumberToCurrencyString } from "../../../../../../common/utils/CurrencyUtils";

const OfferingInstanceOrgUnitEditForm = ({ orgUnitObj, reportPeriodObj, offeringInstanceObj, offeringInstanceOrgUnitId,
  isFeeReadOnly = false, hideOrgUnitName = false, hideDateFields = false, submitButtonText = 'Save',
  onSubmitFormCallback, onBackClicked, onDeleteClicked }) => {
  const {
    formState,
    errorState,
    updateFormState,
    handleSubmit
  } = useOfferingInstanceOrgUnitEditForm(onSubmitFormCallback, reportPeriodObj, orgUnitObj, offeringInstanceObj, offeringInstanceOrgUnitId, isFeeReadOnly);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="Season Year"
            name="periodName"
            value={reportPeriodObj?.periodName} />
        </div>
        {hideOrgUnitName !== true &&
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="LSC"
              name="orgUnitName"
              value={orgUnitObj?.orgUnitName} />
          </div>
        }
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="Offering Name"
            name="offeringName"
            value={offeringInstanceObj?.offeringName} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="Maximum Fee Amount"
            name="maxLscFeeAmount"
            value={formState.maxLscFeeAmount === null
              ? 'No Maximum'
              : `$${convertNumberToCurrencyString(formState.maxLscFeeAmount)}`
            } />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          {isFeeReadOnly === true
            ? (
              <ReadOnly
                label="Fee Amount"
                name="feeAmount"
                value={`$${convertNumberToCurrencyString(formState.feeAmount)}`} />
            ) : (
              <CurrencyInput
                label="Fee Amount*"
                name="feeAmount"
                value={formState.feeAmount}
                error={errorState.feeAmount}
                message={errorState.feeAmount}
                onChange={(value) => { updateFormState('feeAmount', value); }} />
            )
          }
        </div>
        {formState.isClubOffering === true &&
          <div className="col-xs-12 col-sm-6 col-md-4">
            {isFeeReadOnly === true
              ? (
                <ReadOnly
                  label="Renewal Fee Amount"
                  name="renewalFeeAmount"
                  value={`$${convertNumberToCurrencyString(formState.renewalFeeAmount)}`} />
              ) : (
                <CurrencyInput
                  label="Renewal Fee Amount*"
                  name="renewalFeeAmount"
                  value={formState.renewalFeeAmount}
                  error={errorState.renewalFeeAmount}
                  message={errorState.renewalFeeAmount}
                  onChange={(value) => { updateFormState('renewalFeeAmount', value); }} />
              )
            }
          </div>
        }
        {hideDateFields !== true &&
          <Fragment>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <DatePicker
                label="Registration Start Date*"
                name="registrationStartDate"
                value={formState.registrationStartDate}
                error={errorState.registrationStartDate}
                message={errorState.registrationStartDate}
                onChange={(value) => { updateFormState('registrationStartDate', value); }}
                countOfYears={35}
                startYearOffset={-20} />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <DatePicker
                label="Registration End Date*"
                name="registrationEndDate"
                value={formState.registrationEndDate}
                error={errorState.registrationEndDate}
                message={errorState.registrationEndDate}
                onChange={(value) => { updateFormState('registrationEndDate', value); }}
                countOfYears={35}
                startYearOffset={-20} />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <DatePicker
                label="Effective Start Date*"
                name="effectiveDate"
                value={formState.effectiveDate}
                error={errorState.effectiveDate}
                message={errorState.effectiveDate}
                onChange={(value) => { updateFormState('effectiveDate', value); }}
                countOfYears={35}
                startYearOffset={-20} />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <DatePicker
                label="Expiration End Date*"
                name="expirationDate"
                value={formState.expirationDate}
                error={errorState.expirationDate}
                message={errorState.expirationDate}
                onChange={(value) => { updateFormState('expirationDate', value); }}
                countOfYears={35}
                startYearOffset={-20} />
            </div>
          </Fragment>
        }
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type='submit'>{submitButtonText}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>&nbsp;
          {onDeleteClicked &&
            <SecondaryButton type="button" onClick={(e) => onDeleteClicked(e, formState)}>Delete</SecondaryButton>
          }
        </div>
      </div>
    </form>
  );
};

export default OfferingInstanceOrgUnitEditForm;