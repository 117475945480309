import { Fragment } from 'react';

import useNationalTeamAttachments from './UseNationalTeamAttachments';

import NTAttachmentsGrid from '../../../components/grids/nationalTeamAttachments/NTAttachmentsGrid';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

import { LOADING_MSG, SAVING_MSG } from '../../../../common/utils/Constants';

const NationalTeamAttachments = () => {
  const {
    isSaving,
    isLoading,
    gridData,
    state,
    onAddAttachmentClicked,
    onEditAttachmentClicked,
    onDeleteAttachmentClicked,
    onDeletePopupConfirmClicked,
    onPopupCancelClicked
  } = useNationalTeamAttachments();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Attachments</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton onClick={onAddAttachmentClicked}>Add Attachment</ActionIntraPageButton>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <NTAttachmentsGrid
            gridData={gridData}
            isLoading={isLoading}
            onEditClicked={onEditAttachmentClicked}
            onDeleteClicked={onDeleteAttachmentClicked} />
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Delete Attachment File?'}
        displayPopUp={state.displayDeletePopup}
        onModalCanceled={onPopupCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>File Type:</b> {state.deleteAttachmentObj?.personNationalAttachmentFileTypeName}</p>
            <p><b>Uploaded File Name:</b> {state.deleteAttachmentObj?.attachmentName}</p>
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <PrimaryButton type="button" onClick={onDeletePopupConfirmClicked}>Yes, Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onPopupCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default NationalTeamAttachments;