import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import useNTRegistrationData from "../../../state/nationalTeamRegistration/UseNTRegistrationData";
import useNTRegistrationLeftNavData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationLeftNav/UseNTRegistrationLeftNavData";
import useNTRegistrationPersonData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationPerson/UseNTRegistrationPersonData";

import useNavRoutes from "../../../../common/state/security/UseNavRoutes";

import { LEFT_NAV_ITEM_NAME_SPONSORS } from "../utils/NTRegistrationConstants";

const INITIAL_STATE = {
  sponsorObj: null,
  dupSponsorObj: {},
  displayDupSponsorModal: false
};

const useNTRegistrationSponsorsDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { onNTRegistrationError, updatePageHasUnsavedChanges } = useNTRegistrationData();
  const { updateCurrentLeftNavData } = useNTRegistrationLeftNavData();
  const { nationalTeamRegistrationPersonState, putNTRegistrationPerson } = useNTRegistrationPersonData();
  const [state, setState] = useState(INITIAL_STATE);

  const onCancelClicked = (event) => {
    try {
      event?.preventDefault();
      navigate(navRoutes.NT_REGISTRATION_SPONSORS?.route);
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onDupPopupCloseClicked = (event) => {
    try {
      event?.preventDefault();
      
      setState({
        ...state,
        displayDupSponsorModal: false,
        dupSponsorObj: {}
      });
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onSubmitSponsorsEditForm = async (formState) => {
    try {
      const dupSponsorObj = findDuplicateSponsor({ ...formState, sponsorId: state.sponsorObj.sponsorId }, nationalTeamRegistrationPersonState.objData.sponsors, state.sponsorObj.personSponsorId);

      if (dupSponsorObj) {
        setState({
          ...state,
          displayDupSponsorModal: true,
          dupSponsorObj
        });
      } else {
        const personId = nationalTeamRegistrationPersonState.objData.personId;
        const newPersonNTObj = JSON.parse(JSON.stringify(nationalTeamRegistrationPersonState.objData));
        const targetIndex = newPersonNTObj.sponsors.findIndex((x) => x.personSponsorId === state.sponsorObj.personSponsorId);
        newPersonNTObj.sponsors[targetIndex] = {
          ...newPersonNTObj.sponsors[targetIndex],
          effectiveDate: formState.effectiveDate,
          expirationDate: formState.expirationDate
        };
  
        const putNTRegistrationPersonResult = await putNTRegistrationPerson(personId, newPersonNTObj);
  
        if (putNTRegistrationPersonResult) {
          navigate(navRoutes.NT_REGISTRATION_SPONSORS?.route);
        }
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  useEffect(() => {
    try {
      if (location.state?.sponsorObj) {
        updateCurrentLeftNavData(LEFT_NAV_ITEM_NAME_SPONSORS);

        setState({ ...state, sponsorObj: location.state.sponsorObj });
      } else {
        throw new Error('Failed to load Sponsor Detail view');
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function findDuplicateSponsor(formState, sponsors, personSponsorId) {
    const existingSponsors = sponsors.filter((x) => x.personSponsorId !== personSponsorId);
    const effectiveDate = new Date(formState.effectiveDate);
    const expirationDate = new Date(formState.expirationDate);

    for (const sponsor of existingSponsors) {
      if (formState.sponsorId === sponsor.sponsorId) {
        const existingEffectiveDate = new Date(sponsor.effectiveDate);
        const existingExpirationDate = new Date(sponsor.expirationDate);

        if (effectiveDate <= existingExpirationDate && expirationDate >= existingEffectiveDate) {
          return sponsor;
        }
      }
    }

    return null;
  }

  return {
    isLoading: nationalTeamRegistrationPersonState.isObjLoading,
    sponsorObj: state.sponsorObj || null,
    state,
    onCancelClicked,
    onDupPopupCloseClicked,
    onSubmitSponsorsEditForm,
    updatePageHasUnsavedChanges,
    onNTRegistrationError
  };
};

export default useNTRegistrationSponsorsDetail;