import { createContext, useState } from 'react';

import NTRegistrationData from './NTRegistrationData';

export const NTRegistrationContext = createContext();
export const NTRegistrationPageStateContext = createContext();

const NTRegistrationContextProvider = ({ children }) => {
  const stateHook = useState(NTRegistrationData.INITIAL_NATIONAL_TEAM_REGISTRATION_STATE);
  const pageStateHook = useState(NTRegistrationData.INITIAL_NATIONAL_TEAM_REGISTRATION_PAGE_STATE);

  return (
    <NTRegistrationContext.Provider value={stateHook}>
      <NTRegistrationPageStateContext.Provider value={pageStateHook}>
        {children}
      </NTRegistrationPageStateContext.Provider>
    </NTRegistrationContext.Provider>
  );
};

export default NTRegistrationContextProvider;