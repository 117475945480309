import { useEffect, useState } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import useNTCoachOfRecordData from "../../../state/nationalTeamCoachOfRecord/UseNTCoachOfRecordData";
import useNTRegistrationData from "../../../state/nationalTeamRegistration/UseNTRegistrationData";
import useNTRegistrationLeftNavData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationLeftNav/UseNTRegistrationLeftNavData";
import useNTRegistrationPersonData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationPerson/UseNTRegistrationPersonData";

import useNavRoutes from "../../../../common/state/security/UseNavRoutes";

import { LEFT_NAV_ITEM_NAME_COACH } from "../utils/NTRegistrationConstants";
import { DEFAULT_MAX_DATE } from "../../../../common/utils/Constants";

const INITIAL_STATE = {
  displayDupCoachModal: false,
  dupCoachObj: {}
};

const useNTRegistrationCoach = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { pageHasUnsavedChanges, updatePageHasUnsavedChanges, onNTRegistrationError, onNavigateWithoutSaving } = useNTRegistrationData();
  const { updateCurrentLeftNavData } = useNTRegistrationLeftNavData();
  const { nationalTeamRegistrationPersonState, getNTRegistrationPerson } = useNTRegistrationPersonData();
  const { nationalTeamCoachOfRecordState, coachOfRecordAddState, postNTCoachOfRecord, getNTCoachOfRecord } = useNTCoachOfRecordData();
  const [state, setState] = useState(INITIAL_STATE);

  const onContinueClicked = (event) => {
    try {
      event?.preventDefault();

      if (pageHasUnsavedChanges === true) {
        onNavigateWithoutSaving(navRoutes.NT_REGISTRATION_SPONSORS?.route);
      } else {
        navigate(navRoutes.NT_REGISTRATION_SPONSORS?.route);
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onBackClicked = (event) => {
    try {
      event?.preventDefault();

      if (pageHasUnsavedChanges === true) {
        onNavigateWithoutSaving(navRoutes.NT_REGISTRATION_FREQUENT_FLYER?.route);
      } else {
        navigate(navRoutes.NT_REGISTRATION_FREQUENT_FLYER?.route);
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onDupPopupCloseClicked = (event) => {
    try {
      event?.preventDefault();
      setState({
        ...state,
        displayDupCoachModal: false,
        dupCoachObj: {}
      });
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onSubmitCoachAddForm = async (formState) => {
    try {
      const dupCoachObj = findDuplicateCoach(formState, nationalTeamCoachOfRecordState.arrayData);

      if (dupCoachObj) {
        setState({
          ...state,
          displayDupCoachModal: true,
          dupCoachObj
        });
      } else {
        const personId = nationalTeamRegistrationPersonState.objData.personId;
        const coachObj = {
          personId,
          coachPersonId: formState.coachPersonId,
          effectiveDate: formState.effectiveDate,
          expirationDate: formState.hasNoExpirationDate === true ? DEFAULT_MAX_DATE : formState.expirationDate
        };

        const postNTCoachOfRecordResult = await postNTCoachOfRecord(personId, coachObj);

        if (postNTCoachOfRecordResult) {
          await getNTCoachOfRecord(0);
        }
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  useEffect(() => {
    async function loadNTRegistrationCoaches() {
      try {
        updateCurrentLeftNavData(LEFT_NAV_ITEM_NAME_COACH, true);

        if (nationalTeamCoachOfRecordState.isArrayLoaded !== true) {
          await getNTCoachOfRecord(0);
        }
      } catch (e) {
        onNTRegistrationError(e);
      }
    }
    loadNTRegistrationCoaches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nationalTeamCoachOfRecordState.isArrayLoaded]);

  useEffect(() => {
    async function loadNTRegistrationPerson() {
      try {
        if (nationalTeamRegistrationPersonState.isObjLoaded !== true) {
          await getNTRegistrationPerson(0);
        }
      } catch (e) {
        onNTRegistrationError(e);
      }
    }
    loadNTRegistrationPerson();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nationalTeamRegistrationPersonState.isObjLoaded]);

  function findDuplicateCoach(formState, existingCoaches) {
    for (const coach of existingCoaches) {
      if (formState.coachPersonId === coach.coachPersonId) {
        return coach;
      }
    }

    return null;
  }

  return {
    isLoading: nationalTeamCoachOfRecordState.isArrayLoading || coachOfRecordAddState.isObjLoading,
    personId: nationalTeamRegistrationPersonState.objData.personId,
    gridData: nationalTeamCoachOfRecordState.arrayData,
    state,
    onContinueClicked,
    onBackClicked,
    onDupPopupCloseClicked,
    onSubmitCoachAddForm,
    updatePageHasUnsavedChanges,
    onNTRegistrationError
  };
};

export default useNTRegistrationCoach;