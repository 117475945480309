import EditIcon from '../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';

import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ sponsor, onEditClicked, onDeleteClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span className='hidden-xs'>{sponsor.sponsor?.sponsorName || <span>&nbsp;</span>}</span>&nbsp;
      <div className={global.FloatRight}>
        <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onDeleteClicked(e, sponsor)}>
          <DeleteIcon />
        </button>
        <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEditClicked(e, sponsor)}>
          <EditIcon />
        </button>
      </div>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Sponsor Name
        </div>
        <div className={['col-xs-6 visible-xs', global.SmallTableRowData].join(' ')}>
          {sponsor.sponsor?.sponsorName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Sponsor Type
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {sponsor.sponsor?.sponsorType?.typeName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Effective Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {sponsor.effectiveDate ? formatDate(sponsor.effectiveDate) : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Expiration Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {sponsor.expirationDate ? formatDate(sponsor.expirationDate) : <span>&nbsp;</span>}
        </div>
      </div>
    </div>
  </div>
);

const SponsorsGridSmall = ({ gridData, isLoading, onEditClicked, onDeleteClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      ) : Array.isArray(gridData) && gridData.length > 0
        ? gridData.map((sponsor, i) => <GridRow key={i} sponsor={sponsor} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Sponsors</div>
            </div>
          </div>
        )
    }
  </div>
);

export default SponsorsGridSmall;