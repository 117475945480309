import { Fragment } from "react";

import useOfferingInstanceAddForm from "./UseOfferingInstanceAddForm";

import ReadOnly from "../../../../../../common/components/readOnly/ReadOnly";
import Dropdown from "../../../../../../common/components/dropdowns/Dropdown";
import PopUpModal from "../../../../../../common/components/dialogs/PopUpModal";
import DatePicker from "../../../../../../common/components/datepickers/DatePicker";
import YesNoSwitch from "../../../../../../common/components/yesNoSwitch/YesNoSwitch";
import CurrencyInput from "../../../../../../common/components/inputs/CurrencyInput";
import PrimaryButton from "../../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../../common/components/buttons/SecondaryButton";

const OfferingInstanceAddForm = ({ reportPeriodObj, submitButtonText = 'Save', onSubmitFormCallback,
  onBackClicked }) => {
  const {
    feeTypeName,
    state,
    formState,
    errorState,
    updateFormState,
    onEnableMaxLscFeeAmountChanged,
    onOfferingTypeChanged,
    handleSubmit
  } = useOfferingInstanceAddForm(onSubmitFormCallback, reportPeriodObj);

  return (
    <Fragment>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="Season Year"
              name="periodName"
              value={reportPeriodObj.periodName} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="Fee Type"
              name="feeTypeName"
              value={feeTypeName} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label="Available Offerings*"
              name="offeringId"
              value={formState.offeringId}
              error={errorState.offeringId}
              message={errorState.offeringId}
              options={state.offeringOptions}
              isLoading={state.areOfferingOptionsLoaded !== true}
              onChange={(newValue, newValueLabel, e) => {
                e && e.target && e.target.value && onOfferingTypeChanged(newValue)
              }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <CurrencyInput
              label="Fee Amount*"
              name="feeAmount"
              value={formState.feeAmount}
              error={errorState.feeAmount}
              message={errorState.feeAmount}
              onChange={(value) => { updateFormState('feeAmount', value); }} />
          </div>
          {formState.isClubOffering === true &&
            <div className="col-xs-12 col-sm-6 col-md-4">
              <CurrencyInput
                label="Renewal Fee Amount*"
                name="renewalFeeAmount"
                value={formState.renewalFeeAmount}
                error={errorState.renewalFeeAmount}
                message={errorState.renewalFeeAmount}
                onChange={(value) => { updateFormState('renewalFeeAmount', value); }} />
            </div>
          }
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label="Default Registration Start Date*"
              name="defaultRegStartDate"
              value={formState.defaultRegStartDate}
              error={errorState.defaultRegStartDate}
              message={errorState.defaultRegStartDate}
              onChange={(value) => { updateFormState('defaultRegStartDate', value); }}
              countOfYears={35}
              startYearOffset={-20} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label="Default Registration End Date*"
              name="defaultRegEndDate"
              value={formState.defaultRegEndDate}
              error={errorState.defaultRegEndDate}
              message={errorState.defaultRegEndDate}
              onChange={(value) => { updateFormState('defaultRegEndDate', value); }}
              countOfYears={35}
              startYearOffset={-20} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label="Default Effective Start Date*"
              name="defaultEffectiveDate"
              value={formState.defaultEffectiveDate}
              error={errorState.defaultEffectiveDate}
              message={errorState.defaultEffectiveDate}
              onChange={(value) => { updateFormState('defaultEffectiveDate', value); }}
              countOfYears={35}
              startYearOffset={-20} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label="Default Expiration End Date*"
              name="defaultExpirationDate"
              value={formState.defaultExpirationDate}
              error={errorState.defaultExpirationDate}
              message={errorState.defaultExpirationDate}
              onChange={(value) => { updateFormState('defaultExpirationDate', value); }}
              countOfYears={35}
              startYearOffset={-20} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <YesNoSwitch
              label="Set Maximum LSC Fee?*"
              name="enableMaxLscFeeAmount"
              checked={formState.enableMaxLscFeeAmount}
              error={errorState.enableMaxLscFeeAmount}
              message={errorState.enableMaxLscFeeAmount}
              onChange={(value) => onEnableMaxLscFeeAmountChanged(value)} />
          </div>
          {formState.enableMaxLscFeeAmount === true &&
            <div className="col-xs-12 col-sm-6 col-md-4">
              <CurrencyInput
                label="Maximum LSC Fee Amount*"
                name="maxLscFeeAmount"
                value={formState.maxLscFeeAmount}
                error={errorState.maxLscFeeAmount}
                message={errorState.maxLscFeeAmount}
                onChange={(value) => { updateFormState('maxLscFeeAmount', value); }} />
            </div>
          }
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type='submit'>{submitButtonText}</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Available Offerings Error'}
        displayPopUp={state.apiErrorMessage !== ''}
        onModalCanceled={onBackClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>{state.apiErrorMessage}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <SecondaryButton type="button" onClick={onBackClicked}>Close</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default OfferingInstanceAddForm;