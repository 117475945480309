import { Fragment } from 'react';

import useNationalTeamSupportStaff from './UseNationalTeamSupportStaff';

import NTSupportStaffGrid from '../../../components/grids/nationalTeamSupportStaff/NTSupportStaffGrid';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

import { LOADING_MSG } from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';
import { formatPhoneNumber } from '../../../../common/utils/validation';

const NationalTeamSupportStaff = () => {
  const {
    isLoading,
    gridData,
    state,
    onAddSupportStaffClicked,
    onAddSupportStaffRoleClicked,
    onEditSupportStaffClicked,
    onEditSupportStaffRoleClicked,
    onDeleteSupportStaffClicked,
    onDeleteSupportStaffRoleClicked,
    onDeleteStaffPopupConfirmClicked,
    onDeleteStaffRolePopupConfirmClicked,
    onDeletePopupCancelClicked
  } = useNationalTeamSupportStaff();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Support Staff</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton onClick={onAddSupportStaffClicked}>Add Support Staff</ActionIntraPageButton>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <NTSupportStaffGrid
            gridData={gridData}
            isLoading={isLoading}
            onAddRoleClicked={onAddSupportStaffRoleClicked}
            onEditClicked={onEditSupportStaffClicked}
            onEditRoleClicked={onEditSupportStaffRoleClicked}
            onDeleteClicked={onDeleteSupportStaffClicked}
            onDeleteRoleClicked={onDeleteSupportStaffRoleClicked} />
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Delete Support Staff?'}
        displayPopUp={state.displayDeleteStaffPopup === true}
        onModalCanceled={onDeletePopupCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>{state.deleteSupportStaffObj.firstName} {state.deleteSupportStaffObj.lastName} (Email: {state.deleteSupportStaffObj.email}, Phone Number: {formatPhoneNumber(state.deleteSupportStaffObj.phoneNumber)})</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeleteStaffPopupConfirmClicked}>Yes, Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onDeletePopupCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Delete Support Staff Role?'}
        displayPopUp={state.displayDeleteStaffRolePopup === true}
        onModalCanceled={onDeletePopupCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>{state.deleteSupportStaffRoleObj.supportStaffType?.typeName} ({formatDate(state.deleteSupportStaffRoleObj.effectiveDate)} - {formatDate(state.deleteSupportStaffRoleObj.expirationDate)})</p>
          </div>
          {state.isLastRole === true &&
            <div className="col-xs-12">
              <p><b>Removing {state.deleteSupportStaffObj.firstName} {state.deleteSupportStaffObj.lastName}&apos;s last role will remove them as a support staff.</b></p>
            </div>
          }
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeleteStaffRolePopupConfirmClicked}>Yes, Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onDeletePopupCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
}
export default NationalTeamSupportStaff;