import { useEffect } from 'react';

import { isPersonObjectDataCurrent } from '../utils/MemberUtils';

import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';
import usePersonProfileData from '../../../state/personProfile/UsePersonProfileData';

const useProfileFunFacts = () => {
  const { basePersonState } = useBasePersonData();
  const { personProfileState, getPersonProfile, putPersonProfile } = usePersonProfileData();

  const onSubmitFunFactsForm = (funFacts) => {
    const personId = basePersonState.objData.personId;
    const newPersonProfileObj = JSON.parse(JSON.stringify(personProfileState.objData));
    newPersonProfileObj.funFacts = funFacts;

    putPersonProfile(personId, newPersonProfileObj);
  };

  useEffect(() => {
    if (personProfileState.isObjLoaded === false
      || !isPersonObjectDataCurrent(basePersonState.objData.personId, personProfileState.objData)) {
      getPersonProfile(basePersonState.objData.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState]);

  return {
    isLoading: personProfileState.isObjLoading || personProfileState.isSaving,
    personId: basePersonState.objData.personId,
    gridData: personProfileState.objData?.funFacts || [],
    onSubmitFunFactsForm
  };
};

export default useProfileFunFacts;