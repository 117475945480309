import { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import PlusIcon from '../../../../common/components/icons/PlusIcon';

import global from '../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ address, onEditAddressClicked, onDeleteAddressClicked }) => (
  <tr>
    <td>{address.address || ''}</td>
    <td>{address.city || ''}</td>
    <td>{address.stateCode || ''}</td>
    <td>{address.postalCode || ''}</td>
    <td>{address.addressTypeNames || ''}</td>
    <td className='pull-right'>
      {onEditAddressClicked &&
        <button className={global.IconButtonMargin} type="button" onClick={(e) => onEditAddressClicked(e, address.contactId, address.addressId)}>
          <EditIcon toolTipText='Edit Address' />
        </button>
      }
      {onDeleteAddressClicked &&
        <button className={global.IconButtonMargin} type="button" onClick={(e) => onDeleteAddressClicked(e, address)}>
          <DeleteIcon toolTipText='Delete Address' />
        </button>
      }
    </td>
  </tr>
);

const GridRowDetailTable = ({ addresses, onEditAddressClicked, onDeleteAddressClicked }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Address</th>
        <th>City</th>
        <th>State</th>
        <th>Zip Code</th>
        <th>Type(s)</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(addresses) && addresses.length > 0
        ? addresses.map((address, i) => <DetailTableRow key={i} address={address} onEditAddressClicked={onEditAddressClicked} onDeleteAddressClicked={onDeleteAddressClicked} />)
        : <tr><td colSpan='6'>No Addresses</td></tr>
      }
    </tbody>
  </table>
);

const LargeGridRow = ({ contact, onAddAddressClicked, onEditClicked, onEditAddressClicked,
  onDeleteClicked, onDeleteAddressClicked }) => (
  <Fragment>
    <tr>
      <td colSpan='2'>{contact.name || ''}</td>
      <td>{contact.relationship || ''}</td>
      <td>{contact.email || ''}</td>
      <td>{contact.phoneNumber || ''}</td>
      <td className='pull-right'>
        {onAddAddressClicked &&
          <button className={global.IconButtonMargin} type="button" onClick={(e) => onAddAddressClicked(e, contact.contactId)}>
            <PlusIcon toolTipText='Add Address' />
          </button>
        }
        {onEditClicked &&
          <button className={global.IconButtonMargin} type="button" onClick={(e) => onEditClicked(e, contact.contactId)}>
            <EditIcon toolTipText='Edit Contact' />
          </button>
        }
        {contact.canDelete && onDeleteClicked &&
          <button className={global.IconButtonMargin} type="button" onClick={(e) => onDeleteClicked(e, contact)}>
            <DeleteIcon toolTipText='Delete Contact' />
          </button>
        }
      </td>
    </tr>
    <tr className={global.Expanded}>
      <td colSpan="6">
        <GridRowDetailTable addresses={contact.contactAddresses} onEditAddressClicked={onEditAddressClicked} onDeleteAddressClicked={onDeleteAddressClicked} />
      </td>
    </tr>
  </Fragment>
);

const NTContactsGridLarge = ({ gridData, isLoading, onAddAddressClicked, onEditClicked, onEditAddressClicked,
  onDeleteClicked, onDeleteAddressClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th colSpan='2'>Name</th>
        <th>Relationship</th>
        <th>Email</th>
        <th>Phone Number</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="6">Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0
          ? gridData.map((contact, i) => <LargeGridRow key={i} contact={contact} onAddAddressClicked={onAddAddressClicked} onEditClicked={onEditClicked} onEditAddressClicked={onEditAddressClicked} onDeleteClicked={onDeleteClicked} onDeleteAddressClicked={onDeleteAddressClicked} />)
          : <tr><td colSpan="6">No Contacts</td></tr>
      }
    </tbody>
  </table>
);

export default NTContactsGridLarge