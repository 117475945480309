import { useContext, useState } from 'react';

import { PersonNationalTeamStateContext } from './PersonNationalTeamContextProvider';
import PersonNationalTeamData from './PersonNationalTeamData';

const usePersonNationalTeamData = () => {
  const [personNationalTeamState, setPersonNationalTeamState] = useContext(PersonNationalTeamStateContext);
  const [personNationalTeamAdvancedState, setPersonNationalTeamAdvancedState] = useState(PersonNationalTeamData.INITIAL_PERSON_NATIONAL_TEAM_ADVANCED_STATE);

  const getPersonNationalTeam = (personId) => {
    return PersonNationalTeamData.getPersonNationalTeamData(personId, personNationalTeamState, setPersonNationalTeamState);
  };

  const getPersonNationalTeamAdvanced = (personId) => {
    return PersonNationalTeamData.getPersonNationalTeamAdvancedData(personId, personNationalTeamAdvancedState, setPersonNationalTeamAdvancedState);
  };

  const putPersonNationalTeam = (personId, personNTObj) => {
    return PersonNationalTeamData.putPersonNationalTeamData(personId, personNTObj, personNationalTeamState, setPersonNationalTeamState);
  };

  const putPersonNationalTeamAdvanced = (personId, personNTAdvancedObj) => {
    return PersonNationalTeamData.putPersonNationalTeamAdvancedData(personId, personNTAdvancedObj, personNationalTeamAdvancedState, setPersonNationalTeamAdvancedState);
  };

  return {
    personNationalTeamState,
    personNationalTeamAdvancedState,
    getPersonNationalTeam,
    putPersonNationalTeam,
    getPersonNationalTeamAdvanced,
    putPersonNationalTeamAdvanced
  };
};

export default usePersonNationalTeamData;