import { useContext, useState } from "react";

import NTRegistrationData from "./NTRegistrationData";
import { NTRegistrationContext, NTRegistrationPageStateContext } from "./NTRegistrationContextProvider";

const useNTRegistrationData = () => {
  const [nationalTeamRegistrationState, setNationalTeamRegistrationState] = useContext(NTRegistrationContext);
  const [nationalTeamRegistrationPageState, setNationalTeamRegistrationPageState] = useContext(NTRegistrationPageStateContext);
  const [groupMemberRegistrationState, setGroupMemberRegistrationState] = useState(NTRegistrationData.INITIAL_GROUP_MEMBER_REGISTRATION_STATE);

  const postNTGroupMemberRegistration = (personNationalTeamGroupId) => {
    return NTRegistrationData.postNTGroupMemberRegistrationData(personNationalTeamGroupId, groupMemberRegistrationState, setGroupMemberRegistrationState);
  };

  const getNTGroupMemberRegistration = (personId) => {
    return NTRegistrationData.getNTGroupMemberRegistrationData(personId, groupMemberRegistrationState, setGroupMemberRegistrationState);
  };

  const onNTRegistrationError = (e, errorMessage = undefined, errorTitle = 'National Team Registrations Error') => {
    setNationalTeamRegistrationState({
      ...nationalTeamRegistrationState,
      isError: true,
      error: e,
      errorTitle,
      errorMessage: errorMessage || e?.message || 'An error occurred.'
    });
  };

  const onNavigateWithoutSaving = (navigatePath, navigateState = undefined, navigateWarningPopupTitle = 'This page has unsaved changes!') => {
    setNationalTeamRegistrationPageState({
      ...nationalTeamRegistrationPageState,
      displayNavigateWarningPopup: true,
      navigateWarningPopupTitle,
      navigatePath,
      navigateState
    });
  };

  const updatePageHasUnsavedChanges = (hasUnsavedChanges) => {
    setNationalTeamRegistrationPageState({
      ...nationalTeamRegistrationPageState,
      hasUnsavedChanges: hasUnsavedChanges === true ? true : false
    });
  };

  const onCloseNavigateWarningPopup = () => {
    setNationalTeamRegistrationPageState({
      ...nationalTeamRegistrationPageState,
      displayNavigateWarningPopup: false,
      navigateWarningPopupTitle: '',
      navigatePath: '',
      navigateState: undefined
    });
  };

  const resetNationalTeamRegistrationState = () => {
    setNationalTeamRegistrationState({
      ...NTRegistrationData.INITIAL_NATIONAL_TEAM_REGISTRATION_STATE
    });
  };

  const resetNationalTeamRegistrationPageState = () => {  
    setNationalTeamRegistrationPageState({
      ...NTRegistrationData.INITIAL_NATIONAL_TEAM_REGISTRATION_PAGE_STATE
    });
  };

  return {
    pageHasUnsavedChanges: nationalTeamRegistrationPageState.hasUnsavedChanges === true,
    nationalTeamRegistrationState,
    nationalTeamRegistrationPageState,
    groupMemberRegistrationState,
    postNTGroupMemberRegistration,
    getNTGroupMemberRegistration,
    onNTRegistrationError,
    onNavigateWithoutSaving,
    updatePageHasUnsavedChanges,
    onCloseNavigateWarningPopup,
    resetNationalTeamRegistrationState,
    resetNationalTeamRegistrationPageState
  };
};

export default useNTRegistrationData;