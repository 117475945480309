import { useEffect, useState } from 'react';

import validate from './SAARegistrationFormValidation';

import { createGroupMemberObj, getGroupPropertyOptions, getNewGroupStatus } from '../../../utils/GroupsUtils';
import { compareAlphabetical } from '../../../utils/SortingUtils';

import useMemberGroupsFileData from '../../../state/memberGroupsFile/UseMemberGroupsFileData';
import useGroupFieldNamesData from '../../../state/groupFieldNames/UseGroupFieldNamesData';
import useOrgGroupPropertiesData from '../../../state/orgGroupProperties/UseOrgGroupPropertiesData';

import UseForm from '../../../utils/UseForm';
import Constants from '../../../utils/Constants';

const INITIAL_FORM_STATE = {
  highSchoolName: '',
  highSchoolGraduationYear: 'Year',
  gpa: '',
  unweightedGPA: '',
  gpaUrl: '',
  gpaFile: {},
  paraStatusId: '',
  paraStatusName: '--',
  openWaterStatusId: '',
  openWaterStatusName: '--'
};

const INITIAL_OPTIONS_STATE = {
  areOptionsLoaded: false,
  paraOptions: [],
  openWaterOptions: []
};

const FILE_TYPE_ERROR_MSG = 'File type not permitted. Please provide a (*.pdf), (*.png), (*.jpg) or (*.jpeg) file.';

const useSAARegistrationForm = (personId, personOrgGroupId, personOrgGroupQualificationStatus, groupObj, memberGroupObj, disableValidation, onFormCompletion) => {
  const { orgGroupPropertiesState, getOrgGroupProperties } = useOrgGroupPropertiesData();
  const { memberGroupsFileUploadState, memberGroupsFileDownloadState, postGroupMemberGPAFile,
    getGroupMemberGPAFile, getFileNameFromUrl } = useMemberGroupsFileData();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { formState, errorState, setFormState, setErrors, onValueTextPairChanged, updateFormState, handleSubmit
  } = UseForm(INITIAL_FORM_STATE, submitFormCallback, disableValidation === true ? () => { } : validate);
  const [optionsState, setOptionsState] = useState(INITIAL_OPTIONS_STATE);

  const onFileLoad = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setErrors({ ...errorState, gpaFile: '' });
    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];
      const fileType = e.target.files[0].type;

      if (fileType === Constants.FILE_TYPE_PDF || fileType === Constants.FILE_TYPE_JPG
        || fileType === Constants.FILE_TYPE_PNG) {
        updateFormState('gpaFile', selectedFile);
      }
      else {
        setErrors({ ...errorState, gpaFile: FILE_TYPE_ERROR_MSG });
      }
    }
  };

  const onFileUnload = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setFormState({
      ...formState,
      gpaFile: {},
      gpaUrl: ''
    });
  };

  const onFileDownload = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    showUploadedFile(formState.gpaUrl)
  };

  useEffect(() => {
    if (groupObj.groupTypeId > 0 && optionsState.areOptionsLoaded === false) {
      if (orgGroupPropertiesState.isArrayLoaded === false || groupObj.groupTypeId !== orgGroupPropertiesState.groupTypeId) {
        const getOrgGroupPropertiesPromise = getOrgGroupProperties(groupObj.groupTypeId);

        if (getOrgGroupPropertiesPromise !== null) {
          getOrgGroupPropertiesPromise.catch((e) => {
            //TODO
          });
        }
      } else {
        const paraOptions = getGroupPropertyOptions(GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_PARA_STATUS, orgGroupPropertiesState.arrayData);
        const openWaterOptions = getGroupPropertyOptions(GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_OPEN_WATER_STATUS, orgGroupPropertiesState.arrayData);
        const sortedParaOptions = paraOptions.sort((a, b) => compareAlphabetical(a.name, b.name))

        setOptionsState({
          ...optionsState,
          paraOptions: sortedParaOptions,
          openWaterOptions,
          areOptionsLoaded: true
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupObj, orgGroupPropertiesState.isArrayLoaded]);

  useEffect(() => {
    if (optionsState.areOptionsLoaded === true && memberGroupObj) {
      const personGroupTypeField = memberGroupObj.personGroupTypeField || [];
      const paraStatusName = personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_PARA_STATUS)?.fieldValue || '';
      const openWaterStatusName = personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_OPEN_WATER_STATUS)?.fieldValue || '';

      const paraStatus = optionsState.paraOptions.find(x => x.name === paraStatusName);
      const openWaterStatus = optionsState.openWaterOptions.find(x => x.name === openWaterStatusName);

      setFormState({
        ...formState,
        highSchoolName: personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_HIGH_SCHOOL)?.fieldValue || '',
        highSchoolGraduationYear: personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_HIGH_SCHOOL_GRADUATION_YEAR)?.fieldValue || 'Year',
        gpa: personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_GPA)?.fieldValue || '',
        unweightedGPA: personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_UNWEIGHTED_GPA)?.fieldValue || '',
        gpaUrl: personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_GPA_FILE)?.fieldValue || '',
        paraStatusId: paraStatus?.id || optionsState.paraOptions[0]?.id || '',
        paraStatusName: paraStatus?.name || optionsState.paraOptions[0]?.name || '',
        openWaterStatusId: openWaterStatus?.id || optionsState.openWaterOptions[0]?.id || '',
        openWaterStatusName: openWaterStatus?.name || optionsState.openWaterOptions[0]?.name || ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsState.areOptionsLoaded, memberGroupObj]);

  function submitFormCallback(formState) {
    if (formState.gpaFile?.size > 0) {
      const postGroupMemberGPAFilePromise = postGroupMemberGPAFile(formState.gpaFile);

      if (postGroupMemberGPAFilePromise !== null) {
        postGroupMemberGPAFilePromise.then((newState) => {
          if (newState !== null) {
            const gpaFileUrl = newState.objData?.uploadUrl;
            const orgGroupId = groupObj.orgGroupId;
            const personOrgGroupQualification = Array.isArray(memberGroupObj.personOrgGroupQualification) && memberGroupObj.personOrgGroupQualification.length > 0
              ? memberGroupObj.personOrgGroupQualification[0]
              : undefined;

            if (personId && orgGroupId && gpaFileUrl) {
              const groupStatus = getNewGroupStatus(personOrgGroupQualificationStatus, groupObj.requiresApproval);

              const groupMemberObj = createGroupMemberObj(personId, orgGroupId, [
                { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_HIGH_SCHOOL, fieldValue: formState.highSchoolName },
                { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_HIGH_SCHOOL_GRADUATION_YEAR, fieldValue: formState.highSchoolGraduationYear },
                { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_GPA, fieldValue: formState.gpa },
                { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_UNWEIGHTED_GPA, fieldValue: formState.unweightedGPA },
                { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_GPA_FILE, fieldValue: gpaFileUrl },
                { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_PARA_STATUS, fieldValue: formState.paraStatusId },
                { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_OPEN_WATER_STATUS, fieldValue: formState.openWaterStatusId }
              ], orgGroupPropertiesState.arrayData, personOrgGroupId, personOrgGroupQualification, groupStatus);

              onFormCompletion(groupMemberObj);
            }
          }
        }).catch((e) => {
          //TODO
        });
      }
    } else {
      const orgGroupId = groupObj.orgGroupId;
      const personOrgGroupQualification = Array.isArray(memberGroupObj.personOrgGroupQualification) && memberGroupObj.personOrgGroupQualification.length > 0
        ? memberGroupObj.personOrgGroupQualification[0]
        : undefined;

      if (personId && orgGroupId) {
        const groupStatus = getNewGroupStatus(personOrgGroupQualificationStatus, groupObj.requiresApproval);

        const groupMemberObj = createGroupMemberObj(personId, orgGroupId, [
          { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_HIGH_SCHOOL, fieldValue: formState.highSchoolName },
          { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_HIGH_SCHOOL_GRADUATION_YEAR, fieldValue: formState.highSchoolGraduationYear },
          { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_GPA, fieldValue: formState.gpa },
          { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_UNWEIGHTED_GPA, fieldValue: formState.unweightedGPA },
          { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_GPA_FILE, fieldValue: formState.gpaUrl },
          { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_PARA_STATUS, fieldValue: formState.paraStatusId },
          { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_OPEN_WATER_STATUS, fieldValue: formState.openWaterStatusId }
        ], orgGroupPropertiesState.arrayData, personOrgGroupId, personOrgGroupQualification, groupStatus);

        onFormCompletion(groupMemberObj);
      }
    }
  }

  async function showUploadedFile(fileUrl) {
    if (fileUrl) {
      const getFileResult = await getGroupMemberGPAFile(fileUrl);

      if (getFileResult) {
        if (getFileResult.objData?.blobFile?.size > 0) {
          var file = window.URL.createObjectURL(getFileResult.objData?.blobFile);
          const link = document.createElement('a');

          link.href = file;
          link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      }
    }
  }

  return {
    isLoading: memberGroupsFileDownloadState.isObjLoading,
    isSaving: memberGroupsFileUploadState.isObjLoading,
    optionsState,
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    onFileDownload,
    onFileLoad,
    onFileUnload,
    getFileNameFromUrl
  };
};

export default useSAARegistrationForm;