import Input from '../../../../common/components/inputs/Input';

import DeleteIcon from '../../../../common/components/icons/DeleteIcon';

import global from '../../../../common/components/GlobalStyle.module.css';
import style from './FrequentFlyerFormGrid.module.css';

const GridRow = ({ frequentFlyer, onUpdateFormGridState }) => (
  <tr>
    <td>{frequentFlyer.name || ''}</td>
    <td style={{ width: "60%" }}>
      <Input
        name={`value${frequentFlyer.id}`}
        value={frequentFlyer.value}
        onChange={(newValue) => { onUpdateFormGridState(frequentFlyer.id, newValue) }} />
    </td>
    <td>
      {frequentFlyer.hasValue === true &&
        <button className={global.IconButtonMargin} type="button" onClick={() => onUpdateFormGridState(frequentFlyer.id, '')}>
          <DeleteIcon toolTipText='Clear Value' />
        </button>
      }
    </td>
  </tr>
);

const FrequentFlyerFormGridLarge = ({ formGridState, onUpdateFormGridState }) => (
  <table className={[global.UsasTable, style.LargeFormGrid, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Carrier</th>
        <th>Frequent Flyer Number</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(formGridState) && formGridState.length > 0
        ? formGridState.map((frequentFlyer, i) =>
          <GridRow key={i} frequentFlyer={frequentFlyer} onUpdateFormGridState={onUpdateFormGridState} />)
        : <tr><td colSpan="3">No Frequent Flyer Info</td></tr>
      }
    </tbody>
  </table>
);

export default FrequentFlyerFormGridLarge;