/* eslint-disable no-debugger */
import { useEffect, useState } from 'react';

import validate from './MemberTimesFilterValidation';
import { formatPlaceString } from './MemberTimesUtils';

import usePersonSwimTimeData from '../../../state/personSwimTime/UsePersonSwimTimeData';

import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useOrganizationData from '../../../../common/state/organization/UseOrganizationData';
import useDecryptIdData from '../../../../common/state/decryptId/UseDecryptIdData';
import useEmbeddedReportData from '../../../../common/state/embeddedReport/UseEmbeddedReportData';
import useReportPeriodSeasonData from '../../../../common/state/reportPeriodSeason/UseReportPeriodSeasonData';

import { formatTimeForFilterObject } from '../../../../common/utils/TimesUtils';
import formatCrossUiRoute from '../../../../common/utils/FormatCrossUiRoute';
import { formatTimeForDisplay } from '../../../../common/utils/TimesUtils';
import { formatDate } from '../../../../common/utils/DateFunctions';

import Constants from '../../../../common/utils/Constants';
import useForm from '../../../../common/utils/UseForm';

const INITIAL_VIEW_STATE = {
  reportParameters: { PersonId: '' },
  personId: '',
  routeName: '',
  isLoading: false,
  reportViewerType: '',
  reportName: ''
};

const useMemberTimes = () => {
  const { environmentVariableState, USAS_ORGANIZATION_ID } = useEnvironmentVariableData();
  const { basePersonState } = useBasePersonData();
  const { reportPeriodSeasonState } = useReportPeriodSeasonData();
  const { personSwimTimeState, getPersonSwimTimesFiltered } = usePersonSwimTimeData();
  const { errorState, formState, handleSubmit, updateFormState, setErrors, onValueTextPairChanged } = useForm(getInitialFormState, submitFormCallback, validate);
  const [gridState, setGridState] = useState(getInitialGridState);
  const [filterState, setFilterState] = useState(getInitialFilterState);
  const { organizationState } = useOrganizationData();
  const { getDecryptedId } = useDecryptIdData();
  const { getEmbeddedReportListForRoute } = useEmbeddedReportData();

  const [viewState, setViewState] = useState(INITIAL_VIEW_STATE);

  const onEditTimeClicked = (swimTimeId) => {
    const relativePath = `/times/byid/${swimTimeId}/member`;
    const formattedUrlObject = formatCrossUiRoute(Constants.UI_PROJECT_NAMES.TIMES, relativePath, false);
    window.location.href = formattedUrlObject.route;
  };

  const onViewReport = () => {
    const idObj = {
      "personId": basePersonState.objData.personId
    }

    const seasonVal = reportPeriodSeasonState.options.find(x => x.id === formState.reportPeriodId)?.name ?? '';
    const orgVal = organizationState.timeRecognizedOrganizationsAsOptions.find(x => x.id === formState.organizationId)?.name ?? ''
    const sessionVal = filterState.sessions.find(x => x.id === formState.sessionId)?.name ?? '';
    const eventVal = filterState.eventCourses.find(x => x.id === formState.eventCourseId)?.name ?? '';

    const getDecryptedIdPromise = getDecryptedId(idObj);

    if (getDecryptedIdPromise ?? false) {
      getDecryptedIdPromise.then((newState) => {

        let keys = [];
        keys.push('PersonId');
        keys.push('BestTimesOnly');
        if (seasonVal !== '--') keys.push('Season');
        if (orgVal !== '--') keys.push('Organization'); 
        if (sessionVal !== '') keys.push('Session');
        if (eventVal !== '') keys.push('EventCourse');

        let values = [];
        values.push(newState.objData.personNumber);
        values.push(formState.showBestTimes === true ? "True" : "False");
        if (seasonVal !== '--') values.push(seasonVal);
        if (orgVal !== '--') values.push(orgVal);
        if (sessionVal !== '') values.push(sessionVal);
        if (eventVal !== '') values.push(eventVal);

        const reportParameters = keys.reduce((acc, key, index) => {
        acc[key] = values[index];
           return acc;
         }, {});

        setViewState({
          ...viewState,
          reportParameters,
          routeName: 'MEMBER_TIMES', 
          isLoading: true,
          reportViewerType: 'ActiveReports',
          reportName: 'Member Times Report'
        });
      }).catch((e) => {
        console.log(e);
      })
    }
  }

  function submitFormCallback(formState) {
    if (personSwimTimeState.isArrayLoading === false) {
      getPersonSwimTimesFiltered(
        basePersonState.objData.personId,
        formState.organizationId,
        formState.eventCourseId === Constants.DEFAULT_ID ? 0 : formState.eventCourseId,
        formState.sessionId === Constants.DEFAULT_ID ? 0 : formState.sessionId,
        formState.reportPeriodId === Constants.DEFAULT_ID ? 0 : formState.reportPeriodId);
    }
  }

  function getInitialFormState() {
    return {
      eventCourseId: Constants.DEFAULT_ID,
      eventCourseName: '',
      sessionId: Constants.DEFAULT_ID,
      sessionName: '',
      reportPeriodId: Constants.DEFAULT_ID,
      reportPeriodName: '',
      showBestTimes: false,
      organizationId: Constants.DEFAULT_ID,
      organizationName: ''
    };
  }

  function getInitialGridState() {
    return {
      arrayData: [],
      displayArray: [],
      isArrayLoading: false,
      isArrayLoaded: false
    };
  }

  function getInitialFilterState() {
    return {
      eventCourses: [{ id: Constants.DEFAULT_ID, name: '' }],
      sessions: [{ id: Constants.DEFAULT_ID, name: '' }],
      dateRanges: [{ id: Constants.DEFAULT_ID, name: '' }]
    };
  }

  function formatGridData(data) {
    return data.map((x) => {
      return {
        swimTimeId: x.swimTimeId,
        time: formatTimeForDisplay(x.swimTime),
        competitor: x.competitor,
        eventGender: x.eventGender,
        swimDate: formatDate(x.swimDate),
        eventCourse: x.eventCourse,
        eventName: x.eventName,
        meetName: x.meetName,
        session: x.session,
        finishPosition: formatPlaceString(x.finishPosition),
        standardName: x.standardName,
        orgUnitName: x.orgUnitName,
        splits: x.splits,
        eventId: x.eventId
      };
    });
  }

  const distinctPrimitive = (arrayOfPrimitiveValues) => {
    const arrayData = arrayOfPrimitiveValues.filter(p => p !== null);
    return [...new Set(arrayData)];
  }

  const getDistinctArray = (arrayData, objectValue) => {
    const arrayString = arrayData.map((time) => {
      return time[objectValue];
    });
    const distinctArray = distinctPrimitive(arrayString).map((string, index) => {
      const id = index + 1;
      return { id: id, name: string };
    });
    return distinctArray;
  }

  const getEventsArray = (arrayData) => {
    let eventArray = [];
    arrayData.forEach((item) => {
      const eventObj = { id: item.eventId, name: item.eventName };

      if (eventArray.find(x => x.id === eventObj.id) === undefined) {
        eventArray.push(eventObj);
      }
    })

    return eventArray;
  }

  function populateEventCourseFilterOptions(data) {
    const eventCourse = getEventsArray(data);
    return eventCourse;
  }

  function populateSessionFilterOptions(data) {
    return [{ id: 0, name: "--" }, { id: 3, name: "Final" }, { id: 1, name: "Prelim" }, { id: 2, name: "Semifinal" }];
  }

  function populateDateRangeFilterOptions(data) {
    const dateRange = getDistinctArray(data, 'swimDate');
    dateRange.sort((a, b) => new Date(a.name) - new Date(b.name));
    return dateRange;
  }

  function filterBestTimes(timeArray) {
    const arrayCopy = JSON.parse(JSON.stringify(timeArray));
    let filteredArray = [];

    for (let i = 0; i < timeArray.length; i++) {
      let index = filteredArray.findIndex(x => x.eventName === arrayCopy[i].eventName);
      if (index === -1) {
        let sameEventArray = [];
        sameEventArray = arrayCopy.filter(timeObj => timeObj.eventName === arrayCopy[i].eventName);
        //sort from fastest to slowest time
        sameEventArray.sort((a, b) => {
          const aTime = formatTimeForFilterObject(a.time);
          const dateA = new Date(2000, 0, 1, aTime.substring(0, 2), aTime.substring(3, 5), aTime.substring(6, 8), aTime.substring(9, 11));
          const bTime = formatTimeForFilterObject(b.time);
          const dateB = new Date(2000, 0, 1, bTime.substring(0, 2), bTime.substring(3, 5), bTime.substring(6, 8), bTime.substring(9, 11));

          return dateA - dateB;
        });
        //add fastest time
        filteredArray.push(sameEventArray[0]);
        //if the swimmer swam the their fastest time more than once, show those times too
        if (sameEventArray.length > 1) {
          for (let i = 1; i < sameEventArray.length; i++) {
            if (sameEventArray[i].time === sameEventArray[0].time) {
              filteredArray.push(sameEventArray[i]);
            }
          }
        }
      }
    }

    return filteredArray;
  }

  useEffect(() => {
    if (personSwimTimeState.isArrayLoaded === true) {
      let formattedGridData = formatGridData(personSwimTimeState.arrayData);

      //if showBestTimes then filter data here
      if (formState.showBestTimes === true) {
        formattedGridData = filterBestTimes(formattedGridData);
      }

      setGridState({
        ...gridState,
        arrayData: formattedGridData,
        isArrayLoaded: true,
        isArrayLoading: false
      });

      if (filterState.sessions.length === 1) {
        const newEventCourses = populateEventCourseFilterOptions(formattedGridData);
        const newSessions = populateSessionFilterOptions(formattedGridData);
        const newDateRanges = populateDateRangeFilterOptions(formattedGridData);

        setFilterState({
          ...filterState,
          eventCourses: filterState.eventCourses.concat(newEventCourses),
          sessions: newSessions,
          dateRanges: filterState.dateRanges.concat(newDateRanges)
        });
      }
      setErrors({ filter: 'Please use filters to search for times' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personSwimTimeState.isArrayLoaded]);

  useEffect(() => {
    if (
      basePersonState.isObjLoaded === true &&
      personSwimTimeState.isArrayLoaded === false &&
      personSwimTimeState.isArrayLoading === false &&
      formState.organizationId > 0) {
      getPersonSwimTimesFiltered(basePersonState.objData.personId, formState.organizationId, 0, 0, 0);
      setGridState({
        ...gridState,
        isArrayLoading: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState, formState]);

  useEffect(() => {
    if (viewState.routeName) {
      const reportListPromise = getEmbeddedReportListForRoute(viewState.routeName);

      if (reportListPromise) {
        reportListPromise.then((stateObj) => {
          if (Array.isArray(stateObj.arrayData)) {
            const reportIdx = stateObj.arrayData.findIndex(x => x.reportName === viewState.reportName);

            setViewState({
              ...viewState,
              isLoading: false,
              showReport: stateObj.arrayData.length > 0,
              reportInfoId: stateObj.arrayData[reportIdx].reportInfoId,
            });
          } else {
            setViewState({
              ...viewState,
              isLoading: false,
              showReport: false,
              reportInfoId: -1
            });
          }
        }).catch((err) => {
          setViewState({
            ...viewState,
            isLoading: false,
            showReport: false,
            reportInfoId: -1
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewState.routeName, viewState.reportViewerType]);

  return {
    personSwimTimeState,
    environmentVariableState,
    formState,
    errorState,
    gridState,
    filterState,
    organizationState,
    reportPeriodSeasonState,
    isStateLoading: personSwimTimeState.isArrayLoading,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    onEditTimeClicked,
    USAS_ORGANIZATION_ID,
    onViewReport,
    viewState,
    setViewState,
    INITIAL_VIEW_STATE
  };
}

export default useMemberTimes;