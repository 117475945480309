import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import useNTContactsData from "../../../state/nationalTeamContacts/UseNTContactsData";

import useNavRoutes from "../../../../common/state/security/UseNavRoutes";

import { createPersonNTContactFromMultiFormState } from "../../../components/forms/nationalTeamContactSP/NTContactSPFormUtils";

const SELF_CONTACT_NAME = 'Self'; //TODO - Move to Constants?

const INITIAL_STATE = {
  contactObj: undefined,
  isSelfContact: false,
  hasOtherSelfContact: false
};

const useNationalTeamContactDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { nationalTeamContactsState, putNTContacts } = useNTContactsData();
  const [state, setState] = useState(INITIAL_STATE);

  const onCancelClicked = (event) => {
    try {
      event?.preventDefault();
      navigate(navRoutes.MEMBER_NT_CONTACTS?.route);
    } catch (e) {
      console.log(e); //TODO: Add error handling
    }
  };

  const onSubmitContactForm = async (multiFormState) => {
    try {
      const personId = nationalTeamContactsState.objData.personId;
      const newPersonNTContactsObj = JSON.parse(JSON.stringify(nationalTeamContactsState.objData));

      if (state.contactObj) {
        const targetIndex = newPersonNTContactsObj.personNationalTeamContact.findIndex((x) => x.personNationalTeamContactId === state.contactObj.personNationalTeamContactId);

        newPersonNTContactsObj.personNationalTeamContact[targetIndex] = createPersonNTContactFromMultiFormState(multiFormState, personId, 'personNationalTeam', newPersonNTContactsObj.personNationalTeamContact[targetIndex]);
      } else {
        newPersonNTContactsObj.personNationalTeamContact.push(createPersonNTContactFromMultiFormState(multiFormState, personId, 'personNationalTeam'));
      }

      const putNTContactsResult = await putNTContacts(personId, newPersonNTContactsObj);

      if (putNTContactsResult) {
        navigate(navRoutes.MEMBER_NT_CONTACTS?.route);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    try {
      if (location.state?.contactObj) {
        setState({
          ...state,
          contactObj: location.state.contactObj,
          isSelfContact: location.state.contactObj.contactRelationshipType?.typeName === SELF_CONTACT_NAME,
          hasOtherSelfContact: nationalTeamContactsState.objData.personNationalTeamContact.filter((x) => x.contactRelationshipType?.typeName === SELF_CONTACT_NAME && x.personNationalTeamContactId !== location.state.contactObj.personNationalTeamContactId).length > 0
        });
      } else {
        setState({
          ...state,
          contactObj: null,
          isSelfContact: false,
          hasOtherSelfContact: nationalTeamContactsState.objData.personNationalTeamContact.filter((x) => x.contactRelationshipType?.typeName === SELF_CONTACT_NAME).length > 0
        });
      }
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // function createPersonNationalTeamContact(formState, personId, existingPersonNationalTeamContact = undefined) {
  //   if (existingPersonNationalTeamContact) {
  //     let phoneNumber = formState.phoneNumber.trim() !== '' && formState.phoneNumber.trim() !== DEFAULT_PHONE_NUMBER_AREA_CODE && formState.phoneNumber.trim() !== DEFAULT_PHONE_NUMBER_AREA_CODE_MEXICO
  //       ? formState.phoneNumber
  //       : null;

  //     return {
  //       ...existingPersonNationalTeamContact,
  //       contactRelationshipType: undefined,
  //       personId,
  //       firstName: formState.firstName.trim(),
  //       lastName: formState.lastName.trim(),
  //       emailAddress: formState.email.trim(),
  //       contactRelationshipTypeId: formState.contactRelationshipTypeId,
  //       personNationalTeamContactPhone: phoneNumber
  //         ? [{
  //           personNationalTeamContactId: existingPersonNationalTeamContact.personNationalTeamContactId,
  //           phoneTypeId: 1 /*TODO Magic number*/,
  //           phoneNumber
  //         }] : []
  //     };
  //   } else {
  //     let phoneNumber = formState.phoneNumber.trim() !== '' && formState.phoneNumber.trim() !== DEFAULT_PHONE_NUMBER_AREA_CODE && formState.phoneNumber.trim() !== DEFAULT_PHONE_NUMBER_AREA_CODE_MEXICO
  //       ? formState.phoneNumber
  //       : null;

  //     return {
  //       personId,
  //       firstName: formState.firstName.trim(),
  //       lastName: formState.lastName.trim(),
  //       emailAddress: formState.email.trim(),
  //       contactRelationshipTypeId: formState.contactRelationshipTypeId,
  //       personNationalTeamContactPhone: phoneNumber ? [{ phoneTypeId: 1 /*TODO Magic number*/, phoneNumber }] : []
  //     };
  //   }
  // }

  return {
    isLoading: nationalTeamContactsState.isObjLoading,
    hasOtherSelfContact: state.hasOtherSelfContact,
    isSelfContact: state.isSelfContact,
    contactObj: state.contactObj,
    onCancelClicked,
    onSubmitContactForm
  };
};

export default useNationalTeamContactDetail;