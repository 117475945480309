import { Fragment } from 'react';

import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';
import style from '../Member.module.css';

const GridRowIcons = ({ group, onApply, INVITE_STATUS }) => (
  <Fragment>
    {group.personOrgGroupId > 0
      ? group.status
        ? group.status === INVITE_STATUS || group.status === 'Revisit'
          ? (
            <button className={global.IconButton} type='button' onClick={() => onApply(group)}>
              <span className={style.SmallGridButtonText}>Apply {`(${group.status})`}</span>
            </button>
          ) : (
            <span className={global.IconButton}>{group.status}</span>
          ) : (
          <span className={global.IconButton}>Approved</span>
        ) : (
        <button className={global.IconButton} type='button' onClick={() => onApply(group)}>
          <span className={style.SmallGridButtonText}>Apply</span>
        </button>
      )
    }
  </Fragment >
);

const GridRow = ({ group, onApply }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span className='hidden-xs'>{group.groupName || ''}</span>&nbsp;
      <GridRowIcons group={group} onApply={onApply} />
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Group Name
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {group.groupName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Registration Start Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {group.registrationOpenDate ? formatDate(group.registrationOpenDate) : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Registration End Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {group.registrationCloseDate ? formatDate(group.registrationCloseDate) : <span>&nbsp;</span>}
        </div>
      </div>
    </div>
  </div>
);

const ProfileGroupsGridSmall = ({ data, isLoading, onApply, INVITE_STATUS }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={[global.SmallTableRowInfo, global.Bold].join(' ')}>
            &nbsp;&nbsp;Loading...
          </div>
        </div>
      ) : Array.isArray(data) && data.length > 0
        ? data.map((group, i) => <GridRow key={i} group={group} onApply={onApply} INVITE_STATUS={INVITE_STATUS} />
        ) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={[global.SmallTableRowInfo, global.Bold].join(' ')}>
              &nbsp;&nbsp;No Groups
            </div>
          </div>
        )
    }
  </div>
);

export default ProfileGroupsGridSmall;