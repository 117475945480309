import { Fragment } from 'react';

import useNationalTeamSponsorsDetail from './UseNationalTeamSponsorsDetail';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import DatePicker from '../../../../common/components/datepickers/DatePicker';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Dropdown from '../../../../common/components/dropdowns/Dropdown';
import Headings from '../../../../common/components/headings/Headings';

const NationalTeamSponsorsDetail = () => {
  const {
    Constants,
    personNationalTeamState,
    formState,
    errorState,
    sponsorState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onSaveClicked,
    onCancelClicked
  } = useNationalTeamSponsorsDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{formState.personSponsorId > 0 ? 'Edit' : 'Add'} Sponsor</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label="Sponsor*"
              name="sponsorId"
              value={formState.sponsorId}
              error={errorState.sponsorId}
              message={errorState.sponsorId}
              isLoading={sponsorState.isLoading}
              options={sponsorState.dataAsOptionsWithNames}
              onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'sponsorId', newValueLabel, 'SponsorName') }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label="Effective Date*"
              name="effectiveDate"
              value={formState.effectiveDate}
              error={errorState.effectiveDate}
              message={errorState.effectiveDate}
              countOfYears={150}
              startYearOffset={-149}
              onChange={(value) => { onFormValueChanged('effectiveDate', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label="Expiration Date*"
              name="expirationDate"
              value={formState.expirationDate}
              error={errorState.expirationDate}
              message={errorState.expirationDate}
              countOfYears={150}
              startYearOffset={-140}
              onChange={(value) => { onFormValueChanged('expirationDate', value); }} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={(e) => onSaveClicked(e)}>Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={(e) => onCancelClicked(e)}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={personNationalTeamState.isObjLoading || sponsorState.isLoading} />
    </Fragment>
  );
}

export default NationalTeamSponsorsDetail;