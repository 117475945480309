import { Fragment } from 'react';

import useMemberPassportUpload from './UseMemberPassportUpload';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ActionButton from '../../../../common/components/buttons/ActionButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import { LOADING_MSG, SAVING_MSG } from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const MemberPassportUpload = () => {
  const {
    isSaving,
    isLoading,
    state,
    fileTypeState,
    onUploadPassport,
    onDownloadUploadedFile,
    onViewSamplePassport,
    onRemoveFileClicked,
    onRemovePopupConfirmClicked,
    onPopupCancelClicked
  } = useMemberPassportUpload();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Member Passport Upload</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin" style={{ marginRight: 30, marginLeft: 5 }}>
        <div className="col-xs-12" style={{ backgroundColor: 'lightblue', marginLeft: 10, marginBottom: 10, paddingBottom: 5, borderRadius: 10 }}>
          <ul style={{ padding: 20, color: 'darkslateblue' }}>
            <li>If a copy of your passport is already in the SWIMS database, please download it and review before uploading a new one.</li>
            <li>Please make sure that the image or document that you upload displays the upper and lower sections of your passport.</li>
            <li>You can view the sample passport for an example of what you should upload.</li>
          </ul>
        </div>
      </div>
      <form>
        <div className="row usas-extra-top-margin">
          {state.passportAttachmentObj?.attachmentUrl
            ? (
              <Fragment>
                <div className="col-xs-12 usas-extra-bottom-margin usas-extra-top-margin">
                  <SecondaryButton onClick={onRemoveFileClicked}>Remove Uploaded File</SecondaryButton>&nbsp;
                  <PrimaryButton type="button" onClick={onDownloadUploadedFile}>Download Uploaded File</PrimaryButton>
                </div>
                <div className="col-xs-12 usas-extra-bottom-margin">
                  <p><b>Uploaded File Name: </b> {state.passportFileName}</p>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div className="col-xs-12 usas-extra-bottom-margin usas-extra-top-margin">
                  <label htmlFor={`passport-file-upload`} className={global.FileUpload}>Upload Passport File</label>
                  <input hidden id={`passport-file-upload`} type="file" onChange={onUploadPassport} />
                  &nbsp;{fileTypeState.validFileTypeNames}
                </div>
              </Fragment>
            )
          }
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <ActionButton type="button" onClick={onViewSamplePassport}>{state.displaySamplePassport ? 'Hide' : 'View'} Sample Passport</ActionButton>
          </div>
        </div>
        <div className="row" style={{ display: state.displaySamplePassport ? 'inline' : 'none' }} >
          <div className="col-xs-12">
            <img src={`${import.meta.env.VITE_CDN_URL}/SamplePassport.png`} alt="Passport" />&nbsp;
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Remove Uploaded File?'}
        displayPopUp={state.displayRemovePopup}
        onModalCanceled={onPopupCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>File Type:</b> {state.passportAttachmentObj?.personNationalAttachmentFileTypeName}</p>
            <p><b>Uploaded File Name:</b> {state.passportAttachmentObj?.attachmentName}</p>
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <PrimaryButton type="button" onClick={onRemovePopupConfirmClicked}>Yes, Remove</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onPopupCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Validation Error'}
        displayPopUp={state.displayValidationPopup}
        onModalCanceled={onPopupCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>{state.validationMessage}</p>
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <SecondaryButton type="button" onClick={onPopupCancelClicked}>Close</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
}

export default MemberPassportUpload;