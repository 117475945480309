export const localValidate = (formState) => {
  let errors = {};

  // File Type
  if (formState.fileTypeId < 0) {
    errors.fileTypeId = 'File Type is required';
  }

  // File Name
  if (!formState.attachmentName.trim()) {
    errors.attachmentName = 'File Name is required';
  } else if (formState.attachmentName.trim().length > 200) {
    errors.attachmentName = 'File Name cannot exceed 200 characters';
  }


  // Attachment Description
  if (formState.attachmentDescription.trim()) {
    if (formState.attachmentDescription.trim().length > 7000) {
      errors.attachmentDescription = 'Description cannot exceed 7000 characters';
    }
  }

  // Attachment URL
  if (!formState.attachmentUrl && !formState.attachmentFile?.size) {
    errors.attachmentFile = 'Attachment File is required';
  }

  return errors;
};

const NTAttachmentFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default NTAttachmentFormValidation;