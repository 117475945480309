import { API_NAMES, initApi } from '../../../common/utils/HttpApiHelper';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getPersonNationalTeamAttachmentFileTypes from './GetPersonNationalTeamAttachmentFileTypesData';

const getNTAttachmentFileTemplateData = (typeName, state, setState) => {
  if (typeName) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const typeNameForUrl = encodeURIComponent(typeName);
    const url = `/PersonFiles/downloadNationalTeamTemplate/${typeNameForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'GET', null, false, true) : null;
  }
};

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  arrayData: [],
  dataAsOptionsWithNames: []
};

const INITIAL_NATIONAL_TEAM_ATTACHMENT_TEMPLATE_STATE = {
  ...BASIC_INITIAL_STATE
};

const PersonNationalTeamFileTypeData = {
  INITIAL_STATE,
  INITIAL_NATIONAL_TEAM_ATTACHMENT_TEMPLATE_STATE,
  getPersonNationalTeamAttachmentFileTypes,
  getNTAttachmentFileTemplateData
};

export default PersonNationalTeamFileTypeData;
