import { useEffect } from 'react';

import validate from './PersonBioInfoFormValidation';

import useStatesCombobox from '../../../../common/components/comboboxes/statesCombobox/UseStatesCombobox';
import useCountryCombobox from '../../../../common/components/comboboxes/countryCombobox/UseCountryCombobox';

import { createNewPersonBiosFormState } from './PersonBioInfoFormUtils';
import { COUNTRY_CODE_CANADA, COUNTRY_CODE_MEXICO, COUNTRY_CODE_UNITED_STATES } from '../../../../common/utils/Constants';
import useForm from "../../../../common/utils/UseForm";

const INITIAL_FORM_STATE = {
  height: '',
  weight: '',
  collegeGraduationYear: '',
  highSchoolGraduationYear: '',
  college: '',
  highSchool: '',
  highSchoolCity: '',
  hometown: '',
  residence: '',
  birthplace: '',
  highSchoolState: '',
  highSchoolStateName: '',
  hometownState: '',
  hometownStateName: '',
  residenceState: '',
  residenceStateName: '',
  birthplaceState: '',
  birthplaceStateName: '',
  birthplaceCountry: '',
  birthplaceCountryName: '',
  isBirthCountryUSA: false,
  isBirthCountryMexico: false,
  isBirthCountryCanada: false
};

const usePersonBioInfoForm = (personBiosArray, onSubmitFormCallback, onIsDirtyChanged, onErrorCaught, customValidate) => {
  const { getStateIdByName } = useStatesCombobox();
  const { getCountryIdByName } = useCountryCombobox();
  const { formState, errorState, isDirty, handleSubmit, updateFormState, setFormData, setFormState, resetForm,
    onValueTextPairChanged, setErrors
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, customValidate || validate);

  const onBirthplaceCountryChanged = (birthplaceCountry, birthplaceCountryName, event) => {
    try {
      event?.preventDefault();

      let newFormState = {
        ...formState,
        birthplaceCountry,
        birthplaceCountryName,
        isBirthCountryUSA: false,
        isBirthCountryMexico: false,
        isBirthCountryCanada: false,
        birthplaceState: '',
        birthplaceStateName: ''
      };

      if (birthplaceCountry === COUNTRY_CODE_UNITED_STATES) {
        newFormState = {
          ...newFormState,
          isBirthCountryUSA: true,
        };
      } else if (birthplaceCountry === COUNTRY_CODE_MEXICO) {
        newFormState = {
          ...newFormState,
          isBirthCountryMexico: true,
        };
      } else if (birthplaceCountry === COUNTRY_CODE_CANADA) {
        newFormState = {
          ...newFormState,
          isBirthCountryCanada: true,
        };
      }

      setFormState(newFormState);
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : setErrors({ formError: e?.message });
    }
  };

  useEffect(() => {
    try {
      if (personBiosArray) {
        let newFormState = createNewPersonBiosFormState(personBiosArray, getStateIdByName, getCountryIdByName);

        setFormData({
          ...INITIAL_FORM_STATE,
          ...newFormState
        });
      }
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : setErrors({ formError: e?.message });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personBiosArray]);

  useEffect(() => {
    try {
      if (onIsDirtyChanged) {
        onIsDirtyChanged(isDirty);
      }
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : setErrors({ formError: e?.message });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  return {
    isBirthCountryUSA: formState.isBirthCountryUSA === true,
    isBirthCountryMexico: formState.isBirthCountryMexico === true,
    isBirthCountryCanada: formState.isBirthCountryCanada === true,
    isDirty,
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onValueTextPairChanged,
    onBirthplaceCountryChanged,
    resetForm
  };
};

export default usePersonBioInfoForm;