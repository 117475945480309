import { Fragment } from 'react';

import useNationalTeamSupportStaffTypeDetail from './UseNationalTeamSupportStaffTypeDetail';

import NTSupportStaffEditRoleForm from '../../../components/forms/nationalTeamSupportStaff/NTSupportStaffEditRoleForm';
import NTSupportStaffAddRoleForm from '../../../components/forms/nationalTeamSupportStaff/NTSupportStaffAddRoleForm';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

import { formatDate } from '../../../../common/utils/DateFunctions';
import { LOADING_MSG } from '../../../../common/utils/Constants';

const NationalTeamSupportStaffTypeDetail = () => {
  const {
    isLoading,
    state,
    supportStaffObj,
    supportStaffRoleObj,
    onCancelClicked,
    onDupPopupCloseClicked,
    onSubmitSupportStaffRoleAddForm,
    onSubmitSupportStaffRoleEditForm
  } = useNationalTeamSupportStaffTypeDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{supportStaffRoleObj ? 'Edit Support Staff Role' : 'Add Support Staff Role'}</Headings.H3>
        </div>
      </div>
      {supportStaffObj &&
        <Fragment>
          {supportStaffRoleObj
            ? <NTSupportStaffEditRoleForm supportStaffRoleObj={supportStaffRoleObj} onSubmitFormCallback={onSubmitSupportStaffRoleEditForm} onSecondaryButtonClicked={onCancelClicked} />
            : <NTSupportStaffAddRoleForm supportStaffObj={supportStaffObj} onSubmitFormCallback={onSubmitSupportStaffRoleAddForm} onSecondaryButtonClicked={onCancelClicked} />
          }
        </Fragment>
      }
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Duplicate Role Error'}
        displayPopUp={state.displayDupRoleModal === true}
        onModalCanceled={onDupPopupCloseClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>Selected dates conflict with an existing role ({state.dupRoleObj?.supportStaffType?.typeName}, {formatDate(state.dupRoleObj?.effectiveDate)} - {formatDate(state.dupRoleObj?.expirationDate)})</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <SecondaryButton type="button" onClick={onDupPopupCloseClicked}>Close</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
}

export default NationalTeamSupportStaffTypeDetail;