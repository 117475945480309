import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import usePersonNationalTeamData from '../../../state/personNationalTeam/UsePersonNationalTeamData';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';

import { DEFAULT_MAX_DATE } from '../../../../common/utils/Constants';

const INITIAL_STATE = {
  supportStaffObj: null,
  supportStaffRoleObj: null,
  displayDupRoleModal: false,
  dupRoleObj: {}
};

const useNationalTeamSupportStaffTypeDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { personNationalTeamState, putPersonNationalTeam } = usePersonNationalTeamData();
  const [state, setState] = useState(INITIAL_STATE);

  const onCancelClicked = (event) => {
    event?.preventDefault();

    navigate(navRoutes.MEMBER_NT_SUPPORT_STAFF?.route);
  };

  const onDupPopupCloseClicked = (event) => {
    event?.preventDefault();

    setState({
      ...state,
      displayDupRoleModal: false,
      dupRoleObj: {}
    });
  };

  const onSubmitSupportStaffRoleAddForm = async (formState) => {
    const dupRoleObj = findDuplicateRole(formState, state.supportStaffObj.nationalTeamSupportStaffType);

    if (dupRoleObj) {
      setState({
        ...state,
        displayDupRoleModal: true,
        dupRoleObj
      });
    } else {
      const personId = personNationalTeamState.objData.personId;
      const newNTPersonObj = JSON.parse(JSON.stringify(personNationalTeamState.objData));
      const targetStaffIndex = newNTPersonObj.supportStaffMembers.findIndex((x) => x.nationalTeamSupportStaffId === state.supportStaffObj.nationalTeamSupportStaffId);
      newNTPersonObj.supportStaffMembers[targetStaffIndex].nationalTeamSupportStaffType.push({
        nationalTeamSupportStaffId: state.supportStaffObj.nationalTeamSupportStaffId,
        effectiveDate: formState.effectiveDate,
        expirationDate: formState.hasNoExpirationDate === true ? DEFAULT_MAX_DATE : formState.expirationDate,
        supportStaffTypeId: formState.supportStaffTypeId,
      });

      const putPersonNationalTeamResult = await putPersonNationalTeam(personId, newNTPersonObj);

      if (putPersonNationalTeamResult) {
        navigate(navRoutes.MEMBER_NT_SUPPORT_STAFF?.route);
      }
    }
  };

  const onSubmitSupportStaffRoleEditForm = async (formState) => {
    const dupRoleObj = findDuplicateRole({ ...formState, supportStaffTypeId: state.supportStaffRoleObj.supportStaffTypeId }, state.supportStaffObj.nationalTeamSupportStaffType, state.supportStaffRoleObj.nationalTeamSupportStaffTypeId);

    if (dupRoleObj) {
      setState({
        ...state,
        displayDupRoleModal: true,
        dupRoleObj
      });
    } else {
      const personId = personNationalTeamState.objData.personId;
      const newNTPersonObj = JSON.parse(JSON.stringify(personNationalTeamState.objData));
      const targetStaffIndex = newNTPersonObj.supportStaffMembers.findIndex((x) => x.nationalTeamSupportStaffId === state.supportStaffObj.nationalTeamSupportStaffId);
      const targetRoleIndex = newNTPersonObj.supportStaffMembers[targetStaffIndex].nationalTeamSupportStaffType.findIndex((x) => x.nationalTeamSupportStaffTypeId === state.supportStaffRoleObj.nationalTeamSupportStaffTypeId)

      newNTPersonObj.supportStaffMembers[targetStaffIndex].nationalTeamSupportStaffType[targetRoleIndex] = {
        ...newNTPersonObj.supportStaffMembers[targetStaffIndex].nationalTeamSupportStaffType[targetRoleIndex],
        effectiveDate: formState.effectiveDate,
        expirationDate: formState.hasNoExpirationDate === true ? DEFAULT_MAX_DATE : formState.expirationDate
      };

      const putPersonNationalTeamResult = await putPersonNationalTeam(personId, newNTPersonObj);

      if (putPersonNationalTeamResult) {
        navigate(navRoutes.MEMBER_NT_SUPPORT_STAFF?.route);
      }
    }
  };

  useEffect(() => {
    if (location.state?.supportStaffObj) {
      if (location.state?.supportStaffRoleObj) {
        setState({ ...state, supportStaffObj: location.state.supportStaffObj, supportStaffRoleObj: location.state.supportStaffRoleObj });
      } else {
        setState({ ...state, supportStaffObj: location.state.supportStaffObj });
      }
    } else {
      navigate(navRoutes.MEMBER_NT_SUPPORT_STAFF?.route);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function findDuplicateRole(formState, nationalTeamSupportStaffType, nationalTeamSupportStaffTypeId = undefined) {
    const existingRoles = nationalTeamSupportStaffType.filter((x) => x.nationalTeamSupportStaffTypeId !== nationalTeamSupportStaffTypeId);
    const effectiveDate = new Date(formState.effectiveDate);
    const expirationDate = formState.hasNoExpirationDate === true ? new Date(DEFAULT_MAX_DATE) : new Date(formState.expirationDate);

    for (const role of existingRoles) {
      if (formState.supportStaffTypeId === role.supportStaffTypeId) {
        const existingEffectiveDate = new Date(role.effectiveDate);
        const existingExpirationDate = new Date(role.expirationDate);

        if (effectiveDate <= existingExpirationDate && expirationDate >= existingEffectiveDate) {
          return role;
        }
      }
    }

    return null;
  }

  return {
    isLoading: personNationalTeamState.isObjLoading,
    state,
    supportStaffObj: state.supportStaffObj || null,
    supportStaffRoleObj: state.supportStaffRoleObj || null,
    onCancelClicked,
    onDupPopupCloseClicked,
    onSubmitSupportStaffRoleAddForm,
    onSubmitSupportStaffRoleEditForm
  };
};

export default useNationalTeamSupportStaffTypeDetail;