import { useEffect } from "react";

import validate from './OfferingInstanceOrgUnitEditFormValidation';

import UseForm from "../../../../../../common/utils/UseForm";
import { BLANK_DATE_STRING } from "../../../../../../common/utils/Constants";
import { formatDate } from "../../../../../../common/utils/DateFunctions";

const INITIAL_FORM_STATE = {
  isClubOffering: false,
  registrationStartDate: BLANK_DATE_STRING,
  registrationEndDate: BLANK_DATE_STRING,
  effectiveDate: BLANK_DATE_STRING,
  expirationDate: BLANK_DATE_STRING,
  feeAmount: 0,
  renewalFeeAmount: null,
  maxLscFeeAmount: null,
  reportPeriodStartDate: null,
  reportPeriodEndDate: null,
  offeringInstanceOrgUnitRegDates: [],
};

const useOfferingInstanceOrgUnitEditForm = (onSubmitFormCallback, reportPeriodObj, orgUnitObj, offeringInstanceObj,
  offeringInstanceOrgUnitId, isFeeReadOnly) => {
  const { formState, errorState, setFormState, updateFormState, handleSubmit
  } = UseForm(INITIAL_FORM_STATE, formatBeforeSubmit, validate);

  useEffect(() => {
    if (Array.isArray(offeringInstanceObj?.offeringInstanceOrgUnit)) {
      const targetPeriod = offeringInstanceObj.offeringInstanceOrgUnit.find((x) => x.offeringInstanceOrgUnitId === offeringInstanceOrgUnitId);
      const otherPeriods = offeringInstanceObj.offeringInstanceOrgUnit.filter((x) => x.offeringInstanceOrgUnitId !== offeringInstanceOrgUnitId);

      setFormState({
        ...formState,
        isClubOffering: offeringInstanceObj.isClubRegistrationOffering === true,
        registrationStartDate: formatDate(targetPeriod.registrationStartDate),
        registrationEndDate: formatDate(targetPeriod.registrationEndDate),
        effectiveDate: formatDate(targetPeriod.effectiveDate),
        expirationDate: formatDate(targetPeriod.expirationDate),
        reportPeriodStartDate:formatDate(reportPeriodObj.startDate),
        reportPeriodEndDate: formatDate(reportPeriodObj.endDate),
        feeAmount: offeringInstanceObj.feeAmount,
        renewalFeeAmount: offeringInstanceObj.isClubRegistrationOffering === true ? offeringInstanceObj.renewalFeeAmount : null,
        maxLscFeeAmount: offeringInstanceObj.maxLscFeeAmount,
        offeringInstanceOrgUnitRegDates: otherPeriods.map((x) => {
          return { startDate: formatDate(x.registrationStartDate), endDate: formatDate(x.registrationEndDate) }
        })
      });
    } else if (offeringInstanceObj) {
      setFormState({
        ...formState,
        isClubOffering: offeringInstanceObj.isClubRegistrationOffering === true,
        registrationStartDate: formatDate(offeringInstanceObj.registrationStartDate),
        registrationEndDate: formatDate(offeringInstanceObj.registrationEndDate),
        effectiveDate: formatDate(offeringInstanceObj.effectiveDate),
        expirationDate: formatDate(offeringInstanceObj.expirationDate),
        reportPeriodStartDate:formatDate(reportPeriodObj.startDate),
        reportPeriodEndDate: formatDate(reportPeriodObj.endDate),
        feeAmount: offeringInstanceObj.feeAmount,
        renewalFeeAmount: offeringInstanceObj.isClubRegistrationOffering === true ? offeringInstanceObj.renewalFeeAmount : null,
        maxLscFeeAmount: offeringInstanceObj.maxLscFeeAmount,
        offeringInstanceOrgUnitRegDates: [],
        reportPeriodObj
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offeringInstanceObj]);

  function formatBeforeSubmit(formState) {
    const submitObj = {
      offeringInstanceOrgUnitId,
      offeringId: offeringInstanceObj.offeringId,
      offeringInstanceId: offeringInstanceObj.offeringInstanceId,
      reportPeriodId: reportPeriodObj.reportPeriodId,
      orgUnitId: orgUnitObj.orgUnitId,

      offeringInstanceFeeId: isFeeReadOnly === false ? offeringInstanceObj.offeringInstanceFeeId : 0,
      feeAmount: isFeeReadOnly === false ? formState.feeAmount : offeringInstanceObj.feeAmount,
      renewalFeeAmount: isFeeReadOnly === false && offeringInstanceObj.isClubRegistrationOffering === true ? formState.renewalFeeAmount : offeringInstanceObj.renewalFeeAmount,

      registrationStartDate: formState.registrationStartDate,
      registrationEndDate: formState.registrationEndDate,
      effectiveDate: formState.effectiveDate,
      expirationDate: formState.expirationDate
    };

    onSubmitFormCallback(submitObj);
  }

  return {
    formState,
    errorState,

    updateFormState,
    handleSubmit
  };
};

export default useOfferingInstanceOrgUnitEditForm;