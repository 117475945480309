import { useEffect, useState } from "react";

import useFunFactsTypeData from "../../../state/funFactsType/UseFunFactsTypeData";

import { compareAlphabetical } from "../../../../common/utils/SortingUtils";

const useFunFactsFormGrid = (gridData, personId, onSubmitFormCallback, onIsDirtyChanged, onErrorCaught) => {
  const { funFactsTypeState } = useFunFactsTypeData();
  const [initialFormGridState, setInitialFormGridState] = useState([]);
  const [formGridState, setFormGridState] = useState([]);
  const [isDirtyState, setIsDirtyState] = useState(false);

  const onSubmitFormGrid = (event) => {
    try {
      event?.preventDefault();

      const funFactsDetails = [];

      for (const funFactsFormObj of formGridState) {
        if (funFactsFormObj.hasValue === true) {
          const existingFunFactsValue = gridData.find((x) => x.funFactsId === funFactsFormObj.id);

          if (existingFunFactsValue) {
            funFactsDetails.push({
              ...existingFunFactsValue,
              textValue: funFactsFormObj.value.trim()
            });
          } else {
            funFactsDetails.push({
              personId,
              funFactsId: funFactsFormObj.id,
              textValue: funFactsFormObj.value.trim(),
              funFacts: undefined
            });
          }
        }
      }

      onSubmitFormCallback(funFactsDetails);
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : () => { throw e };
    }
  };

  const onCancelClicked = (event) => {
    try {
      event?.preventDefault();

      const newFormGridState = JSON.parse(JSON.stringify(initialFormGridState));

      setFormGridState(newFormGridState);
      setIsDirtyState(false);
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : () => { throw e };
    }
  };

  const onUpdateFormGridState = (id, newValue) => {
    try {
      const newFormGridState = [...formGridState];

      for (let i = 0; i < newFormGridState.length; i++) {
        if (newFormGridState[i].id === id) {
          newFormGridState[i].value = newValue;
          newFormGridState[i].hasValue = newValue?.trim() ? true : false;
          newFormGridState[i].isDirty = newValue.trim() !== initialFormGridState[i].value.trim();

          break;
        }
      }

      setFormGridState(newFormGridState);
      setIsDirtyState(getIsDirty(newFormGridState));
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : () => { throw e };
    }
  };

  useEffect(() => {
    try {
      if (Array.isArray(gridData) && funFactsTypeState.isLoaded === true) {
        const newFormGridState = createFormGridState(gridData);
        const newInitialFormGridState = JSON.parse(JSON.stringify(newFormGridState));

        setFormGridState(newFormGridState);
        setInitialFormGridState(newInitialFormGridState);
        setIsDirtyState(false);
      }
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : () => { throw e };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridData, funFactsTypeState.isLoaded]);

  useEffect(() => {
    try {
      if (onIsDirtyChanged) {
        onIsDirtyChanged(isDirtyState);
      }
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : () => { throw e };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirtyState]);

  function createFormGridState(gridData) {
    const newFormGridState = [];

    for (const funFactsOption of funFactsTypeState.dataAsOptionsWithNames) {
      if (funFactsOption.id > 0) {
        const existingFunFactsValue = gridData.find((x) => x.funFactsId === funFactsOption.id);

        newFormGridState.push(createFunFactsFormObj(funFactsOption, existingFunFactsValue));
      }
    }

    newFormGridState.sort((a, b) => compareAlphabetical(a.name, b.name));

    return newFormGridState;
  }

  function createFunFactsFormObj(funFactsOption, existingFunFactsValue) {
    return {
      id: funFactsOption.id,
      name: funFactsOption.name,
      value: existingFunFactsValue?.textValue || '',
      hasValue: existingFunFactsValue?.textValue ? true : false,
      isDirty: false
    };
  }

  function getIsDirty(newFormGridState) {
    for (const funFacts of newFormGridState) {
      if (funFacts.isDirty === true) {
        return true;
      }
    }

    return false;
  }

  return {
    isLoading: funFactsTypeState.isLoading,
    isDirty: isDirtyState,
    formGridState,
    onUpdateFormGridState,
    onSubmitFormGrid,
    onCancelClicked
  };
};

export default useFunFactsFormGrid;