import { useEffect } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import useNTRegistrationData from "../../../state/nationalTeamRegistration/UseNTRegistrationData";
import useNTRegistrationLeftNavData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationLeftNav/UseNTRegistrationLeftNavData";
import useNTRegistrationProfileData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationProfile/UseNTRegistrationProfileData";

import useNavRoutes from "../../../../common/state/security/UseNavRoutes";

import { LEFT_NAV_ITEM_NAME_FUN_FACTS } from "../utils/NTRegistrationConstants";

const useNTRegistrationFunFacts = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { pageHasUnsavedChanges, updatePageHasUnsavedChanges, onNTRegistrationError, onNavigateWithoutSaving
  } = useNTRegistrationData();
  const { updateCurrentLeftNavData } = useNTRegistrationLeftNavData();
  const { nationalTeamRegistrationProfileState, getNTRegistrationProfile, putNTRegistrationProfile } = useNTRegistrationProfileData();

  const onContinueClicked = (event) => {
    try {
      event?.preventDefault();

      if (pageHasUnsavedChanges === true) {
        onNavigateWithoutSaving(navRoutes.NT_REGISTRATION_ATTACHMENTS?.route);
      } else {
        navigate(navRoutes.NT_REGISTRATION_ATTACHMENTS?.route);
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onBackClicked = (event) => {
    try {
      event?.preventDefault();

      if (pageHasUnsavedChanges === true) {
        onNavigateWithoutSaving(navRoutes.NT_REGISTRATION_SOCIAL_MEDIA?.route);
      } else {
        navigate(navRoutes.NT_REGISTRATION_SOCIAL_MEDIA?.route);
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onSubmitFunFactsForm = async (funFacts) => {
    try {
      const personId = nationalTeamRegistrationProfileState.objData.personId;
      const newPersonProfileObj = JSON.parse(JSON.stringify(nationalTeamRegistrationProfileState.objData));
      newPersonProfileObj.funFacts = funFacts;

      await putNTRegistrationProfile(personId, newPersonProfileObj);
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  useEffect(() => {
    async function loadNTRegistrationProfile() {
      try {
        updateCurrentLeftNavData(LEFT_NAV_ITEM_NAME_FUN_FACTS, true);

        if (nationalTeamRegistrationProfileState.isObjLoaded !== true) {
          await getNTRegistrationProfile(0);
        }
      } catch (e) {
        onNTRegistrationError(e);
      }
    }
    loadNTRegistrationProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nationalTeamRegistrationProfileState.isObjLoaded]);

  return {
    isLoading: nationalTeamRegistrationProfileState.isObjLoading,
    personId: nationalTeamRegistrationProfileState.objData?.personId,
    gridData: nationalTeamRegistrationProfileState.objData?.funFacts || [],
    onContinueClicked,
    onBackClicked,
    onSubmitFunFactsForm,
    updatePageHasUnsavedChanges,
    onNTRegistrationError
  };
};

export default useNTRegistrationFunFacts;