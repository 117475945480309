import { Fragment } from "react";

import useNTContactSPForm from "./UseNTContactSPForm";

import NTContactSPFormInfo from "./NTContactSPFormInfo";
import NTContactSPFormAddresses from "./NTContactSPFormAddresses";
import NTContactSPSuggestionsFormGrid from "./NTContactSPSuggestionsFormGrid";

import Spinner from "../../../../common/components/spinners/Spinner";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";

import { SUBMITTING_MSG } from "../../../../common/utils/Constants";

import global from '../../../../common/components/GlobalStyle.module.css';

const NTContactSPForm = ({ contactObj = undefined, personNTFieldsKey = 'personNationalTeam', submitButtonText = 'Save',
  secondaryButtonText = 'Cancel', isRelationshipTypeReadonly = false, excludeSelfRelationshipType = false,
  onSubmitFormCallback, onSecondaryButtonClicked, onIsDirtyChanged = undefined, onErrorCaught = undefined }) => {
  const {
    isSubmitting,
    isLoading,
    isDirty,
    submitErrors,
    addressesSuggestions,
    multiFormState,
    onSubmitMultiForm,
    onCloseSuggestionsForm,
    onAddContactAddressClicked,
    onDeleteContactAddressClicked,
    onRevertContactAddressClicked,
    onUpdateContactFormObj,
    onUpdateContactAddressFormObj
  } = useNTContactSPForm(contactObj, personNTFieldsKey, onSubmitFormCallback, onIsDirtyChanged, onErrorCaught);

  if (isLoading === true) {
    return <Spinner />;
  }

  return (
    <Fragment>
      {addressesSuggestions && addressesSuggestions.length > 0
        ? (
          <NTContactSPSuggestionsFormGrid
            multiFormState={multiFormState}
            addressSuggestions={addressesSuggestions}
            onSubmitFormCallback={onSubmitFormCallback}
            onBackClicked={onCloseSuggestionsForm} />
        ) : (
          <form onSubmit={onSubmitMultiForm} noValidate>
            <NTContactSPFormInfo
              formValues={multiFormState.contactInfo.values}
              formErrors={multiFormState.contactInfo.errors}
              onUpdateFormValue={onUpdateContactFormObj}
              isRelationshipTypeReadonly={isRelationshipTypeReadonly}
              excludeSelfRelationshipType={excludeSelfRelationshipType} />
            <NTContactSPFormAddresses
              contactAddresses={multiFormState.contactAddresses}
              onUpdateFormValue={onUpdateContactAddressFormObj}
              onAddContactAddress={onAddContactAddressClicked}
              onDeleteContactAddress={onDeleteContactAddressClicked}
              onRevertContactAddress={onRevertContactAddressClicked} />
            {submitErrors && submitErrors.length > 0 &&
              <div className="row">
                <div className="col-xs-12">
                  {submitErrors.map((error, i) => <p key={i} className={global.ErrorMessage}>{error}</p>)}
                </div>
              </div>
            }
            <div className="row usas-extra-top-margin usas-extra-bottom-margin">
              <div className="col-xs-12 usas-extra-top-margin">
                <PrimaryButton type="submit" isDisabled={isDirty === false}>{submitButtonText}</PrimaryButton>&nbsp;
                <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
              </div>
            </div>
          </form>
        )}
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={SUBMITTING_MSG}
        displayPopUp={isSubmitting} />
    </Fragment>
  );
};

export default NTContactSPForm;