import { useEffect } from 'react';

import validate from './SocialMediaAddFormValidation';

import useSocialMediaTypeData from '../../../state/socialMediaType/UseSocialMediaTypeData';

import { DEFAULT_ID } from '../../../../common/utils/Constants';
import useForm from "../../../../common/utils/UseForm";

const INITIAL_FORM_STATE = {
  socialMediaTypeId: DEFAULT_ID,
  socialMediaTypeName: '',
  socialHandle: ''
};

const useSocialMediaAddForm = (onSubmitFormCallback, onIsDirtyChanged, onErrorCaught) => {
  const { socialMediaTypeState } = useSocialMediaTypeData();
  const { formState, errorState, isDirty, isSubmitting, setFormData, setErrors, handleSubmit, updateFormState,
    onValueTextPairChanged, resetForm
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  useEffect(() => {
    try { // reset form to initial state after add
      if (isDirty === false && isSubmitting === false) {
        setFormData(INITIAL_FORM_STATE);
      }

      if (onIsDirtyChanged) {
        onIsDirtyChanged(isDirty);
      }
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : setErrors({ formError: e?.message });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, isSubmitting]);

  return {
    socialMediaOptions: socialMediaTypeState.dataAsOptionsWithNames || [],
    isDirty,
    formState,
    errorState,
    resetForm,
    handleSubmit,
    updateFormState,
    onValueTextPairChanged
  };
};

export default useSocialMediaAddForm;