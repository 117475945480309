import { Fragment } from 'react';

import useNationalTeamMissedDrugTestsDetail from './UseNationalTeamMissedDrugTestsDetail';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import DatePicker from '../../../../common/components/datepickers/DatePicker';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Dropdown from '../../../../common/components/dropdowns/Dropdown';
import Headings from '../../../../common/components/headings/Headings';
import Textarea from '../../../../common/components/inputs/Textarea';

import global from '../../../../common/components/GlobalStyle.module.css';

const NationalTeamMissedDrugTestsDetail = () => {
  const {
    Constants,
    personNationalTeamState,
    formState,
    errorState,
    testingBodyState,
    drugTestStatusState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onSaveClicked,
    onCancelClicked
  } = useNationalTeamMissedDrugTestsDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{formState.personMissingDrugTestId > 0 ? 'Edit' : 'Add'} Missing Drug Test</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label="Date of Missed Drug Test*"
              name="missingDrugTestDate"
              value={formState.missingDrugTestDate}
              error={errorState.missingDrugTestDate}
              message={errorState.missingDrugTestDate}
              countOfYears={150}
              startYearOffset={-149}
              onChange={(value) => { onFormValueChanged('missingDrugTestDate', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label="Testing Body*"
              name="testingBodyId"
              value={formState.testingBodyId}
              error={errorState.testingBodyId}
              message={errorState.testingBodyId}
              isLoading={testingBodyState.isLoading}
              options={testingBodyState.dataAsOptionsWithNames}
              onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'testingBodyId', newValueLabel, 'bodyName') }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label="Status*"
              name="drugTestStatusId"
              value={formState.drugTestStatusId}
              error={errorState.drugTestStatusId}
              message={errorState.drugTestStatusId}
              isLoading={drugTestStatusState.isLoading}
              options={drugTestStatusState.dataAsOptionsWithNames}
              onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'drugTestStatusId', newValueLabel, 'statusName') }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label="Notification from Testing Body Date"
              name="notificationFromTestBodyDate"
              value={formState.notificationFromTestBodyDate}
              error={errorState.notificationFromTestBodyDate}
              message={errorState.notificationFromTestBodyDate}
              countOfYears={150}
              startYearOffset={-149}
              onChange={(value) => { onFormValueChanged('notificationFromTestBodyDate', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label="Athlete Notified Date"
              name="athleteNotifiedDate"
              value={formState.athleteNotifiedDate}
              error={errorState.athleteNotifiedDate}
              message={errorState.athleteNotifiedDate}
              countOfYears={150}
              startYearOffset={-149}
              onChange={(value) => { onFormValueChanged('athleteNotifiedDate', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label="Test Appealed Date"
              name="testAppealedDate"
              value={formState.testAppealedDate}
              error={errorState.testAppealedDate}
              message={errorState.testAppealedDate}
              countOfYears={150}
              startYearOffset={-149}
              onChange={(value) => { onFormValueChanged('testAppealedDate', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label="Test Body Appeal Response Date"
              name="testBodyAppealResponseDate"
              value={formState.testBodyAppealResponseDate}
              error={errorState.testBodyAppealResponseDate}
              message={errorState.testBodyAppealResponseDate}
              countOfYears={150}
              startYearOffset={-149}
              onChange={(value) => { onFormValueChanged('testBodyAppealResponseDate', value); }} />
          </div>
          <div className="col-xs-12">
            <Textarea
              className={global.Textarea}
              label="Comments"
              name="comments"
              value={formState.comments}
              error={errorState.comments}
              message={errorState.comments}
              onChange={(value) => { onFormValueChanged('comments', value) }} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={(e) => onSaveClicked(e)}>Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={(e) => onCancelClicked(e)}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={personNationalTeamState.isObjLoading || testingBodyState.isLoading || drugTestStatusState.isLoading} />
    </Fragment>
  );
};

export default NationalTeamMissedDrugTestsDetail;