import { BLANK_DATE_STRING } from "../../../../common/utils/Constants";
import { isValidDate } from "../../../../common/utils/validation";

export const localValidate = (formState) => {
  let errors = {};
  const effectiveDate = new Date(formState.effectiveDate);
  const expirationDate = new Date(formState.expirationDate);

  // Effective Date
  if (formState.effectiveDate === BLANK_DATE_STRING) {
    errors.effectiveDate = 'Effective Date is required';
  } else if (!isValidDate(formState.effectiveDate)) {
    errors.effectiveDate = 'Effective Date must be a valid date';
  } else if (formState.effectiveDate === formState.expirationDate) {
    errors.effectiveDate = 'Effective Date and Expiration Date cannot be the same date';
  } else if (expirationDate < effectiveDate) {
    errors.effectiveDate = 'Effective Date must be before the Expiration Date';
  }

  // Expiration Date
  if (formState.expirationDate === BLANK_DATE_STRING) {
    errors.expirationDate = 'Expiration Date is required';
  } else if (!isValidDate(formState.expirationDate)) {
    errors.expirationDate = 'Expiration Date must be a valid date';
  } else if (expirationDate < effectiveDate) {
    errors.expirationDate = 'Expiration Date must be after the Effective Date';
  }

  return errors;
};

const SponsorsEditFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default SponsorsEditFormValidation;