import { Fragment } from "react";

import useNTAttachmentForm from "./UseNTAttachmentForm";

import FileTypeDropdown from "../../dropdowns/fileTypeDropdown/FileTypeDropdown";

import Input from "../../../../common/components/inputs/Input";
import Textarea from "../../../../common/components/inputs/Textarea";
import ReadOnly from "../../../../common/components/readOnly/ReadOnly";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";
import ActionIntraPageButton from "../../../../common/components/buttons/ActionIntraPageButton";

import { LOADING_MSG } from "../../../../common/utils/Constants";

import global from '../../../../common/components/GlobalStyle.module.css';

const NTAttachmentForm = ({ attachmentObj = undefined, submitButtonText = 'Save', secondaryButtonText = 'Cancel',
  onSubmitFormCallback, onSecondaryButtonClicked, onDownloadUploadedFileClicked,
  onIsDirtyChanged = undefined, onErrorCaught = undefined }) => {
  const {
    isLoading,
    hasTemplate,
    validFileTypeNames,
    uploadedFileName,
    isDirty,
    formState,
    errorState,
    onFileLoad,
    onFileUnload,
    handleSubmit,
    updateFormState,
    onFileTypeChanged,
    onDownloadFileTemplateClicked
  } = useNTAttachmentForm(attachmentObj, onSubmitFormCallback, onIsDirtyChanged, onErrorCaught);

  return (
    <Fragment>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          {hasTemplate === true &&
            <div className='col-xs-12 usas-extra-bottom-margin'>
              <ActionIntraPageButton onClick={(e) => onDownloadFileTemplateClicked(e)}>{formState.fileTypeName} Template</ActionIntraPageButton>
            </div>
          }
          <div className="col-xs-12">
            {formState.attachmentFile?.size > 0 || formState.attachmentUrl
              ? (
                <Fragment>
                  <SecondaryButton onClick={(e) => onFileUnload(e)}>Remove Uploaded File</SecondaryButton>&nbsp;
                  {formState.attachmentUrl && <PrimaryButton type="button" onClick={(e) => onDownloadUploadedFileClicked(e, formState.attachmentUrl)}>Download Uploaded File</PrimaryButton>}
                  <p><b>Uploaded File Name:</b> {uploadedFileName}</p>
                </Fragment>
              ) : (
                <Fragment>
                  <label htmlFor="attachment-file-upload" className={global.FileUpload}>Upload File</label>
                  <input hidden id="attachment-file-upload" type="file" onChange={(e) => onFileLoad(e)} />&nbsp;{validFileTypeNames}
                  {errorState.attachmentFile && <p className={global.ErrorMessage}>{errorState.attachmentFile}</p>}
                </Fragment>
              )
            }
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            {attachmentObj ? (
              <ReadOnly
                label="File Type"
                name="fileTypeName"
                value={formState.fileTypeName} />
            ) : (
              <FileTypeDropdown
                label="File Type*"
                name="fileTypeId"
                value={formState.fileTypeId}
                error={errorState.fileTypeId}
                message={errorState.fileTypeId}
                onChange={(newValue, newValueLabel, e) => { e?.target?.value && onFileTypeChanged(newValue, newValueLabel); }} />
            )}
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="File Name*"
              name="attachmentName"
              value={formState.attachmentName}
              error={errorState.attachmentName}
              message={errorState.attachmentName}
              onChange={(value) => { updateFormState('attachmentName', value); }} />
          </div>
          <div className="col-xs-12">
            <Textarea
              className={global.Textarea}
              label="Description"
              name="attachmentDescription"
              value={formState.attachmentDescription}
              error={errorState.attachmentDescription}
              message={errorState.attachmentDescription}
              onChange={(value) => { updateFormState('attachmentDescription', value); }} />
          </div>
        </div>
        {errorState.errorOnSubmitAction &&
          <div className="row">
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
            </div>
          </div>
        }
        {errorState.formError &&
          <div className="row">
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>{errorState.formError || ''}</p>
            </div>
          </div>
        }
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit" isDisabled={isDirty === false}>{submitButtonText}</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default NTAttachmentForm;