import { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import useNTRegistrationContacts from './UseNTRegistrationContacts';

import NTContactsGrid from '../../../components/grids/nationalTeamContacts/NTContactsGrid';
import NTRegistrationLeftNav from '../components/leftNav/NTRegistrationLeftNav';
import NTRegistrationLoadingModal from '../components/dialogs/NTRegistrationLoadingModal';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

import style from '../NTRegistration.module.css';

const RequirementsList = ({ requirements }) => (
  <div className="row usas-extra-top-margin">
    <div className="col-xs-12">
      Contact Requirements
      <p style={{ fontSize: 14 }} >
        {requirements.map((x, i) => <span key={i}> <FontAwesomeIcon color={x.isRequirementMet === true ? 'green' : 'orange'} icon={x.isRequirementMet === true ? faCheckCircle : faExclamationCircle} /> {x.requirementText}<br /></span>)}
      </p>
    </div>
  </div>
);

const NTRegistrationContacts = () => {
  const {
    isLoading,
    gridData,
    requirements,
    state,
    onContinueClicked,
    onBackClicked,
    onAddContactClicked,
    // onAddAddressClicked,
    onEditContactClicked,
    // onEditAddressClicked,
    onDeleteContactClicked,
    onDeleteAddressClicked,
    onDeleteContactModalConfirmClicked,
    onDeleteAddressModalConfirmClicked,
    onDeleteModalCancelClicked
  } = useNTRegistrationContacts();

  return (
    <Fragment>
      <NTRegistrationLeftNav viewTitle={'Contacts'}>
        <RequirementsList requirements={requirements} />
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <ActionIntraPageButton onClick={onAddContactClicked}>Add Contact</ActionIntraPageButton>
          </div>
        </div>
        <NTContactsGrid
          gridData={gridData}
          isLoading={isLoading}
          // onAddAddressClicked={onAddAddressClicked}
          onEditClicked={onEditContactClicked}
          // onEditAddressClicked={onEditAddressClicked}
          onDeleteClicked={onDeleteContactClicked}
          onDeleteAddressClicked={onDeleteAddressClicked} />
        <div className={['row', style.PageNavigationButtons].join(' ')}>
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>&nbsp;
            <PrimaryButton type="button" onClick={onContinueClicked}>Continue</PrimaryButton>
          </div>
        </div>
        <NTRegistrationLoadingModal isLoading={isLoading} />
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={'Delete Contact?'}
          displayPopUp={state.displayDeleteContactModal === true}
          onModalCanceled={onDeleteModalCancelClicked}>
          <div className="row">
            <div className="col-xs-12">
              <p>{state.deleteContactObj.name} ({state.deleteContactObj.relationship})</p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onDeleteContactModalConfirmClicked}>Yes, Delete</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onDeleteModalCancelClicked}>Cancel</SecondaryButton>
            </div>
          </div>
        </PopUpModal>
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={'Delete Contact Address?'}
          displayPopUp={state.displayDeleteAddressModal === true}
          onModalCanceled={onDeleteModalCancelClicked}>
          <div className="row">
            <div className="col-xs-12">
              <p>{state.deleteAddressObj.address}, {state.deleteAddressObj.city}, {state.deleteAddressObj.countryCode || state.deleteAddressObj.stateCode} {state.deleteAddressObj.postalCode} ({state.deleteAddressObj.addressTypeNames})</p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onDeleteAddressModalConfirmClicked}>Yes, Delete</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onDeleteModalCancelClicked}>Cancel</SecondaryButton>
            </div>
          </div>
        </PopUpModal>
      </NTRegistrationLeftNav>
    </Fragment>
  );
};

export default NTRegistrationContacts;