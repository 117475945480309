import { Fragment } from 'react';

import useNTRegistrationCoach from './UseNTRegistrationCoach';

import NTRegistrationLeftNav from '../components/leftNav/NTRegistrationLeftNav';
import NTRegistrationLoadingModal from '../components/dialogs/NTRegistrationLoadingModal';
import CoachPersonGrid from '../../../components/grids/coachPerson/CoachPersonGrid';
import CoachPersonAddForm from '../../../components/forms/coachPerson/CoachPersonAddForm';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import style from '../NTRegistration.module.css';

const NTRegistrationCoach = () => {
  const {
    isLoading,
    personId,
    gridData,
    state,
    onContinueClicked,
    onBackClicked,
    onDupPopupCloseClicked,
    onSubmitCoachAddForm,
    updatePageHasUnsavedChanges,
    onNTRegistrationError
  } = useNTRegistrationCoach();

  return (
    <Fragment>
      <NTRegistrationLeftNav viewTitle={'Coach'}>
        <CoachPersonAddForm
          personId={personId}
          onSubmitFormCallback={onSubmitCoachAddForm}
          onIsDirtyChanged={updatePageHasUnsavedChanges}
          onErrorCaught={onNTRegistrationError} />
        <CoachPersonGrid
          gridData={gridData}
          isLoading={isLoading} />
        <div className={['row', style.PageNavigationButtons].join(' ')}>
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>&nbsp;
            <PrimaryButton type="button" onClick={onContinueClicked}>Continue</PrimaryButton>
          </div>
        </div>
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={'Duplicate Coach Error'}
          displayPopUp={state.displayDupCoachModal === true}
          onModalCanceled={onDupPopupCloseClicked}>
          <div className="row">
            <div className="col-xs-12">
              <p>{state.dupCoachObj?.preferredName || state.dupCoachObj?.firstName} {state.dupCoachObj?.lastName} has already been added as a coach.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <SecondaryButton type="button" onClick={onDupPopupCloseClicked}>Close</SecondaryButton>
            </div>
          </div>
        </PopUpModal>
        <NTRegistrationLoadingModal isLoading={isLoading} />
      </NTRegistrationLeftNav>
    </Fragment>
  );
};

export default NTRegistrationCoach;