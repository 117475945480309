import { Fragment } from 'react';

import useFunFactsFormGrid from './UseFunFactsFormGrid';

import FunFactsFormGridLarge from './FunFactsFormGridLarge';
import FunFactsFormGridSmall from './FunFactsFormGridSmall';

import Spinner from '../../../../common/components/spinners/Spinner';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

const FunFactsFormGrid = ({ gridData, personId, onSubmitFormCallback, onIsDirtyChanged = undefined, onErrorCaught = undefined }) => {
  const {
    isLoading,
    isDirty,
    formGridState,
    onUpdateFormGridState,
    onSubmitFormGrid,
    onCancelClicked
  } = useFunFactsFormGrid(gridData, personId, onSubmitFormCallback, onIsDirtyChanged, onErrorCaught);

  if (isLoading === true) {
    return <Spinner />;
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" isDisabled={isDirty === false} onClick={onSubmitFormGrid}>Save</PrimaryButton>&nbsp;
          {isDirty === true && <SecondaryButton type="button" onClick={onCancelClicked}>Cancel</SecondaryButton>}
        </div>
      </div>
      <FunFactsFormGridLarge formGridState={formGridState} onUpdateFormGridState={onUpdateFormGridState} />
      <FunFactsFormGridSmall formGridState={formGridState} onUpdateFormGridState={onUpdateFormGridState} />
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="button" isDisabled={isDirty === false} onClick={onSubmitFormGrid}>Save</PrimaryButton>&nbsp;
          {isDirty === true && <SecondaryButton type="button" onClick={onCancelClicked}>Cancel</SecondaryButton>}
        </div>
      </div>
    </Fragment>
  );
};

export default FunFactsFormGrid;