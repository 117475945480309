import { useEffect } from 'react';

import useBioEntryData from '../../../state/bioEntry/UseBioEntryData';
import usePersonProfileData from '../../../state/personProfile/UsePersonProfileData';

import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';

import { isPersonObjectDataCurrent } from '../utils/MemberUtils';
import { createNewPersonBiosArray } from '../../../components/forms/personBioInfo/PersonBioInfoFormUtils';

const useProfileBioInfo = () => {
  const { bioEntryState, getBioEntries } = useBioEntryData();
  const { basePersonState } = useBasePersonData();
  const { personProfileState, getPersonProfile, putPersonProfile } = usePersonProfileData();

  const onSubmitBioInfoForm = (formState) => {
    if (personProfileState.isSaving === false) {
      const personId = personProfileState.objData.personId;
      const newPersonProfileObj = JSON.parse(JSON.stringify(personProfileState.objData));
      newPersonProfileObj.bios = createNewPersonBiosArray(formState, personId, personProfileState.objData.bios, bioEntryState.arrayData);
    
      putPersonProfile(personProfileState.objData.personId, newPersonProfileObj);
    }
  }

  useEffect(() => {
    if (personProfileState.isObjLoaded === false
      || !isPersonObjectDataCurrent(basePersonState.objData.personId, personProfileState.objData)) {
      getPersonProfile(basePersonState.objData.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState]);

  useEffect(() => {
    async function loadBioEntries() {
      if (bioEntryState.isArrayLoaded !== true) {
        await getBioEntries();
      }
    }
    loadBioEntries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bioEntryState.isArrayLoaded]);

  return {
    isSaving: personProfileState.isSaving,
    isLoading: personProfileState.isObjLoading || bioEntryState.isArrayLoading,
    personBiosArray: personProfileState.objData?.bios || [],
    onSubmitBioInfoForm
  };
};

export default useProfileBioInfo;