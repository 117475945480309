import { Fragment } from 'react';

import useNTRegistrationSocialMediaDetail from './UseNTRegistrationSocialMediaDetail';

import NTRegistrationLeftNav from '../components/leftNav/NTRegistrationLeftNav';
import NTRegistrationLoadingModal from '../components/dialogs/NTRegistrationLoadingModal';

import SocialMediaEditForm from '../../../components/forms/socialMedia/SocialMediaEditForm';

const NTRegistrationSocialMediaDetail = () => {
  const {
    isLoading,
    socialMediaObj,
    onCancelClicked,
    onSubmitSocialMediaEditForm,
    updatePageHasUnsavedChanges,
    onNTRegistrationError
  } = useNTRegistrationSocialMediaDetail();

  return (
    <Fragment>
      <NTRegistrationLeftNav viewTitle={'Edit Social Media'}>
        {socialMediaObj &&
          <SocialMediaEditForm
            socialMediaObj={socialMediaObj}
            onSubmitFormCallback={onSubmitSocialMediaEditForm}
            onSecondaryButtonClicked={onCancelClicked}
            onIsDirtyChanged={updatePageHasUnsavedChanges}
            onErrorCaught={onNTRegistrationError} />
        }
        <NTRegistrationLoadingModal isLoading={isLoading} />
      </NTRegistrationLeftNav>
    </Fragment>
  );
};

export default NTRegistrationSocialMediaDetail;