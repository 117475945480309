import { Fragment } from "react";

import useMemberCardLookup from "./UseMemberCardLookup";

import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import Headings from "../../../../common/components/headings/Headings";
import Dropdown from "../../../../common/components/dropdowns/Dropdown";
import Input from '../../../../common/components/inputs/Input';

import { formatDate } from '../../../../common/utils/DateFunctions';
import Constants from '../../../../common/utils/Constants';

const RequirementRow = ({ req }) => {
  return (
    <div>
      <b>{req.courseName}</b> - <span style={{ color: req.error === true || req.warning === true ? 'red' : 'black' }}>{req.status}</span>
    </div>
  );
}

const MemberCard = ({ card, isRequirementCurrent }) => (
  <div className="row" style={{ backgroundColor: '#C1C6C8', borderBottom: 0, padding: 15, width: 420, borderRadius: 15, marginLeft: 2 }}>
    <div>
      <img src={`${import.meta.env.VITE_CDN_URL}/swimShield.svg`} alt='Logo' style={{ width: 50 }} />
    </div>
    <div className="usas-extra-top-margin" style={{ fontSize: 24, color: '#041e42' }}>
      <b>{card.personaName !== 'Member' ? card.personaName + ' ' : ''}Member Card</b>
    </div>
    <div className="usas-extra-top-margin usas-extra-bottom-margin" style={{ fontSize: 20 }}>
      <b>{card.firstName} {card.lastName}</b>
    </div>
    <div>
      <b>Member ID:</b> {card.memberId}
    </div>
    <div>
      <b>LSC & Club Code:</b>&nbsp;
      {card.inTransferQualifiedOrgUnitCode !== ''
        ? card.inTransferQualifiedOrgUnitCode
        : card.qualifiedOrgUnitCode}
    </div>
    <div style={{ paddingBottom: '8px' }}>
      {card.inTransferQualifiedOrgUnitCode !== '' &&
        `Attach to ${card.qualifiedOrgUnitCode} on ${formatDate(card.reattachDate)}`
      }
    </div>
    <div>
      <b>In Good Standing Until:</b> <span style={{ color: isRequirementCurrent(card.isInGoodStandingExpirationDate) === true ? 'black' : 'red' }}>{formatDate(card.isInGoodStandingExpirationDate)}</span>
    </div>
    <div>
      <b>Expiration Date:</b> <span style={{ color: isRequirementCurrent(card.registrationExpirationDate) === true ? 'black' : 'red' }}>{formatDate(card.registrationExpirationDate)}</span>
      <hr></hr>
    </div>
    {
      card.personRequirements?.map((requirement, i) => <RequirementRow key={i} req={requirement} isRequirementCurrent={isRequirementCurrent} />)
    }
  </div>
);

const MemberCardLookup = () => {
  const {
    memberCardState,
    personPersonasState,
    formState,
    errorState,
    uiState,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    isRequirementCurrent
  } = useMemberCardLookup();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Member Card Lookup</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Member Id*"
              name="memberId"
              value={formState.memberId}
              error={errorState.memberId}
              message={errorState.memberId}
              onChange={(value) => { onFormValueChanged('memberId', value) }} />
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-4">
              {personPersonasState.isArrayLoaded &&
                personPersonasState.options.length > 0 &&
                uiState.memberFound === true &&
                <div>
                  <Dropdown
                    label="Persona"
                    name="personaId"
                    options={personPersonasState.options.filter(p => (p.id !== 5 && p.id !== 6))}
                    value={formState.personaId}
                    error={errorState.personaId}
                    message={errorState.personaId}
                    showEmptySelection={false}
                    isLoading={false}
                    onChange={(newValue, newValueLabel, e) => {
                      e && e.target && e.target.value &&
                        onValueTextPairChanged(newValue, 'personaId', newValueLabel, 'personaName');
                    }} />
                </div>
              }
            </div>
          </div>
          <div style={{ marginLeft: 15, color: 'red' }}>
            {personPersonasState.isArrayLoaded &&
              personPersonasState.options.length === 0 &&
              uiState.memberFound === true ?
              <div>Member has no current memberships</div> : <Fragment />
            }
            {uiState.memberFound === false && formState.memberId.length === 14 ?
              <div>
                Member not found
              </div> : <Fragment />
            }
            {formState.memberId.length !== 14 && formState.memberId.length !== 0 ?
              <div>
                Invalid Member Id
              </div> : <Fragment />
            }
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Lookup Member Card</PrimaryButton>&nbsp;
          </div>
        </div>
        <div>
          {memberCardState.objData.memberId &&
            <MemberCard card={memberCardState.objData} isRequirementCurrent={isRequirementCurrent} />
          }
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={memberCardState.isObjLoading || personPersonasState.isArrayLoading} />
    </Fragment>);
};

export default MemberCardLookup;

