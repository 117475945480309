import Input from "../../../../common/components/inputs/Input";
import ReadOnly from "../../../../common/components/readOnly/ReadOnly";
import InternationalPhoneInput from "../../../../common/components/inputs/InternationalPhoneInput";
import ContactRelationshipTypeDropdown from "../../../../common/components/dropdowns/contactRelationshipTypeDropdown/ContactRelationshipTypeDropdown";
import NoSelfContactRelationshipTypeDropdown from "../../../../common/components/dropdowns/noSelfContactTypeDropdown/NoSelfContactRelationshipTypeDropdown";

const NTContactSPFormInfo = ({ formValues, formErrors, isRelationshipTypeReadonly = false, excludeSelfRelationshipType = false, onUpdateFormValue }) => (
  <div className="row usas-extra-bottom-margin">
    <div className="col-xs-12 col-sm-6 col-md-4">
      <Input
        label="First Name*"
        name="firstName"
        value={formValues.firstName}
        error={formErrors.firstName}
        message={formErrors.firstName}
        onChange={(value) => { onUpdateFormValue(value, 'firstName'); }} />
    </div>
    <div className="col-xs-12 col-sm-6 col-md-4">
      <Input
        label="Last Name*"
        name="lastName"
        value={formValues.lastName}
        error={formErrors.lastName}
        message={formErrors.lastName}
        onChange={(value) => { onUpdateFormValue(value, 'lastName'); }} />
    </div>
    <div className="col-xs-12 col-sm-6 col-md-4">
      {isRelationshipTypeReadonly === true ? (
        <ReadOnly
          label="Relationship*"
          name="contactRelationshipTypeName"
          value={formValues.contactRelationshipTypeName} />
      ) : excludeSelfRelationshipType === true ? (
        <NoSelfContactRelationshipTypeDropdown
          label="Relationship*"
          name="contactRelationshipTypeId"
          value={formValues.contactRelationshipTypeId}
          error={formErrors.contactRelationshipTypeId}
          message={formErrors.contactRelationshipTypeId}
          onChange={(newValue, newValueLabel, e) => { e && e.target && e.target.value && onUpdateFormValue(newValue, 'contactRelationshipTypeId', newValueLabel, 'contactRelationshipTypeName'); }}
          showNationalTeamOptions={true} />
      ) : (
        <ContactRelationshipTypeDropdown
          label="Relationship*"
          name="contactRelationshipTypeId"
          value={formValues.contactRelationshipTypeId}
          error={formErrors.contactRelationshipTypeId}
          message={formErrors.contactRelationshipTypeId}
          onChange={(newValue, newValueLabel, e) => { e && e.target && e.target.value && onUpdateFormValue(newValue, 'contactRelationshipTypeId', newValueLabel, 'contactRelationshipTypeName'); }}
          showNationalTeamOptions={true} />
      )}
    </div>
    <div className="col-xs-12 col-sm-6 col-md-4">
      <Input
        label="Email"
        name="email"
        value={formValues.email}
        error={formErrors.email}
        message={formErrors.email}
        onChange={(value) => { onUpdateFormValue(value, 'email'); }} />
    </div>
    <div className="col-xs-12 col-sm-6 col-md-4 usas-extra-bottom-margin">
      <InternationalPhoneInput
        label="Phone Number"
        name="phoneNumber"
        value={formValues.phoneNumber}
        error={formErrors.phoneNumber}
        message={formErrors.phoneNumber}
        onChange={(value) => onUpdateFormValue(value, 'phoneNumber')} />
    </div>
  </div>
);

export default NTContactSPFormInfo;