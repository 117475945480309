import { useEffect, useState } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import useNTRegistrationData from "../../../state/nationalTeamRegistration/UseNTRegistrationData";
import useNTRegistrationLeftNavData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationLeftNav/UseNTRegistrationLeftNavData";

import useNavRoutes from "../../../../common/state/security/UseNavRoutes";

import { LEFT_NAV_ITEM_NAME_SUBMIT } from "../utils/NTRegistrationConstants";

const useNTRegistrationSubmit = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { groupMemberRegistrationState, onNTRegistrationError, postNTGroupMemberRegistration } = useNTRegistrationData();
  const { nationalTeamRegistrationLeftNavState, updateCurrentLeftNavData } = useNTRegistrationLeftNavData();
  const [displayExitModalState, setDisplayExitModalState] = useState(false);

  const onSubmitClicked = async (event) => {
    try {
      event?.preventDefault();

      const submitLeftNavObj = nationalTeamRegistrationLeftNavState.arrayData.find((x) => x.name === LEFT_NAV_ITEM_NAME_SUBMIT);

      if (submitLeftNavObj.arePrerequisitesDone === true) {
        const postNTGroupMemberRegistrationResult = await postNTGroupMemberRegistration(nationalTeamRegistrationLeftNavState.objData?.personNationalTeamGroupId);
      
        if (postNTGroupMemberRegistrationResult) {
          setDisplayExitModalState(true);
        }
      } else {
        throw new Error('Failed to validate prerequisites');
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onExitRegistrationClicked = (event) => {
    try {
      event?.preventDefault();

      window.location.href = `${import.meta.env.VITE_LOGIN_URL}`;
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onBackClicked = (event) => {
    try {
      event?.preventDefault();
      navigate(navRoutes.NT_REGISTRATION_ATTACHMENTS?.route);
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  useEffect(() => {
    try {
      updateCurrentLeftNavData(LEFT_NAV_ITEM_NAME_SUBMIT);
    } catch (e) {
      onNTRegistrationError(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading: groupMemberRegistrationState.isObjLoading,
    displayExitModal: displayExitModalState,
    acknowledgementText: 'I give USA Swimming National Team Division permission to provide my contact information for internal USA Swimming Staff (i.e. communications, foundation, accounting) and for Trip Staff use for any team which I am selected to from September 9, 2024 to September 7, 2025.',
    onSubmitClicked,
    onExitRegistrationClicked,
    onBackClicked
  };
};

export default useNTRegistrationSubmit;