import EditIcon from '../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';

import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ sponsor, onEditClicked, onDeleteClicked }) => (
  <tr>
    <td>{sponsor.sponsor?.sponsorName || ''}</td>
    <td>{sponsor.sponsor?.sponsorType?.typeName || ''}</td>
    <td>{sponsor.effectiveDate ? formatDate(sponsor.effectiveDate) : ''}</td>
    <td>{sponsor.expirationDate ? formatDate(sponsor.expirationDate) : ''}</td>
    <td className='pull-right'>
      <button className={global.IconButtonMargin} type="button" onClick={(e) => onEditClicked(e, sponsor)}>
        <EditIcon />
      </button>
      <button className={global.IconButtonMargin} type="button" onClick={(e) => onDeleteClicked(e, sponsor)}>
        <DeleteIcon />
      </button>
    </td>
  </tr>
);

const SponsorsGridLarge = ({ gridData, isLoading, onEditClicked, onDeleteClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Sponsor Name</th>
        <th>Sponsor Type</th>
        <th>Effective Date</th>
        <th>Expiration Date</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="5">Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0
          ? gridData.map((sponsor, i) => <GridRow key={i} sponsor={sponsor} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />)
          : <tr><td colSpan="5">No Sponsors</td></tr>
      }
    </tbody>
  </table>
);

export default SponsorsGridLarge;