import { Fragment } from 'react';

import useNTRegistrationAttachments from './UseNTRegistrationAttachments';

import NTRegistrationLeftNav from '../components/leftNav/NTRegistrationLeftNav';
import NTRegistrationLoadingModal from '../components/dialogs/NTRegistrationLoadingModal';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

import global from '../../../../common/components/GlobalStyle.module.css';
import style from '../NTRegistration.module.css';

const FileAttachment = ({ attachmentObj, onUploadFile, onDownloadFileClicked, onDownloadFileTemplateClicked,
  onRemoveFileClicked }) => (
  <div className="row usas-extra-top-margin usas-extra-bottom-margin">
    <Headings.H5>{attachmentObj.personNationalAttachmentFileTypeName}{attachmentObj.isRequired !== true && ' (Optional)'}</Headings.H5>
    {attachmentObj.hasTemplate === true &&
      <div className='col-xs-12 usas-extra-bottom-margin usas-extra-top-margin'>
        <ActionIntraPageButton onClick={(e) => onDownloadFileTemplateClicked(e, attachmentObj)}>{attachmentObj.personNationalAttachmentFileTypeName} Template</ActionIntraPageButton>
      </div>
    }
    {attachmentObj.attachmentUrl
      ? (
        <Fragment>
          <div className="col-xs-12 usas-extra-bottom-margin usas-extra-top-margin">
            <SecondaryButton onClick={(e) => onRemoveFileClicked(e, attachmentObj)}>Remove Uploaded File</SecondaryButton>&nbsp;
            <PrimaryButton type="button" onClick={(e) => onDownloadFileClicked(e, attachmentObj)}>Download Uploaded File</PrimaryButton>
          </div>
          <div className="col-xs-12 usas-extra-bottom-margin">
            <p><b>Uploaded File Name: </b> {attachmentObj.attachmentName}</p>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className="col-xs-12 usas-extra-bottom-margin usas-extra-top-margin">
            <label htmlFor={`file-upload-${attachmentObj.personNationalAttachmentFileTypeId}`} className={global.FileUpload}>
              Upload File
            </label>
            <input hidden id={`file-upload-${attachmentObj.personNationalAttachmentFileTypeId}`} type="file" onChange={(e) => onUploadFile(e, attachmentObj)} />
            &nbsp;{attachmentObj.validFileTypeNames}
          </div>
        </Fragment>
      )
    }
  </div>
);

const NTRegistrationAttachments = () => {
  const {
    isLoading,
    filesState,
    state,
    onContinueClicked,
    onBackClicked,
    onUploadFile,
    onDownloadFileClicked,
    onDownloadFileTemplateClicked,
    onRemoveFileClicked,
    onPopupCancelClicked,
    onRemovePopupConfirmClicked
  } = useNTRegistrationAttachments();

  return (
    <Fragment>
      <NTRegistrationLeftNav viewTitle={'Attachments'}>
        {filesState.map((attachmentObj, i) => <FileAttachment key={i} attachmentObj={attachmentObj} onUploadFile={onUploadFile} onDownloadFileClicked={onDownloadFileClicked} onDownloadFileTemplateClicked={onDownloadFileTemplateClicked} onRemoveFileClicked={onRemoveFileClicked} />)}
        <div className={['row', style.PageNavigationButtons].join(' ')}>
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>&nbsp;
            <PrimaryButton type="button" onClick={onContinueClicked}>Continue</PrimaryButton>
          </div>
        </div>
        <NTRegistrationLoadingModal isLoading={isLoading} />
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={'Remove Uploaded File?'}
          displayPopUp={state.displayRemovePopup}
          onModalCanceled={onPopupCancelClicked}>
          <div className="row">
            <div className="col-xs-12">
              <p><b>File Type:</b> {state.removeAttachmentObj?.personNationalAttachmentFileTypeName}</p>
              <p><b>Uploaded File Name:</b> {state.removeAttachmentObj?.attachmentName}</p>
            </div>
          </div>
          <div className="row usas-extra-top-margin">
            <div className="col-xs-12">
              <PrimaryButton type="button" onClick={onRemovePopupConfirmClicked}>Yes, Remove</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onPopupCancelClicked}>Cancel</SecondaryButton>
            </div>
          </div>
        </PopUpModal>
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={'Validation Error'}
          displayPopUp={state.displayValidationPopup}
          onModalCanceled={onPopupCancelClicked}>
          <div className="row">
            <div className="col-xs-12">
              <p>{state.validationMessage}</p>
            </div>
          </div>
          <div className="row usas-extra-top-margin">
            <div className="col-xs-12">
              <SecondaryButton type="button" onClick={onPopupCancelClicked}>Close</SecondaryButton>
            </div>
          </div>
        </PopUpModal>
      </NTRegistrationLeftNav>
    </Fragment>
  );
};

export default NTRegistrationAttachments;