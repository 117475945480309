import useCoachPersonAddForm from "./UseCoachPersonAddForm";

import DatePicker from "../../../../common/components/datepickers/DatePicker";
import YesNoSwitch from "../../../../common/components/yesNoSwitch/YesNoSwitch";
import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";
import GroupPersonCoachesDropdown from "../../../../common/components/dropdowns/groupPersonCoachesDropdown/GroupPersonCoachesDropdown";

import global from '../../../../common/components/GlobalStyle.module.css';

const CoachPersonAddForm = ({ personId, submitButtonText = 'Add Coach', onSubmitFormCallback,
  onIsDirtyChanged = undefined, onErrorCaught = undefined }) => {
  const {
    isDirty,
    formState,
    errorState,
    resetForm,
    handleSubmit,
    updateFormState,
    onValueTextPairChanged
  } = useCoachPersonAddForm(onSubmitFormCallback, onIsDirtyChanged, onErrorCaught);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="row">
        {personId &&
          <div className="col-xs-12 col-sm-6 col-md-4">
            <GroupPersonCoachesDropdown
              label="Coach"
              name="coachPersonId"
              value={formState.coachPersonId}
              error={errorState.coachPersonId}
              message={errorState.coachPersonId}
              onChange={(newValue, newValueLabel, e) => { e?.target?.value && onValueTextPairChanged(newValue, 'coachPersonId', newValueLabel, 'coachPersonName'); }}
              personId={personId}
              isActive={true} />
          </div>
        }
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <DatePicker
            label="Effective Date"
            name="effectiveDate"
            value={formState.effectiveDate}
            error={errorState.effectiveDate}
            message={errorState.effectiveDate}
            countOfYears={150}
            startYearOffset={-149}
            onChange={(value) => { updateFormState('effectiveDate', value); }} />
        </div>
        <div className='col-xs-12 col-sm-6 col-md-4'>
          <YesNoSwitch
            label={'No Expiration Date?'}
            name={'hasNoExpirationDate'}
            checked={formState.hasNoExpirationDate === true}
            error={formState.hasNoExpirationDate}
            message={formState.hasNoExpirationDate}
            onChange={(value) => updateFormState('hasNoExpirationDate', value)} />
        </div>
        {formState.hasNoExpirationDate === false &&
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label="Expiration Date"
              name="expirationDate"
              value={formState.expirationDate}
              error={errorState.expirationDate}
              message={errorState.expirationDate}
              countOfYears={150}
              startYearOffset={-140}
              onChange={(value) => { updateFormState('expirationDate', value); }} />
          </div>
        }
      </div>
      {errorState.errorOnSubmitAction &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
          </div>
        </div>
      }
      {errorState.formError &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{errorState.formError || ''}</p>
          </div>
        </div>
      }
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="submit" isDisabled={isDirty === false}>{submitButtonText}</PrimaryButton>&nbsp;
          {isDirty === true && <SecondaryButton type="button" onClick={resetForm}>Cancel</SecondaryButton>}
        </div>
      </div>
    </form>
  );
};

export default CoachPersonAddForm;