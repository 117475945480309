import { Fragment } from 'react';

import useNTContactSPSuggestionsFormGrid from './UseNTContactSPSuggestionsFormGrid';

import Checkbox from '../../../../common/components/checkboxes/Checkbox';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

import global from '../../../../common/components/GlobalStyle.module.css';
import style from './NTContactSPFormAddresses.module.css';

const DetailTable = ({ contactAddressId, enteredValues, suggestedValues, isSelectedValues, onUpdateIsSelected }) => (
  <table className={[global.DetailTable, style.FormTable].join(' ')}>
    <thead>
      <tr>
        <th>Field</th>
        <th>Entered Value</th>
        <th>Suggested Value</th>
        <th>Use Suggested Value?</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Address Type</td>
        <td className={isSelectedValues.addressType === true ? style.DiscardedValue : style.SelectedValue}>
          {Array.isArray(enteredValues.addressType) && enteredValues.addressType.length > 0
            ? enteredValues.addressType.length === 1 ? enteredValues.addressType[0].name : enteredValues.addressType.map((type) => type.name).join(', ')
            : ''
          }
        </td>
        <td className={isSelectedValues.addressType === true ? style.SelectedValue : ''}>
          {Array.isArray(suggestedValues.addressType) && suggestedValues.addressType.length > 0
            ? suggestedValues.addressType.length === 1 ? suggestedValues.addressType[0].name : suggestedValues.addressType.map((type) => type.name).join(', ')
            : ''
          }
        </td>
        <td>
          {(isSelectedValues.addressType === true || isSelectedValues.addressType === false) &&
            <Checkbox
              name={`addressType${contactAddressId}`}
              checked={isSelectedValues.addressType}
              onChange={() => { onUpdateIsSelected(contactAddressId, 'addressType') }} />
          }
        </td>
      </tr>
      <tr>
        <td>Address Line 1</td>
        <td className={isSelectedValues.address1 === true ? style.DiscardedValue : style.SelectedValue}>
          {enteredValues.address1 || ''}
        </td>
        <td className={isSelectedValues.address1 === true ? style.SelectedValue : ''}>
          {suggestedValues.address1 || ''}
        </td>
        <td>
          {(isSelectedValues.address1 === true || isSelectedValues.address1 === false) &&
            <Checkbox
              name={`address1${contactAddressId}`}
              checked={isSelectedValues.address1}
              onChange={() => { onUpdateIsSelected(contactAddressId, 'address1') }} />
          }
        </td>
      </tr>
      <tr>
        <td>Address Line 2</td>
        <td className={isSelectedValues.address2 === true ? style.DiscardedValue : style.SelectedValue}>
          {enteredValues.address2 || ''}
        </td>
        <td className={isSelectedValues.address2 === true ? style.SelectedValue : ''}>
          {suggestedValues.address2 || ''}
        </td>
        <td>
          {(isSelectedValues.address2 === true || isSelectedValues.address2 === false) &&
            <Checkbox
              name={`address2${contactAddressId}`}
              checked={isSelectedValues.address2}
              onChange={() => { onUpdateIsSelected(contactAddressId, 'address2') }} />
          }
        </td>
      </tr>
      <tr>
        <td>City</td>
        <td className={isSelectedValues.city === true ? style.DiscardedValue : style.SelectedValue}>
          {enteredValues.city || ''}
        </td>
        <td className={isSelectedValues.city === true ? style.SelectedValue : ''}>
          {suggestedValues.city || ''}
        </td>
        <td>
          {(isSelectedValues.city === true || isSelectedValues.city === false) &&
            <Checkbox
              name={`city${contactAddressId}`}
              checked={isSelectedValues.city}
              onChange={() => { onUpdateIsSelected(contactAddressId, 'city') }} />
          }
        </td>
      </tr>
      <tr>
        <td>Zip Code</td>
        <td className={isSelectedValues.postalCode === true ? style.DiscardedValue : style.SelectedValue}>
          {enteredValues.postalCode || ''}
        </td>
        <td className={isSelectedValues.postalCode === true ? style.SelectedValue : ''}>
          {suggestedValues.postalCode || ''}
        </td>
        <td>
          {(isSelectedValues.postalCode === true || isSelectedValues.postalCode === false) &&
            <Checkbox
              name={`postalCode${contactAddressId}`}
              checked={isSelectedValues.postalCode}
              onChange={() => { onUpdateIsSelected(contactAddressId, 'postalCode') }} />
          }
        </td>
      </tr>
      <tr>
        <td>Country</td>
        <td className={isSelectedValues.countryName === true ? style.DiscardedValue : style.SelectedValue}>
          {enteredValues.countryName || ''}
        </td>
        <td className={isSelectedValues.countryName === true ? style.SelectedValue : ''}>
          {suggestedValues.countryName || ''}
        </td>
        <td>
          {(isSelectedValues.countryName === true || isSelectedValues.countryName === false) &&
            <Checkbox
              name={`countryName${contactAddressId}`}
              checked={isSelectedValues.countryName}
              onChange={() => { onUpdateIsSelected(contactAddressId, 'countryName') }} />
          }
        </td>
      </tr>
      {(enteredValues.isCountryUSA === true || enteredValues.isCountryMexico === true || enteredValues.isCountryCanada === true) &&
        <tr>
          <td>State</td>
          <td className={isSelectedValues.stateCode === true ? style.DiscardedValue : style.SelectedValue}>
            {enteredValues.stateCode || ''}
          </td>
          <td className={isSelectedValues.stateCode === true ? style.SelectedValue : ''}>
            {suggestedValues.stateCode || ''}
          </td>
          <td>
            {(isSelectedValues.stateCode === true || isSelectedValues.stateCode === false) &&
              <Checkbox
                name={`stateCode${contactAddressId}`}
                checked={isSelectedValues.stateCode}
                onChange={() => { onUpdateIsSelected(contactAddressId, 'stateCode') }} />
            }
          </td>
        </tr>
      }
    </tbody>
  </table>
);

const GridRow = ({ contactAddress, onUpdateIsSelected }) => (
  <Fragment>
    <tr>
      <td colSpan='4'>{contactAddress.isAdd === true ? 'New Address' : 'Existing Address'}</td>
    </tr>
    {contactAddress.isDeleted !== true &&
      <tr className={global.Expanded}>
        <td colSpan="4">
          <DetailTable contactAddressId={contactAddress.id} enteredValues={contactAddress.values} suggestedValues={contactAddress.suggestedValues} isSelectedValues={contactAddress.isSelectedValues} onUpdateIsSelected={onUpdateIsSelected} />
        </td>
      </tr>
    }
  </Fragment>
);

const NTContactSPSuggestionsFormGrid = ({ multiFormState, addressSuggestions, onSubmitFormCallback, onBackClicked }) => {
  const {
    formGridState,
    onSubmitFormGrid,
    onUpdateIsSelected
  } = useNTContactSPSuggestionsFormGrid(multiFormState, addressSuggestions, onSubmitFormCallback);

  return (
    <Fragment>
      <div id='suggestion-form-top' className="row">
        <div className="col-xs-12">
          <p>One or more addresses you entered have suggestions</p>
        </div>
      </div>
      <table className={[global.UsasTable].join(' ')}>
        <thead>
          <tr>
            <th colSpan="4">Address Suggestions</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(formGridState) && formGridState.length > 0
            && formGridState.map((contactAddress, i) =>
              <GridRow key={i} contactAddress={contactAddress} onUpdateIsSelected={onUpdateIsSelected} />)
          }
        </tbody>
      </table>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onSubmitFormGrid}>Continue</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
        </div>
      </div>
    </Fragment>
  );
};

export default NTContactSPSuggestionsFormGrid;