import { useEffect, useState } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import useNTAttachmentData from "../../../state/nationalTeamAttachment/UseNTAttachmentData";
import useNTRegistrationData from "../../../state/nationalTeamRegistration/UseNTRegistrationData";
import useNTRegistrationPersonData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationPerson/UseNTRegistrationPersonData";
import useNTRegistrationLeftNavData from "../../../state/nationalTeamRegistration/nationalTeamRegistrationLeftNav/UseNTRegistrationLeftNavData";
import usePersonNationalTeamFileTypeData from "../../../state/personNationalTeamFileType/UsePersonNationalTeamFileTypeData";

import useNavRoutes from "../../../../common/state/security/UseNavRoutes";

import { LEFT_NAV_ITEM_NAME_ATTACHMENTS, LEFT_NAV_ITEM_NAME_SUBMIT } from "../utils/NTRegistrationConstants";

import { formatValidFileTypes, openFileInNewTab } from "../../../../common/utils/FileUtils";

const INITIAL_STATE = {
  displayValidationPopup: false,
  displayRemovePopup: false,
  removeAttachmentObj: {},
  validationMessage: ''
};

const useNTRegistrationAttachments = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { onNTRegistrationError } = useNTRegistrationData();
  const { nationalTeamRegistrationLeftNavState, updateCurrentLeftNavData } = useNTRegistrationLeftNavData();
  const { nationalTeamRegistrationPersonState, getNTRegistrationPerson } = useNTRegistrationPersonData();
  const { personNationalTeamFileTypeState, nationalTeamAttachmentTemplateState, getPersonNationalTeamAttachmentFileTypes,
    getNTAttachmentFileTemplate
  } = usePersonNationalTeamFileTypeData();
  const { nationalTeamAttachmentState, nationalTeamAttachmentUploadState, nationalTeamAttachmentDownloadState,
    nationalTeamAttachmentDeleteState, getNTAttachmentsByPersonId, postNTAttachment, getNTAttachment, deleteNTAttachment
  } = useNTAttachmentData();
  const [filesState, setFilesState] = useState([]);
  const [state, setState] = useState(INITIAL_STATE);

  const onContinueClicked = (event) => {
    try {
      event?.preventDefault();
      const requiredFiles = filesState.filter((x) => x.isRequired === true && x.attachmentUrl === null);
      const submitLeftNavObj = nationalTeamRegistrationLeftNavState.arrayData.find((x) => x.name === LEFT_NAV_ITEM_NAME_SUBMIT);

      if (requiredFiles.length > 0) {
        setState({
          ...state,
          displayValidationPopup: true,
          validationMessage: formatRequiredFilesMessage(requiredFiles)
        });
      } else if (submitLeftNavObj.arePrerequisitesDone === true) {
        navigate(navRoutes.NT_REGISTRATION_SUBMIT?.route);
      } else {
        setState({
          ...state,
          displayValidationPopup: true,
          validationMessage: 'Must complete all prerequisite registration steps before continuing.'
        });
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onBackClicked = (event) => {
    try {
      event?.preventDefault();
      navigate(navRoutes.NT_REGISTRATION_FUN_FACTS?.route);
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onUploadFile = async (event, attachmentObj) => {
    try {
      event.preventDefault();

      if (event.target.files[0]) {
        const file = event.target.files[0];
        const fileType = event.target.files[0].type;
        const fileName = event.target.files[0].name;
        const personId = nationalTeamRegistrationPersonState.objData.personId;
        if (attachmentObj.validFileTypes.includes(fileType)) {
          const newAttachmentObj = {
            personId,
            personNationalAttachmentFileTypeId: attachmentObj.personNationalAttachmentFileTypeId,
            attachmentDescription: '',
            attachmentName: fileName,
          };

          const postNTAttachmentResult = await postNTAttachment(file, newAttachmentObj);

          if (postNTAttachmentResult) {
            const getNTAttachmentsByPersonIdResult = await getNTAttachmentsByPersonId(0);

            if (getNTAttachmentsByPersonIdResult) {
              const newFilesState = createNewFilesState(getNTAttachmentsByPersonIdResult.arrayData, personNationalTeamFileTypeState.arrayData);

              setFilesState(newFilesState);

              updateCurrentLeftNavData(LEFT_NAV_ITEM_NAME_ATTACHMENTS, getAttachmentsIsComplete(newFilesState));
            }
          }
        } else {
          setState({
            ...state,
            displayValidationPopup: true,
            validationMessage: `File type not permitted. Please provide a ${attachmentObj.validFileTypeNames} file.`
          });
        }
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onDownloadFileTemplateClicked = async (event, attachmentObj) => {
    try {
      event?.preventDefault();

      const getNTAttachmentFileTemplateResult = await getNTAttachmentFileTemplate(attachmentObj.personNationalAttachmentFileTypeName);

      openFileInNewTab(getNTAttachmentFileTemplateResult.objData?.downloadUrl);
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onDownloadFileClicked = async (event, attachmentObj) => {
    try {
      event?.preventDefault();

      const getNTAttachmentResult = await getNTAttachment(attachmentObj.attachmentUrl);

      openFileInNewTab(getNTAttachmentResult.objData?.downloadUrl);
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onRemoveFileClicked = (event, attachmentObj) => {
    try {
      event?.preventDefault();

      setState({
        ...state,
        displayRemovePopup: true,
        removeAttachmentObj: attachmentObj
      });
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onRemovePopupConfirmClicked = async (event) => {
    try {
      event?.preventDefault();

      const attachmentId = state.removeAttachmentObj.personNationalAttachmentId;
      const attachmentUrl = state.removeAttachmentObj.attachmentUrl;

      setState({ ...INITIAL_STATE });

      const deleteNTAttachmentResult = await deleteNTAttachment(attachmentId, attachmentUrl);

      if (deleteNTAttachmentResult) {
        const getNTAttachmentsByPersonIdResult = await getNTAttachmentsByPersonId(0);

        if (getNTAttachmentsByPersonIdResult) {
          const newFilesState = createNewFilesState(getNTAttachmentsByPersonIdResult.arrayData, personNationalTeamFileTypeState.arrayData);

          setFilesState(newFilesState);

          updateCurrentLeftNavData(LEFT_NAV_ITEM_NAME_ATTACHMENTS, getAttachmentsIsComplete(newFilesState));
        }
      }
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  const onPopupCancelClicked = (event) => {
    try {
      event?.preventDefault();

      setState({ ...INITIAL_STATE });
    } catch (e) {
      onNTRegistrationError(e);
    }
  };

  useEffect(() => {
    async function loadNTRegistrationAttachments() {
      try {
        updateCurrentLeftNavData(LEFT_NAV_ITEM_NAME_ATTACHMENTS);

        if (personNationalTeamFileTypeState.isArrayLoaded !== true) {
          getPersonNationalTeamAttachmentFileTypes(); //TODO make promise
        } else if (nationalTeamAttachmentState.isArrayLoaded !== true) {
          await getNTAttachmentsByPersonId(0);
        } else {
          const newFilesState = createNewFilesState(nationalTeamAttachmentState.arrayData, personNationalTeamFileTypeState.arrayData);

          setFilesState(newFilesState);

          updateCurrentLeftNavData(LEFT_NAV_ITEM_NAME_ATTACHMENTS, getAttachmentsIsComplete(newFilesState));
        }
      } catch (e) {
        onNTRegistrationError(e);
      }
    }
    loadNTRegistrationAttachments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nationalTeamAttachmentState.isArrayLoaded, personNationalTeamFileTypeState.isArrayLoaded]);

  useEffect(() => {
    async function loadNTRegistrationPerson() {
      try {
        if (nationalTeamRegistrationPersonState.isObjLoaded !== true) {
          await getNTRegistrationPerson(0);
        }
      } catch (e) {
        onNTRegistrationError(e);
      }
    }
    loadNTRegistrationPerson();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nationalTeamRegistrationPersonState.isObjLoaded]);

  function createNewFilesState(attachmentsArray, fileTypesArray) {
    const newFilesState = [];

    for (const fileType of fileTypesArray) {
      if (fileType.includeInRegistration === true) {
        const existingAttachment = attachmentsArray.find((x) => x.personNationalAttachmentFileTypeId === fileType.personNationalAttachmentFileTypeId);
        const [validFileTypesArray, validFileTypeNames] = formatValidFileTypes(fileType.validFileTypes);

        if (existingAttachment) {
          newFilesState.push({
            personNationalAttachmentId: existingAttachment.personNationalAttachmentId,
            attachmentDescription: existingAttachment.attachmentDescription,
            attachmentName: existingAttachment.attachmentName,
            attachmentUrl: existingAttachment.attachmentUrl,
            personNationalAttachmentFileTypeId: fileType.personNationalAttachmentFileTypeId,
            personNationalAttachmentFileTypeName: fileType.typeName,
            isRequired: fileType.isRequired === true,
            hasTemplate: fileType.hasTemplate === true,
            validFileTypes: validFileTypesArray,
            validFileTypeNames: validFileTypeNames
          });
        } else {
          newFilesState.push({
            personNationalAttachmentId: null,
            attachmentDescription: null,
            attachmentName: null,
            attachmentUrl: null,
            personNationalAttachmentFileTypeId: fileType.personNationalAttachmentFileTypeId,
            personNationalAttachmentFileTypeName: fileType.typeName,
            isRequired: fileType.isRequired === true,
            hasTemplate: fileType.hasTemplate === true,
            validFileTypes: validFileTypesArray,
            validFileTypeNames: validFileTypeNames
          });
        }
      }
    }

    newFilesState.sort((a, b) => a.isRequired > b.isRequired ? -1 : 1);

    return newFilesState;
  }

  function formatRequiredFilesMessage(requiredFiles) {
    let requiredFilesMessage = '';

    for (let i = 0; i < requiredFiles.length; i++) {
      if (requiredFilesMessage) {
        if (requiredFiles.length - 1 === i) {
          requiredFilesMessage += ` and ${requiredFiles[i].personNationalAttachmentFileTypeName}`;
        } else {
          requiredFilesMessage += `, ${requiredFiles[i].personNationalAttachmentFileTypeName}`;
        }
      } else {
        requiredFilesMessage = `${requiredFiles[i].personNationalAttachmentFileTypeName}`;
      }
    }

    return `Must upload a valid ${requiredFilesMessage}`;
  }

  function getAttachmentsIsComplete(filesState) {
    for (const file of filesState) {
      if (file.isRequired === true && file.attachmentUrl === null) {
        return false;
      }
    }

    return true;
  }

  return {
    isLoading: nationalTeamAttachmentState.isArrayLoading || personNationalTeamFileTypeState.isArrayLoading || nationalTeamRegistrationPersonState.isObjLoading || nationalTeamAttachmentDeleteState.isObjLoading || nationalTeamAttachmentUploadState.isObjLoading || nationalTeamAttachmentDownloadState.isObjLoading || nationalTeamAttachmentTemplateState.isObjLoading,
    filesState,
    state,
    onContinueClicked,
    onBackClicked,
    onUploadFile,
    onDownloadFileClicked,
    onDownloadFileTemplateClicked,
    onRemoveFileClicked,
    onPopupCancelClicked,
    onRemovePopupConfirmClicked
  };
};

export default useNTRegistrationAttachments;