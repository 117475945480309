import { Fragment } from 'react';

import useMemberGroupsRegistration from './UseMemberGroupsRegistration';

import ListGroupForm from './ListGroupForm';

import GroupQualificationEventGrid from '../../../../../common/components/grids/groupQualificationEvent/GroupQualificationEventGrid';

import CompetitorListRegistrationForm from '../../../../../common/components/forms/groups/CompetitorListRegistrationForm';
import CampRegistrationForm from '../../../../../common/components/forms/groups/CampRegistrationForm';
import SAARegistrationForm from '../../../../../common/components/forms/groups/SAARegistrationForm';
import NationalTeamRegistrationForm from '../../../../../common/components/forms/groups/NationalTeamRegistrationForm';
import ErrorMessage from '../../../../../common/components/errors/ErrorMessage';
import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';

const GroupRegistrationForm = ({ GROUP_CONSTANTS, groupTypeName, personId, groupObj, memberGroupObj,
  onBackClicked, onFormCompletion }) => {
  switch (groupTypeName) {
    case GROUP_CONSTANTS.GROUP_TYPE_NAME_LIST:
      return <ListGroupForm personId={personId} personOrgGroupId={groupObj.personOrgGroupId}
        personOrgGroupQualificationStatus={groupObj.status} groupObj={groupObj} memberGroupObj={memberGroupObj}
        onFormCompletion={onFormCompletion} onBackClicked={onBackClicked} />
    case GROUP_CONSTANTS.GROUP_TYPE_NAME_COMPETITOR_LIST:
      return <CompetitorListRegistrationForm personId={personId} personOrgGroupId={groupObj.personOrgGroupId}
        personOrgGroupQualificationStatus={groupObj.status} groupObj={groupObj} memberGroupObj={memberGroupObj}
        primaryButtonText={'Submit'} onSecondaryButtonClicked={onBackClicked} onFormCompletion={onFormCompletion} />;
    case GROUP_CONSTANTS.GROUP_TYPE_NAME_CAMP:
      return <CampRegistrationForm personId={personId} personOrgGroupId={groupObj.personOrgGroupId}
        personOrgGroupQualificationStatus={groupObj.status} groupObj={groupObj} memberGroupObj={memberGroupObj}
        primaryButtonText={'Submit'} onSecondaryButtonClicked={onBackClicked} onFormCompletion={onFormCompletion} />;
    case GROUP_CONSTANTS.GROUP_TYPE_NAME_SAA:
      return <SAARegistrationForm personId={personId} personOrgGroupId={groupObj.personOrgGroupId}
        personOrgGroupQualificationStatus={groupObj.status} stepNotes={groupObj.stepNotes} groupObj={groupObj} memberGroupObj={memberGroupObj}
        primaryButtonText={'Submit'} onSecondaryButtonClicked={onBackClicked} onFormCompletion={onFormCompletion} />;
    case GROUP_CONSTANTS.GROUP_TYPE_NAME_NATIONAL_TEAM:
      return <NationalTeamRegistrationForm personId={personId} personOrgGroupId={groupObj.personOrgGroupId}
        personOrgGroupQualificationStatus={groupObj.status} groupObj={groupObj} memberGroupObj={memberGroupObj}
        primaryButtonText={'Submit'} onSecondaryButtonClicked={onBackClicked} onFormCompletion={onFormCompletion} />;
    default:
      return <ErrorMessage message={'An error occurred'} />
  }
};

const MemberGroupsRegistration = () => {
  const {
    isLoading,
    isSaving,
    isGridLoading,
    isMemberGroupObjLoaded,
    groupTypeName,
    groupObj,
    memberGroupObj,
    groupQualificationEvents,
    personId,
    onBackClicked,
    onFormCompletion,
    GROUP_CONSTANTS
  } = useMemberGroupsRegistration();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{groupTypeName} Registration</Headings.H3>
        </div>
      </div>
      <div className="row">
        {groupQualificationEvents.length > 0 &&
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <GroupQualificationEventGrid data={groupQualificationEvents} isLoading={isGridLoading} />
          </div>
        }
      </div>
      {groupObj.personOrgGroupId > 0
        ? isMemberGroupObjLoaded === true
          ? (
            <GroupRegistrationForm
              GROUP_CONSTANTS={GROUP_CONSTANTS}
              groupTypeName={groupTypeName}
              personId={personId}
              groupObj={groupObj}
              memberGroupObj={memberGroupObj}
              onFormCompletion={onFormCompletion}
              onBackClicked={onBackClicked} />
          ) : (
            <Fragment />
          ) : (
          <GroupRegistrationForm
            GROUP_CONSTANTS={GROUP_CONSTANTS}
            groupTypeName={groupTypeName}
            personId={personId}
            groupObj={groupObj}
            memberGroupObj={memberGroupObj}
            onFormCompletion={onFormCompletion}
            onBackClicked={onBackClicked} />
        )
      }
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default MemberGroupsRegistration;