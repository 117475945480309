import { Fragment } from 'react';

import useNTRegistrationBioInfo from './UseNTRegistrationBioInfo';

import NTRegistrationLeftNav from '../components/leftNav/NTRegistrationLeftNav';
import NTRegistrationLoadingModal from '../components/dialogs/NTRegistrationLoadingModal';
import NTRegistrationBioInfoForm from '../../../components/forms/personBioInfo/NTRegistrationBioInfoForm';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

const NTRegistrationBioInfo = () => {
  const {
    state,
    isLoading,
    personBiosArray,
    onExitClicked,
    onContinueClicked,
    onSubmitBioInfoForm,
    onExitModalCancelClicked,
    onExitModalConfirmClicked,
    updatePageHasUnsavedChanges,
    onNTRegistrationError
  } = useNTRegistrationBioInfo();

  return (
    <Fragment>
      <NTRegistrationLeftNav viewTitle={'Biographical Info'}>
        <NTRegistrationBioInfoForm
          personBiosArray={personBiosArray}
          secondaryButtonText={'Exit'}
          onSubmitFormCallback={onSubmitBioInfoForm} // only show plain continue button if bio info is complete
          onPrimaryButtonClicked={state.isComplete === true ? onContinueClicked : undefined} 
          onSecondaryButtonClicked={onExitClicked}
          onIsDirtyChanged={updatePageHasUnsavedChanges}
          onErrorCaught={onNTRegistrationError} />
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title="Exit National Team Registration?"
          displayPopUp={state.displayExitModal}
          onModalCanceled={onExitModalCancelClicked}>
          <div className="row">
            <div className="col-xs-12">
              <p>Are you sure you want to exit National Team Registration?</p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onExitModalConfirmClicked}>Yes, Exit Registration</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onExitModalCancelClicked}>Cancel</SecondaryButton>
            </div>
          </div>
        </PopUpModal>
        <NTRegistrationLoadingModal isLoading={isLoading} />
      </NTRegistrationLeftNav>
    </Fragment>
  );
};

export default NTRegistrationBioInfo;