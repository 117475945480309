import BioEntryContextProvider from '../../state/bioEntry/BioEntryContextProvider';
import NTContactsContextProvider from '../../state/nationalTeamContacts/NTContactsContextProvider';
import NTAttachmentContextProvider from '../../state/nationalTeamAttachment/NTAttachmentContextProvider';
import PersonProfileContextProvider from '../../state/personProfile/PersonProfileContextProvider';
import PersonNationalTeamContextProvider from '../../state/personNationalTeam/PersonNationalTeamContextProvider';
import PersonCoursesForAdminRoleContextProvider from '../../state/member/memberAdminCourses/PersonCoursesForAdminRoleContextProvider';
import PersonNationalTeamFileTypeContextProvider from '../../state/personNationalTeamFileType/PersonNationalTeamFileTypeContextProvider';

import UserContextProvider from '../../../common/state/lmsUser/UserContextProvider';
import CommentContextProvider from '../../../common/state/comment/CommentContextProvider';
import BasePersonContextProvider from '../../../common/state/personBase/BasePersonContextProvider';
import EnrollmentContextProvider from '../../../common/state/lmsEnrollment/EnrollmentContextProvider';
import PersonGeneralContextProvider from '../../../common/state/personGeneral/PersonGeneralContextProvider';
import GenderIdentityTypeContextProvider from '../../../common/state/genderIdentity/GenderIdentityTypeContextProvider';
import ContactRelationshipTypesContextProvider from '../../../common/state/contactRelationshipType/ContactRelationshipTypesContextProvider';
import EnrollmentInCertificationContextProvider from '../../../common/state/lmsEnrollment/EnrollmentInCertificationContextProvider';
import CoachEnrollmentInCertificationContextProvider from '../../../common/state/lmsEnrollment/CoachEnrollmentInCertificationContextProvider';

const MemberContextRoutesProviders = ({ children }) => {
  return (
    <BasePersonContextProvider>
      <PersonGeneralContextProvider>
        <BioEntryContextProvider>
          <NTContactsContextProvider>
            <NTAttachmentContextProvider>
              <CommentContextProvider>
                <PersonProfileContextProvider>
                  <PersonNationalTeamContextProvider>
                    <PersonNationalTeamFileTypeContextProvider>
                      <ContactRelationshipTypesContextProvider>
                        <GenderIdentityTypeContextProvider>
                          <EnrollmentContextProvider>
                            <EnrollmentInCertificationContextProvider>
                              <CoachEnrollmentInCertificationContextProvider>
                                <PersonCoursesForAdminRoleContextProvider>
                                  <UserContextProvider>
                                    {children}
                                  </UserContextProvider>
                                </PersonCoursesForAdminRoleContextProvider>
                              </CoachEnrollmentInCertificationContextProvider>
                            </EnrollmentInCertificationContextProvider>
                          </EnrollmentContextProvider>
                        </GenderIdentityTypeContextProvider>
                      </ContactRelationshipTypesContextProvider>
                    </PersonNationalTeamFileTypeContextProvider>
                  </PersonNationalTeamContextProvider>
                </PersonProfileContextProvider>
              </CommentContextProvider>
            </NTAttachmentContextProvider>
          </NTContactsContextProvider>
        </BioEntryContextProvider>
      </PersonGeneralContextProvider>
    </BasePersonContextProvider>
  );
};

export default MemberContextRoutesProviders;