import {
  FILE_TYPE_JPG, FILE_TYPE_MP3, FILE_TYPE_PDF, FILE_TYPE_PNG, FILE_TYPE_WAV,
  FILE_TYPE_CSV, FILE_TYPE_DOC, FILE_TYPE_DOCX, FILE_TYPE_XLSX
} from "./Constants";

const FILE_TYPES = {
  PDF: FILE_TYPE_PDF,
  JPG: FILE_TYPE_JPG,
  PNG: FILE_TYPE_PNG,
  MP3: FILE_TYPE_MP3,
  WAV: FILE_TYPE_WAV,
  CSV: FILE_TYPE_CSV,
  DOC: FILE_TYPE_DOC,
  DOCX: FILE_TYPE_DOCX,
  XLSX: FILE_TYPE_XLSX
};

export const openFileInNewTab = (fileObjUrl) => {
  const link = document.createElement('a');

  link.href = fileObjUrl;
  link.setAttribute('target', '_blank');
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const getFileNameFromUrl = (url, baseUploadUrl) => {
  try {
    const fileNameWithDateTime = url.replace(baseUploadUrl, '');
    const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
    const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);

    return decodeURI(fileName) || '';
  } catch (e) {
    return url;
  }
};

export const formatValidFileTypes = (validFileTypes) => {
  const fileTypes = validFileTypes.split(', ');
  const validFileTypesArray = [];
  let validFileTypeNames = '';

  for (let i = 0; i < fileTypes.length; i++) {
    if (validFileTypeNames) {
      if (fileTypes.length - 1 === i) {
        validFileTypeNames += ` or (*.${fileTypes[i].toLowerCase()})`;
      } else {
        validFileTypeNames += `, (*.${fileTypes[i].toLowerCase()})`;
      }
    } else {
      validFileTypeNames = `(*.${fileTypes[i].toLowerCase()})`;
    }

    if (FILE_TYPES[`${fileTypes[i]}`]) {
      validFileTypesArray.push(FILE_TYPES[`${fileTypes[i]}`]);
    }
  }

  return [
    validFileTypesArray,
    validFileTypeNames
  ];
};