import { Fragment } from 'react';

import NTAttachmentsGridLarge from './NTAttachmentsGridLarge';
import NTAttachmentsGridSmall from './NTAttachmentsGridSmall';

const NTAttachmentsGrid = ({ gridData, isLoading, onEditClicked, onDeleteClicked }) => (
  <Fragment>
    <NTAttachmentsGridLarge
      gridData={gridData}
      isLoading={isLoading}
      onEditClicked={onEditClicked}
      onDeleteClicked={onDeleteClicked} />
    <NTAttachmentsGridSmall
      gridData={gridData}
      isLoading={isLoading}
      onEditClicked={onEditClicked}
      onDeleteClicked={onDeleteClicked} />
  </Fragment>
);

export default NTAttachmentsGrid;