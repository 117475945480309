import { Fragment } from 'react';

import useNTRegistrationSponsors from './UseNTRegistrationSponsors';

import NTRegistrationLeftNav from '../components/leftNav/NTRegistrationLeftNav';
import NTRegistrationLoadingModal from '../components/dialogs/NTRegistrationLoadingModal';

import SponsorsAddForm from '../../../components/forms/sponsors/SponsorsAddForm';
import SponsorsGrid from '../../../components/grids/sponsors/SponsorsGrid';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

import { formatDate } from '../../../../common/utils/DateFunctions';

import style from '../NTRegistration.module.css';

const NTRegistrationSponsors = () => {
  const {
    isLoading,
    gridData,
    state,
    onContinueClicked,
    onBackClicked,
    onSubmitSponsorsAddForm,
    onEditSponsorClicked,
    onDeleteSponsorClicked,
    onDeletePopupConfirmClicked,
    onPopupCloseClicked,
    updatePageHasUnsavedChanges,
    onNTRegistrationError
  } = useNTRegistrationSponsors();

  return (
    <Fragment>
      <NTRegistrationLeftNav viewTitle={'Sponsors'}>
        <SponsorsAddForm
          onSubmitFormCallback={onSubmitSponsorsAddForm}
          onIsDirtyChanged={updatePageHasUnsavedChanges}
          onErrorCaught={onNTRegistrationError} />
        <SponsorsGrid
          gridData={gridData}
          isLoading={isLoading}
          onEditClicked={onEditSponsorClicked}
          onDeleteClicked={onDeleteSponsorClicked} />
        <div className={['row', style.PageNavigationButtons].join(' ')}>
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>&nbsp;
            <PrimaryButton type="button" onClick={onContinueClicked}>Continue</PrimaryButton>
          </div>
        </div>
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={'Delete Sponsor?'}
          displayPopUp={state.displayDeletePopup === true}
          onModalCanceled={onPopupCloseClicked}>
          <div className="row">
            <div className="col-xs-12">
              <p>{state.deleteSponsorObj?.sponsor?.sponsorName || ''}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onDeletePopupConfirmClicked}>Yes, Delete</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onPopupCloseClicked}>Cancel</SecondaryButton>
            </div>
          </div>
        </PopUpModal>
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={'Duplicate Sponsor Error'}
          displayPopUp={state.displayDupSponsorModal === true}
          onModalCanceled={onPopupCloseClicked}>
          <div className="row">
            <div className="col-xs-12">
              <p>Selected dates conflict with an existing sponsor ({state.dupSponsorObj?.sponsor?.sponsorName}, {formatDate(state.dupSponsorObj?.effectiveDate)} - {formatDate(state.dupSponsorObj?.expirationDate)})</p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <SecondaryButton type="button" onClick={onPopupCloseClicked}>Close</SecondaryButton>
            </div>
          </div>
        </PopUpModal>
        <NTRegistrationLoadingModal isLoading={isLoading} />
      </NTRegistrationLeftNav>
    </Fragment>
  );
};

export default NTRegistrationSponsors;