import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ group, onApply, INVITE_STATUS }) => (
  <tr>
    <td>{group.groupName || ''}</td>
    <td>{group.registrationOpenDate ? formatDate(group.registrationOpenDate) : ''}</td>
    <td>{group.registrationCloseDate ? formatDate(group.registrationCloseDate) : ''}</td>
    <td>
      {group.personOrgGroupId > 0
        ? group.status
          ? group.status === INVITE_STATUS || group.status === 'Revisit'
            ? (
              <button className={global.AnchorButton} type='button' onClick={() => onApply(group)}>
                <span className={global.Bold}>Apply {`(${group.status})`}</span>
              </button>
            ) : (
              <span className={global.Bold}>{group.status}</span>
            ) : (
            <span className={global.Bold}>Approved</span>
          ) : (
          <button className={global.AnchorButton} type='button' onClick={() => onApply(group)}>
            <span className={global.Bold}>Apply</span>
          </button>
        )
      }
    </td>
  </tr >
);

const ProfileGroupsGridLarge = ({ data, isLoading, onApply, INVITE_STATUS }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Group Name</th>
        <th>Registration Start Date</th>
        <th>Registration End Date</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="4">Loading...</td></tr>
        : Array.isArray(data) && data.length > 0
          ? data.map((group, i) => <GridRow key={i} group={group} onApply={onApply} INVITE_STATUS={INVITE_STATUS} />)
          : <tr><td colSpan="4">No Groups</td></tr>
      }
    </tbody>
  </table>
);

export default ProfileGroupsGridLarge;