import { useNavigate } from "../../../../../common/wrappers/ReactRouterDom";

import useNTRegistrationData from "../../../../state/nationalTeamRegistration/UseNTRegistrationData";
import useNTRegistrationLeftNavData from "../../../../state/nationalTeamRegistration/nationalTeamRegistrationLeftNav/UseNTRegistrationLeftNavData";

const useNTRegistrationLeftNav = () => {
  const navigate = useNavigate();
  const { pageHasUnsavedChanges, onNavigateWithoutSaving } = useNTRegistrationData();
  const { nationalTeamRegistrationLeftNavState } = useNTRegistrationLeftNavData();

  const onNavItemClicked = (navigatePath) => {
    if (pageHasUnsavedChanges === true) {
      onNavigateWithoutSaving(navigatePath);
    } else {
      navigate(navigatePath);
    }
  };

  return {
    nationalTeamRegistrationLeftNavState,
    onNavItemClicked
  };
};

export default useNTRegistrationLeftNav;