import { useEffect } from 'react';

import validate from './SponsorsAddFormValidation';

import useSponsorData from '../../../state/sponsor/UseSponsorData';

import { BLANK_DATE_STRING, DEFAULT_ID } from '../../../../common/utils/Constants';
import useForm from "../../../../common/utils/UseForm";

const INITIAL_FORM_STATE = {
  sponsorId: DEFAULT_ID,
  sponsorName: '',
  effectiveDate: BLANK_DATE_STRING,
  expirationDate: BLANK_DATE_STRING
};

const useSponsorsAddForm = (onSubmitFormCallback, onIsDirtyChanged, onErrorCaught) => {
  const { sponsorState } = useSponsorData();
  const { formState, errorState, isDirty, isSubmitting, setFormData, handleSubmit, updateFormState, onValueTextPairChanged,
    setErrors, resetForm
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  useEffect(() => {
    try { // reset form to initial state after add
      if (isDirty === false && isSubmitting === false) {
        setFormData(INITIAL_FORM_STATE);
      }

      if (onIsDirtyChanged) {
        onIsDirtyChanged(isDirty);
      }
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : setErrors({ formError: e?.message });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, isSubmitting]);

  return {
    sponsorOptions: sponsorState.dataAsOptionsWithNames || [],
    isDirty,
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onValueTextPairChanged,
    resetForm
  };
};

export default useSponsorsAddForm;