import { Fragment } from 'react';

import useNationalTeamContacts from './UseNationalTeamContacts';

import NTContactsGrid from '../../../components/grids/nationalTeamContacts/NTContactsGrid';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

import { LOADING_MSG } from '../../../../common/utils/Constants';

const NationalTeamContactsWrite = () => {
  const {
    isLoading,
    gridData,
    state,
    // onAddAddressClicked,
    onAddContactClicked,
    onEditContactClicked,
    // onEditAddressClicked,
    onDeleteContactClicked,
    onDeleteAddressClicked,
    onDeleteContactModalConfirmClicked,
    onDeleteAddressModalConfirmClicked,
    onDeleteModalCancelClicked
  } = useNationalTeamContacts();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>National Team Contact Info</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton onClick={onAddContactClicked}>Add Contact</ActionIntraPageButton>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <NTContactsGrid
            gridData={gridData}
            isLoading={isLoading}
            // onAddAddressClicked={onAddAddressClicked}
            onEditClicked={onEditContactClicked}
            // onEditAddressClicked={onEditAddressClicked}
            onDeleteClicked={onDeleteContactClicked}
            onDeleteAddressClicked={onDeleteAddressClicked} />
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Delete Contact?'}
        displayPopUp={state.displayDeleteContactModal === true}
        onModalCanceled={onDeleteModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>{state.deleteContactObj.name} ({state.deleteContactObj.relationship})</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeleteContactModalConfirmClicked}>Yes, Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onDeleteModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Delete Contact Address?'}
        displayPopUp={state.displayDeleteAddressModal === true}
        onModalCanceled={onDeleteModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>{state.deleteAddressObj.address}, {state.deleteAddressObj.city}, {state.deleteAddressObj.countryCode || state.deleteAddressObj.stateCode} {state.deleteAddressObj.postalCode} ({state.deleteAddressObj.addressTypeNames})</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeleteAddressModalConfirmClicked}>Yes, Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onDeleteModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default NationalTeamContactsWrite;