import { useEffect } from 'react';

import validate from './SponsorsEditFormValidation';

import useForm from "../../../../common/utils/UseForm";
import { formatDate } from '../../../../common/utils/DateFunctions';
import { BLANK_DATE_STRING } from '../../../../common/utils/Constants';

const INITIAL_FORM_STATE = {
  effectiveDate: BLANK_DATE_STRING,
  expirationDate: BLANK_DATE_STRING
};

const useSponsorsEditForm = (sponsorObj, onSubmitFormCallback, onIsDirtyChanged, onErrorCaught) => {
  const { isDirty, formState, errorState, setFormData, handleSubmit, updateFormState, setErrors
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  useEffect(() => {
    try {
      if (sponsorObj) {
        setFormData({
          ...INITIAL_FORM_STATE,
          effectiveDate: sponsorObj.effectiveDate ? formatDate(sponsorObj.effectiveDate) : BLANK_DATE_STRING,
          expirationDate: sponsorObj.expirationDate ? formatDate(sponsorObj.expirationDate) : BLANK_DATE_STRING
        });
      }
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : setErrors({ formError: e?.message });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sponsorObj]);

  useEffect(() => {
    try {
      if (onIsDirtyChanged) {
        onIsDirtyChanged(isDirty);
      }
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : setErrors({ formError: e?.message });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  return {
    isDirty,
    formState,
    errorState,
    handleSubmit,
    updateFormState
  };
};

export default useSponsorsEditForm;