import { Fragment } from 'react';

import useNTRegistrationFrequentFlyer from './UseNTRegistrationFrequentFlyer';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

import NTRegistrationLeftNav from '../components/leftNav/NTRegistrationLeftNav';
import NTRegistrationLoadingModal from '../components/dialogs/NTRegistrationLoadingModal';
import FrequentFlyerFormGrid from '../../../components/grids/frequentFlyerForm/FrequentFlyerFormGrid';

import style from '../NTRegistration.module.css';

const NTRegistrationFrequentFlyer = () => {
  const {
    isLoading,
    personId,
    gridData,
    onSubmitFrequentFlyerForm,
    onContinueClicked,
    onBackClicked,
    onNTRegistrationError,
    updatePageHasUnsavedChanges
  } = useNTRegistrationFrequentFlyer();

  return (
    <Fragment>
      <NTRegistrationLeftNav viewTitle={'Frequent Flyer'}>
        <FrequentFlyerFormGrid
          gridData={gridData}
          personId={personId}
          onSubmitFormCallback={onSubmitFrequentFlyerForm}
          onIsDirtyChanged={updatePageHasUnsavedChanges}
          onErrorCaught={onNTRegistrationError} />
        <div className={['row', style.PageNavigationButtons].join(' ')}>
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>&nbsp;
            <PrimaryButton type="button" onClick={onContinueClicked}>Continue</PrimaryButton>
          </div>
        </div>
        <NTRegistrationLoadingModal isLoading={isLoading} />
      </NTRegistrationLeftNav>
    </Fragment>
  );
};

export default NTRegistrationFrequentFlyer;