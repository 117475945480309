import { useEffect } from 'react';

import validate from './SocialMediaEditFormValidation';

import useForm from "../../../../common/utils/UseForm";

const INITIAL_FORM_STATE = {
  socialHandle: ''
};

const useSocialMediaEditForm = (socialMediaObj, onSubmitFormCallback, onIsDirtyChanged, onErrorCaught) => {
  const { formState, errorState, isDirty, setFormData, handleSubmit, updateFormState, setErrors
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  useEffect(() => {
    try {
      if (socialMediaObj) {
        setFormData({
          ...INITIAL_FORM_STATE,
          socialHandle: socialMediaObj.socialHandle
        });
      }
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : setErrors({ formError: e?.message });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialMediaObj]);

  useEffect(() => {
    try {
      if (onIsDirtyChanged) {
        onIsDirtyChanged(isDirty);
      }
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : setErrors({ formError: e?.message });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  return {
    isDirty,
    formState,
    errorState,
    handleSubmit,
    updateFormState
  };
};

export default useSocialMediaEditForm;