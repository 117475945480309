import { useEffect } from "react";
import { useNavigate } from "../../../../../common/wrappers/ReactRouterDom";

import useNTRegistrationData from "../../../../state/nationalTeamRegistration/UseNTRegistrationData";

import useNavRoutes from "../../../../../common/state/security/UseNavRoutes";
import useSecurityData from "../../../../../common/state/security/UseSecurityData";
import useErrorLoggerData from "../../../../../common/state/errorLogger/UseErrorLoggerData";

import { getErrorObjForLog } from "../../../../../common/utils/ErrorUtils";

const useNTRegistrationLoadingModal = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { userInfo } = useSecurityData();
  const { nationalTeamRegistrationState, nationalTeamRegistrationPageState, resetNationalTeamRegistrationState,
    resetNationalTeamRegistrationPageState, onCloseNavigateWarningPopup
  } = useNTRegistrationData();
  const { postErrorLog } = useErrorLoggerData();

  const onReloadClicked = (event) => {
    event?.preventDefault();

    const homeRoute = navRoutes.NT_REGISTRATION_ENTRY?.route;
    if (homeRoute && homeRoute !== window.location.pathname) {
      resetNationalTeamRegistrationState();

      navigate(homeRoute);
    } else {
      window.location.reload();
    }
  };

  const onContinueWithoutSavingClicked = (event) => {
    event?.preventDefault();

    resetNationalTeamRegistrationPageState();
    if (nationalTeamRegistrationPageState.navigateState) {
      navigate(nationalTeamRegistrationPageState.navigatePath, nationalTeamRegistrationPageState.navigateState);
    } else {
      navigate(nationalTeamRegistrationPageState.navigatePath);
    }
  };

  const onCancelClicked = (event) => {
    event?.preventDefault();

    onCloseNavigateWarningPopup();
  };

  // Because the loading modal appears on every page, page state is reset on every page load
  useEffect(() => {
    if (nationalTeamRegistrationPageState.hasUnsavedChanges === true) {
      resetNationalTeamRegistrationPageState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (nationalTeamRegistrationState.isError === true && nationalTeamRegistrationState.error) {
      postErrorLog(getErrorObjForLog(nationalTeamRegistrationState.error, userInfo.memberId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nationalTeamRegistrationState.isError]);

  return {
    nationalTeamRegistrationState,
    nationalTeamRegistrationPageState,
    onReloadClicked,
    onContinueWithoutSavingClicked,
    onCancelClicked
  };
};

export default useNTRegistrationLoadingModal;