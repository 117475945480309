import { Fragment } from 'react';

import useNTRegistrationLeftNav from './UseNTRegistrationLeftNav';

import NTRegistrationLoadingModal from '../dialogs/NTRegistrationLoadingModal';

import LeftNavItem from '../../../../../common/components/leftNav/LeftNavItem';
import Headings from '../../../../../common/components/headings/Headings';

import leftNavStyle from '../../../../../common/components/leftNav/LeftNav.module.css';
import style from '../../NTRegistration.module.css';

const LeftNavigationTitle = ({ objData }) => (
  <div className={style.LeftNavHeaders}>
    <Headings.H5>Member Name:</Headings.H5>
    <Headings.H6>{objData.memberName || ''}</Headings.H6>
    <Headings.H5>Group Name:</Headings.H5>
    <Headings.H6>{objData.nationalTeamGroupName || ''}</Headings.H6>
  </div>
);

const NTRegistrationLeftNav = ({ viewTitle, children }) => {
  const {
    nationalTeamRegistrationLeftNavState,
    onNavItemClicked
  } = useNTRegistrationLeftNav();

  return (
    <Fragment>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <Headings.H2 excludeBar={true} className={style.CenterAlign}>National Team Registration</Headings.H2>
          <hr className="usas-bar-turmeric" />
        </div>
      </div>
      {nationalTeamRegistrationLeftNavState.isLoaded === true ? (
        <div className={['row', style.LeftNavContainer].join(' ')}>
          <div className="col-xs-12 col-sm-3">
            <ul tabIndex="0" className={leftNavStyle.LeftNav}>
              <li><LeftNavigationTitle objData={nationalTeamRegistrationLeftNavState.objData || {}} /></li>
              {nationalTeamRegistrationLeftNavState.arrayData.map((item, i) => <LeftNavItem key={i} item={item} onClick={() => onNavItemClicked(item.path)} />)}
            </ul>
            <div className="row usas-extra-top-margin usas-extra-bottom-margin"></div>
          </div>
          <div className={['col-xs-12', 'col-sm-9', style.LeftNavPageContent].join(' ')}>
            {viewTitle &&
              <div className='row usas-extra-top-margin'>
                <div className='col-xs-12'>
                  <Headings.H3 excludeBar={true}>{viewTitle}</Headings.H3>
                </div>
              </div>
            }
            {children}
          </div>
        </div>
      ) : <NTRegistrationLoadingModal isLoading={true} />}
    </Fragment>
  );
};

export default NTRegistrationLeftNav;