import Textarea from '../../../../common/components/inputs/Textarea';

import global from '../../../../common/components/GlobalStyle.module.css';
import style from './FunFactsFormGrid.module.css';

const GridRow = ({ funFact, onUpdateFormGridState }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span>{funFact.name || <span>&nbsp;</span>}</span>&nbsp;
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-12', global.SmallTableRowData].join(' ')}>
          <Textarea
            className={funFact.value ? style.GridTextarea : [style.GridTextarea, style.Blank].join(' ')}
            label={'Description'}
            name={`value${funFact.id}`}
            value={funFact.value}
            onChange={(newValue) => { onUpdateFormGridState(funFact.id, newValue) }} />
        </div>
        <div className={['col-xs-12', global.SmallTableRowData].join(' ')}>
          {funFact.hasValue === true &&
            <button
              className={style.GridActionButton}
              type="button"
              onClick={() => onUpdateFormGridState(funFact.id, '')}>
              Clear
            </button>
          }
        </div>
      </div>
    </div>
  </div>
);

const FunFactsFormGridSmall = ({ formGridState, onUpdateFormGridState }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {Array.isArray(formGridState) && formGridState.length > 0
      ? formGridState.map((funFact, i) => <GridRow key={i} funFact={funFact} onUpdateFormGridState={onUpdateFormGridState} />)
      : (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={[global.SmallTableRowInfo, global.Bold].join(' ')}>
            &nbsp;&nbsp;No Fun Facts Info
          </div>
        </div>
      )
    }
  </div>
);

export default FunFactsFormGridSmall;