import { Fragment } from 'react';

import useNTRegistrationSupportStaff from './UseNTRegistrationSupportStaff';

import NTRegistrationLeftNav from '../components/leftNav/NTRegistrationLeftNav';
import NTRegistrationLoadingModal from '../components/dialogs/NTRegistrationLoadingModal';
import NTSupportStaffGrid from '../../../components/grids/nationalTeamSupportStaff/NTSupportStaffGrid';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

import { formatPhoneNumber } from '../../../../common/utils/validation';
import { formatDate } from '../../../../common/utils/DateFunctions';

import style from '../NTRegistration.module.css';

const NTRegistrationSupportStaff = () => {
  const {
    isLoading,
    gridData,
    state,
    onContinueClicked,
    onBackClicked,
    onAddSupportStaffClicked,
    onAddSupportStaffRoleClicked,
    onEditSupportStaffClicked,
    onEditSupportStaffRoleClicked,
    onDeleteSupportStaffClicked,
    onDeleteSupportStaffRoleClicked,
    onDeleteStaffPopupConfirmClicked,
    onDeleteStaffRolePopupConfirmClicked,
    onDeletePopupCancelClicked
  } = useNTRegistrationSupportStaff();

  return (
    <Fragment>
      <NTRegistrationLeftNav viewTitle={'Support Staff'}>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <ActionIntraPageButton onClick={onAddSupportStaffClicked}>Add Support Staff</ActionIntraPageButton>
          </div>
        </div>
        <NTSupportStaffGrid
          gridData={gridData}
          isLoading={isLoading}
          onAddRoleClicked={onAddSupportStaffRoleClicked}
          onEditClicked={onEditSupportStaffClicked}
          onEditRoleClicked={onEditSupportStaffRoleClicked}
          onDeleteClicked={onDeleteSupportStaffClicked}
          onDeleteRoleClicked={onDeleteSupportStaffRoleClicked} />
        <div className={['row', style.PageNavigationButtons].join(' ')}>
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>&nbsp;
            <PrimaryButton type="button" onClick={onContinueClicked}>Continue</PrimaryButton>
          </div>
        </div>
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={'Delete Support Staff?'}
          displayPopUp={state.displayDeleteStaffPopup === true}
          onModalCanceled={onDeletePopupCancelClicked}>
          <div className="row">
            <div className="col-xs-12">
              <p>{state.deleteSupportStaffObj.firstName} {state.deleteSupportStaffObj.lastName} (Email: {state.deleteSupportStaffObj.email}, Phone Number: {formatPhoneNumber(state.deleteSupportStaffObj.phoneNumber)})</p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onDeleteStaffPopupConfirmClicked}>Yes, Delete</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onDeletePopupCancelClicked}>Cancel</SecondaryButton>
            </div>
          </div>
        </PopUpModal>
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={'Delete Support Staff Role?'}
          displayPopUp={state.displayDeleteStaffRolePopup === true}
          onModalCanceled={onDeletePopupCancelClicked}>
          <div className="row">
            <div className="col-xs-12">
              <p>{state.deleteSupportStaffRoleObj.supportStaffType?.typeName} ({formatDate(state.deleteSupportStaffRoleObj.effectiveDate)} - {formatDate(state.deleteSupportStaffRoleObj.expirationDate)})</p>
            </div>
            {state.isLastRole === true &&
              <div className="col-xs-12">
                <p><b>Removing {state.deleteSupportStaffObj.firstName} {state.deleteSupportStaffObj.lastName}&apos;s last role will remove them from your support staff.</b></p>
              </div>
            }
          </div>
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onDeleteStaffRolePopupConfirmClicked}>Yes, Delete</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onDeletePopupCancelClicked}>Cancel</SecondaryButton>
            </div>
          </div>
        </PopUpModal>
        <NTRegistrationLoadingModal isLoading={isLoading} />
      </NTRegistrationLeftNav>
    </Fragment>
  );
};

export default NTRegistrationSupportStaff;