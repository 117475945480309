import { useContext, useState } from 'react';

import PersonNationalTeamFileTypeData from './PersonNationalTeamFileTypeData';
import { PersonNationalTeamFileTypeStateContext } from './PersonNationalTeamFileTypeContextProvider';

const usePersonNationalTeamFileTypeData = () => {
  const [personNationalTeamFileTypeState, setPersonNationalTeamFileTypeState] = useContext(PersonNationalTeamFileTypeStateContext);
  const [nationalTeamAttachmentTemplateState, setNationalTeamAttachmentTemplateState] = useState(PersonNationalTeamFileTypeData.INITIAL_NATIONAL_TEAM_ATTACHMENT_TEMPLATE_STATE);

  const getPersonNationalTeamAttachmentFileTypes = () => {
    PersonNationalTeamFileTypeData.getPersonNationalTeamAttachmentFileTypes(personNationalTeamFileTypeState, setPersonNationalTeamFileTypeState);
  };

  const getNTAttachmentFileTemplate = (typeName) => {
    return PersonNationalTeamFileTypeData.getNTAttachmentFileTemplateData(typeName, nationalTeamAttachmentTemplateState, setNationalTeamAttachmentTemplateState);
  };

  return {
    personNationalTeamFileTypeState,
    setPersonNationalTeamFileTypeState,
    nationalTeamAttachmentTemplateState,
    getPersonNationalTeamAttachmentFileTypes,
    getNTAttachmentFileTemplate
  };
};


export default usePersonNationalTeamFileTypeData;