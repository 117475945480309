import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../../common/utils/HttpHelper";

const postNTGroupMemberRegistrationData = (personNationalTeamGroupId, state, setState) => {
  const api = initApi(API_NAMES.PERSON, state, setState,);

  const personNationalTeamGroupIdForUrl = personNationalTeamGroupId ? encodeURIComponent(personNationalTeamGroupId) : 0;
  const url = `/PersonNationalTeamGroup/${personNationalTeamGroupIdForUrl}/Registration`;

  return api.executeObject ? api.executeObject(url, 'POST') : null;
};

const getNTGroupMemberRegistrationData = (personId, state, setState) => {
  const api = initApi(API_NAMES.PERSON, state, setState);

  const personIdForUrl = personId ? encodeURIComponent(personId) : 0;
  const url = `/PersonNationalTeamGroup/Person/${personIdForUrl}`;

  return api.executeObject ? api.executeObject(url, 'GET') : null;
};

const INITIAL_NATIONAL_TEAM_REGISTRATION_STATE = {
  isError: false,
  error: {},
  errorTitle: '',
  errorMessage: ''
};

const INITIAL_NATIONAL_TEAM_REGISTRATION_PAGE_STATE = {
  hasUnsavedChanges: false,
  displayNavigateWarningPopup: false,
  navigateWarningPopupTitle: '',
  navigatePath: '',
  navigateState: undefined
};

const INITIAL_GROUP_MEMBER_REGISTRATION_STATE = {
  ...BASIC_INITIAL_STATE
};

const NTRegistrationData = {
  INITIAL_NATIONAL_TEAM_REGISTRATION_STATE,
  INITIAL_NATIONAL_TEAM_REGISTRATION_PAGE_STATE,
  INITIAL_GROUP_MEMBER_REGISTRATION_STATE,
  postNTGroupMemberRegistrationData,
  getNTGroupMemberRegistrationData
};

export default NTRegistrationData;