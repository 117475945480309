import { Fragment } from 'react';

import useNTRegistrationContactsDetail from './UseNTRegistrationContactsDetail';

import NTRegistrationLeftNav from '../components/leftNav/NTRegistrationLeftNav';
import NTRegistrationLoadingModal from '../components/dialogs/NTRegistrationLoadingModal';
// import NTContactForm from '../../../components/forms/nationalTeamContacts/NTContactForm';
import NTContactSPForm from '../../../components/forms/nationalTeamContactSP/NTContactSPForm';

// import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
// import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
// import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

const NTRegistrationContactsDetail = () => {
  const {
    isLoading,
    state,
    onCancelClicked,
    // onAddAddressClicked,
    onSubmitContactForm,
    updatePageHasUnsavedChanges,
    onNTRegistrationError
  } = useNTRegistrationContactsDetail();

  return (
    <Fragment>
      <NTRegistrationLeftNav viewTitle={state.contactObj ? 'Edit Contact' : 'Add Contact'}>
        {/* <NTContactForm
          contactObj={state.contactObj}
          contactPhonesArray={state.contactObj?.personNationalTeamRegistrationContactPhone}
          submitButtonText={state.contactObj ? 'Save' : 'Add Contact'}
          isRelationshipTypeReadonly={state.isSelfContact === true}
          excludeSelfRelationshipType={state.hasOtherSelfContact === true}
          onSubmitFormCallback={onSubmitContactForm}
          onSecondaryButtonClicked={onCancelClicked}
          onIsDirtyChanged={updatePageHasUnsavedChanges}
          onErrorCaught={onNTRegistrationError} /> */}
        <NTContactSPForm
          contactObj={state.contactObj}
          submitButtonText={state.contactObj ? 'Save' : 'Add Contact'}
          personNTFieldsKey='personNationalTeamRegistration'
          isRelationshipTypeReadonly={state.isSelfContact === true}
          excludeSelfRelationshipType={state.hasOtherSelfContact === true}
          onSubmitFormCallback={onSubmitContactForm}
          onSecondaryButtonClicked={onCancelClicked}
          onIsDirtyChanged={updatePageHasUnsavedChanges}
          onErrorCaught={onNTRegistrationError} />
        <NTRegistrationLoadingModal isLoading={isLoading} />
        {/* <PopUpModal
          widthPct={50}
          maxWidth={600}
          title={'New Contact Added'}
          displayPopUp={state.displayAddAddressPopup === true}
          onModalCanceled={onCancelClicked}>
          <div className="row">
            <div className="col-xs-12">
              <p>Would you like to add an address for {state.newContactObj?.firstName} {state.newContactObj?.lastName}?</p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onAddAddressClicked}>Yes, Add Address</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onCancelClicked}>No</SecondaryButton>
            </div>
          </div>
        </PopUpModal> */}
      </NTRegistrationLeftNav>
    </Fragment>
  );
};

export default NTRegistrationContactsDetail;