import usePersonBioInfoForm from "./UsePersonBioInfoForm";

import FeetInchesInput from "../../inputs/FeetInchesInput";

import Input from "../../../../common/components/inputs/Input";
import Headings from "../../../../common/components/headings/Headings";
import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";
import StatesCombobox from "../../../../common/components/comboboxes/statesCombobox/StatesCombobox";
import CountryCombobox from "../../../../common/components/comboboxes/countryCombobox/CountryCombobox";

import global from '../../../../common/components/GlobalStyle.module.css';

const PersonBioInfoForm = ({ personBiosArray, submitButtonText = 'Save', secondaryButtonText = 'Cancel',
  onSubmitFormCallback, onSecondaryButtonClicked = undefined, onIsDirtyChanged = undefined, onErrorCaught = undefined }) => {
  const {
    isDirty,
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onValueTextPairChanged,
    onBirthplaceCountryChanged,
    resetForm,
    isBirthCountryUSA,
    isBirthCountryMexico,
    isBirthCountryCanada
  } = usePersonBioInfoForm(personBiosArray, onSubmitFormCallback, onIsDirtyChanged, onErrorCaught);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <Headings.H5>Body</Headings.H5>
        </div>
        <div className='col-xs-12 col-sm-6 col-md-4'>
          <FeetInchesInput
            label="Height (enter value in inches)"
            name="height"
            value={formState.height}
            error={errorState.height}
            message={errorState.height}
            onChange={(value) => { updateFormState('height', value); }} />
        </div>
        <div className='col-xs-12 col-sm-6 col-md-4'>
          <Input
            label="Weight (lbs)"
            name="weight"
            type="number"
            min="0"
            value={formState.weight}
            error={errorState.weight}
            message={errorState.weight}
            onChange={(value) => { updateFormState('weight', value); }} />
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <Headings.H5>Residence</Headings.H5>
        </div>
        <div className='col-xs-12 col-sm-6 col-md-4'>
          <Input
            label="Residence"
            name="residence"
            value={formState.residence}
            error={errorState.residence}
            message={errorState.residence}
            onChange={(value) => { updateFormState('residence', value); }} />
        </div>
        <div className='col-xs-12 col-sm-6 col-md-4'>
          <StatesCombobox
            label="Residence State"
            name="residenceState"
            value={formState.residenceState}
            valueToMatch={formState.residenceStateName}
            error={errorState.residenceState}
            message={errorState.residenceState}
            isUSA={true}
            onChange={(newValue, newValueLabel, e) => {
              onValueTextPairChanged(newValue, 'residenceState', newValueLabel, 'residenceStateName', e);
            }} />
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <Headings.H5>Birthplace</Headings.H5>
        </div>
        <div className='col-xs-12 col-sm-6 col-md-4'>
          <Input
            label="Birthplace"
            name="birthplace"
            value={formState.birthplace}
            error={errorState.birthplace}
            message={errorState.birthplace}
            onChange={(value) => { updateFormState('birthplace', value); }} />
        </div>
        <div className='col-xs-12 col-sm-6 col-md-4'>
          <CountryCombobox
            label="Birthplace Country"
            name="birthplaceCountry"
            value={formState.birthplaceCountry}
            valueToMatch={formState.birthplaceCountryName}
            error={errorState.birthplaceCountry}
            message={errorState.birthplaceCountry}
            onChange={(newValue, newValueLabel, e) => {
              onBirthplaceCountryChanged(newValue, newValueLabel, e);
            }} />
        </div>
        {(isBirthCountryUSA === true || isBirthCountryMexico === true || isBirthCountryCanada === true) &&
          <div className='col-xs-12 col-sm-6 col-md-4'>
            <StatesCombobox
              label="Birthplace State"
              name="birthplaceState"
              value={formState.birthplaceState}
              valueToMatch={formState.birthplaceStateName}
              error={errorState.birthplaceState}
              message={errorState.birthplaceState}
              isUSA={isBirthCountryUSA === true}
              isMexico={isBirthCountryMexico === true}
              isCanada={isBirthCountryCanada === true}
              onChange={(newValue, newValueLabel, e) => {
                onValueTextPairChanged(newValue, 'birthplaceState', newValueLabel, 'birthplaceStateName', e);
              }} />
          </div>
        }
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <Headings.H5>Hometown</Headings.H5>
        </div>
        <div className='col-xs-12 col-sm-6 col-md-4'>
          <Input
            label="Hometown"
            name="hometown"
            value={formState.hometown}
            error={errorState.hometown}
            message={errorState.hometown}
            onChange={(value) => { updateFormState('hometown', value); }} />
        </div>
        <div className='col-xs-12 col-sm-6 col-md-4'>
          <StatesCombobox
            label="Hometown State"
            name="hometownState"
            value={formState.hometownState}
            valueToMatch={formState.hometownStateName}
            error={errorState.hometownState}
            message={errorState.hometownState}
            isUSA={true}
            onChange={(newValue, newValueLabel, e) => {
              onValueTextPairChanged(newValue, 'hometownState', newValueLabel, 'hometownStateName', e);
            }} />
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <Headings.H5>High School</Headings.H5>
        </div>
        <div className='col-xs-12 col-sm-6 col-md-4'>
          <Input
            label="High School Name"
            name="highSchool"
            value={formState.highSchool}
            error={errorState.highSchool}
            message={errorState.highSchool}
            onChange={(value) => { updateFormState('highSchool', value); }} />
        </div>
        <div className='col-xs-12 col-sm-6 col-md-4'>
          <Input
            label="High School Graduation (Year)"
            name="highSchoolGraduationYear"
            value={formState.highSchoolGraduationYear}
            error={errorState.highSchoolGraduationYear}
            message={errorState.highSchoolGraduationYear}
            onChange={(value) => { updateFormState('highSchoolGraduationYear', value); }} />
        </div>
        <div className='col-xs-12 col-sm-6 col-md-4'>
          <Input
            label="High School City"
            name="highSchoolCity"
            value={formState.highSchoolCity}
            error={errorState.highSchoolCity}
            message={errorState.highSchoolCity}
            onChange={(value) => { updateFormState('highSchoolCity', value); }} />
        </div>
        <div className='col-xs-12 col-sm-6 col-md-4'>
          <StatesCombobox
            label="High School State"
            name="highSchoolState"
            value={formState.highSchoolState}
            valueToMatch={formState.highSchoolStateName}
            error={errorState.highSchoolState}
            message={errorState.highSchoolState}
            isUSA={true}
            onChange={(newValue, newValueLabel, e) => {
              onValueTextPairChanged(newValue, 'highSchoolState', newValueLabel, 'highSchoolStateName', e);
            }} />
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <Headings.H5>College</Headings.H5>
        </div>
        <div className='col-xs-12 col-sm-6 col-md-4'>
          <Input
            label="College Name"
            name="college"
            value={formState.college}
            error={errorState.college}
            message={errorState.college}
            onChange={(value) => { updateFormState('college', value); }} />
        </div>
        <div className='col-xs-12 col-sm-6 col-md-4'>
          <Input
            label="College Graduation (Year)"
            name="collegeGraduationYear"
            value={formState.collegeGraduationYear}
            error={errorState.collegeGraduationYear}
            message={errorState.collegeGraduationYear}
            onChange={(value) => { updateFormState('collegeGraduationYear', value); }} />
        </div>
      </div>
      {errorState.errorOnSubmitAction &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
          </div>
        </div>
      }
      {errorState.formError &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{errorState.formError || ''}</p>
          </div>
        </div>
      }
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="submit" isDisabled={isDirty === false}>{submitButtonText}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={(e) => onSecondaryButtonClicked ? onSecondaryButtonClicked(e) : resetForm(e)}>{secondaryButtonText}</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default PersonBioInfoForm;