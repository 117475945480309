import { useEffect } from 'react';

import validate from './OfferingInstanceEditFormValidation';

import useEnvironmentVariableData from '../../../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import useForm from '../../../../../../common/utils/UseForm';
import { BLANK_DATE_STRING } from '../../../../../../common/utils/Constants';
import { formatDate } from '../../../../../../common/utils/DateFunctions';

const INITIAL_FORM_STATE = {
  isClubOffering: false,
  defaultRegStartDate: BLANK_DATE_STRING,
  defaultRegEndDate: BLANK_DATE_STRING,
  defaultEffectiveDate: BLANK_DATE_STRING,
  defaultExpirationDate: BLANK_DATE_STRING,
  feeAmount: 0,
  renewalFeeAmount: null
};

const useOfferingInstanceEditForm = (onSubmitFormCallback, reportPeriodObj, offeringInstanceObj) => {
  const { USAS_ORG_UNIT_ID } = useEnvironmentVariableData();
  const { formState, errorState, setFormState, handleSubmit, updateFormState,
  } = useForm(INITIAL_FORM_STATE, formatBeforeSubmit, validate);

  useEffect(() => {
    if (offeringInstanceObj) {
      setFormState({
        ...formState,
        isClubOffering: offeringInstanceObj.isClubRegistrationOffering === true,
        defaultRegStartDate: formatDate(offeringInstanceObj.registrationStartDate),
        defaultRegEndDate: formatDate(offeringInstanceObj.registrationEndDate),
        defaultEffectiveDate: formatDate(offeringInstanceObj.effectiveDate),
        defaultExpirationDate: formatDate(offeringInstanceObj.expirationDate),
        feeAmount: offeringInstanceObj.feeAmount,
        renewalFeeAmount: offeringInstanceObj.isClubRegistrationOffering === true ? offeringInstanceObj.renewalFeeAmount : null
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offeringInstanceObj]);

  function formatBeforeSubmit(formState) {
    if (USAS_ORG_UNIT_ID) {
      const submitObj = {
        offeringInstanceId: offeringInstanceObj.offeringInstanceId,
        offeringId: offeringInstanceObj.offeringId,
        maxLscFeeAmount: offeringInstanceObj.maxLscFeeAmount,
        reportPeriodId: reportPeriodObj.reportPeriodId,
        orgUnitId: USAS_ORG_UNIT_ID,

        defaultRegStartDate: formState.defaultRegStartDate,
        defaultRegEndDate: formState.defaultRegEndDate,
        defaultEffectiveDate: formState.defaultEffectiveDate,
        defaultExpirationDate: formState.defaultExpirationDate,
        feeAmount: formState.feeAmount,
        renewalFeeAmount: formState.isClubOffering === true ? formState.renewalFeeAmount : null
      };

      onSubmitFormCallback(submitObj);
    }
  }

  return {
    formState,
    errorState,

    updateFormState,
    handleSubmit
  };
};

export default useOfferingInstanceEditForm;