import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';
import { API_NAMES, initApi } from '../../../common/utils/HttpApiHelper';

const getPersonNationalTeamData = (personId, state, setState) => {
  const api = initApi(API_NAMES.PERSON, state, setState);

  const personIdForUrl = personId ? encodeURIComponent(personId) : 'Nan';
  const url = `/Person/${personIdForUrl}/nationalteam`;

  return api.executeObject ? api.executeObject(url, 'GET') : null;
};

const getPersonNationalTeamAdvancedData = (personId, state, setState) => {
  const api = initApi(API_NAMES.PERSON, state, setState);

  const personIdForUrl = personId ? encodeURIComponent(personId) : 'Nan';
  const url = `/Person/${personIdForUrl}/nationalteam/advancedInfo`;

  return api.executeObject ? api.executeObject(url, 'GET') : null;
};

const putPersonNationalTeamData = (personId, personNTObj, state, setState) => {
  if (personNTObj) {
    const api = initApi(API_NAMES.PERSON, state, setState);

    const personIdForUrl = personId ? encodeURIComponent(personId) : 'Nan';
    const url = `/Person/${personIdForUrl}/nationalteam`;

    return api.executeObject ? api.executeObject(url, 'PUT', personNTObj) : null;
  }
};

const putPersonNationalTeamAdvancedData = (personId, personNTAdvancedObj, state, setState) => {
  if (personNTAdvancedObj) {
    const api = initApi(API_NAMES.PERSON, state, setState);

    const personIdForUrl = personId ? encodeURIComponent(personId) : 'Nan';
    const url = `/Person/${personIdForUrl}/nationalteam/advancedInfo`;

    return api.executeObject ? api.executeObject(url, 'PUT', personNTAdvancedObj) : null;
  }
};

const INITIAL_PERSON_NATIONAL_TEAM_STATE = {
  ...BASIC_INITIAL_STATE
};

const INITIAL_PERSON_NATIONAL_TEAM_ADVANCED_STATE = {
  ...BASIC_INITIAL_STATE
};

const PersonNationalTeamData = {
  INITIAL_PERSON_NATIONAL_TEAM_STATE,
  INITIAL_PERSON_NATIONAL_TEAM_ADVANCED_STATE,
  getPersonNationalTeamData,
  getPersonNationalTeamAdvancedData,
  putPersonNationalTeamData,
  putPersonNationalTeamAdvancedData
};

export default PersonNationalTeamData;