import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import useNTAttachmentData from '../../../state/nationalTeamAttachment/UseNTAttachmentData';

import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';
import useNavRoutes from '../../../../common/state/security/UseNavRoutes';

const INITIAL_STATE = {
  displayDeletePopup: false,
  deleteAttachmentObj: {}
};

const useNationalTeamAttachments = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { basePersonState } = useBasePersonData();
  const { nationalTeamAttachmentState, nationalTeamAttachmentDeleteState, getNTAttachmentsByPersonId, deleteNTAttachment
  } = useNTAttachmentData();
  const [state, setState] = useState(INITIAL_STATE);

  const onAddAttachmentClicked = (event) => {
    event?.preventDefault();

    navigate(navRoutes.MEMBER_NT_ATTACHMENTS_DETAIL?.route);
  };

  const onEditAttachmentClicked = (event, attachmentObj) => {
    event?.preventDefault();

    navigate(navRoutes.MEMBER_NT_ATTACHMENTS_DETAIL?.route, { state: { attachmentObj } });
  };

  const onDeleteAttachmentClicked = (event, attachmentObj) => {
    event?.preventDefault();

    setState({
      ...state,
      displayDeletePopup: true,
      deleteAttachmentObj: attachmentObj
    });
  };

  const onDeletePopupConfirmClicked = async (event) => {
    event?.preventDefault();

    const attachmentId = state.deleteAttachmentObj.personNationalAttachmentId;
    const attachmentUrl = state.deleteAttachmentObj.attachmentUrl;

    setState({ ...INITIAL_STATE });

    const deleteNTAttachmentResult = await deleteNTAttachment(attachmentId, attachmentUrl);

    if (deleteNTAttachmentResult) {
      getNTAttachmentsByPersonId(basePersonState.objData.personId);
    }
  };

  const onPopupCancelClicked = (event) => {
    event?.preventDefault();

    setState({ ...INITIAL_STATE });
  };

  useEffect(() => {
    if (nationalTeamAttachmentState.isArrayLoaded === false && basePersonState.isObjLoaded === true) {
      getNTAttachmentsByPersonId(basePersonState.objData.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState]);

  return {
    isSaving: nationalTeamAttachmentDeleteState.isObjLoading,
    isLoading: nationalTeamAttachmentState.isArrayLoading,
    gridData: nationalTeamAttachmentState.arrayData || [],
    state,
    onAddAttachmentClicked,
    onEditAttachmentClicked,
    onDeleteAttachmentClicked,
    onDeletePopupConfirmClicked,
    onPopupCancelClicked
  };
};

export default useNationalTeamAttachments;