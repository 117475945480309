import { Fragment } from 'react';
import { faRotateLeft } from '@fortawesome/free-solid-svg-icons';

import Icon from '../../../../common/components/icons/Icon';
import Input from '../../../../common/components/inputs/Input';
import PlusIcon from '../../../../common/components/icons/PlusIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import AddressTypeMultiselect from '../../multiSelect/AddressTypeMultiselect';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import StatesCombobox from '../../../../common/components/comboboxes/statesCombobox/StatesCombobox';
import CountryCombobox from '../../../../common/components/comboboxes/countryCombobox/CountryCombobox';

import global from '../../../../common/components/GlobalStyle.module.css';
import style from './NTContactSPFormAddresses.module.css';

const DetailTable = ({ contactAddressId, formValues, formErrors, onUpdateFormValue }) => (
  <table className={[global.DetailTable, style.FormTable].join(' ')}>
    <thead>
      <tr>
        <th>Field</th>
        <th>Value</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Address Type*</td>
        <td className={[style.GridMultiSelect, style.ValueCell].join(' ')}>
          <AddressTypeMultiselect
            name={`addressType${contactAddressId}`}
            value={formValues.addressType}
            error={formErrors.addressType}
            message={formErrors.addressType}
            showOnlyNationalTeamTypes={true}
            onChange={(value) => { onUpdateFormValue(contactAddressId, value, 'addressType'); }} />
        </td>
      </tr>
      <tr>
        <td>Address Line 1*</td>
        <td className={style.ValueCell}>
          <Input
            name={`address1${contactAddressId}`}
            value={formValues.address1}
            error={formErrors.address1}
            message={formErrors.address1}
            onChange={(value) => { onUpdateFormValue(contactAddressId, value, 'address1'); }} />
        </td>
      </tr>
      <tr>
        <td>Address Line 2</td>
        <td className={style.ValueCell}>
          <Input
            name={`address2${contactAddressId}`}
            value={formValues.address2}
            error={formErrors.address2}
            message={formErrors.address2}
            onChange={(value) => { onUpdateFormValue(contactAddressId, value, 'address2'); }} />
        </td>
      </tr>
      <tr>
        <td>City*</td>
        <td className={style.ValueCell}>
          <Input
            name={`city${contactAddressId}`}
            value={formValues.city}
            error={formErrors.city}
            message={formErrors.city}
            onChange={(value) => { onUpdateFormValue(contactAddressId, value, 'city'); }} />
        </td>
      </tr>
      <tr>
        <td>Zip Code*</td>
        <td className={style.ValueCell}>
          <Input
            name={`postalCode${contactAddressId}`}
            value={formValues.postalCode}
            error={formErrors.postalCode}
            message={formErrors.postalCode}
            onChange={(value) => { onUpdateFormValue(contactAddressId, value, 'postalCode'); }} />
        </td>
      </tr>
      <tr>
        <td>Country*</td>
        <td className={style.ValueCell}>
          <CountryCombobox
            name={`countryCode${contactAddressId}`}
            valueToMatch={formValues.countryName}
            error={formErrors.countryCode}
            message={formErrors.countryCode}
            onChange={(newValue, newValueLabel) => { onUpdateFormValue(contactAddressId, newValue, 'countryCode', newValueLabel, 'countryName') }} />
        </td>
      </tr>
      {(formValues.isCountryUSA === true || formValues.isCountryMexico === true || formValues.isCountryCanada === true) &&
        <tr>
          <td>State*</td>
          <td className={style.ValueCell}>
            <StatesCombobox
              name="stateCode"
              valueToMatch={formValues.stateName}
              error={formErrors.stateCode}
              message={formErrors.stateCode}
              onChange={(newValue, newValueLabel) => { onUpdateFormValue(contactAddressId, newValue, 'stateCode', newValueLabel, 'stateName'); }}
              isUSA={formValues.isCountryUSA === true}
              isMexico={formValues.isCountryMexico === true}
              isCanada={formValues.isCountryCanada === true} />
          </td>
        </tr>
      }
    </tbody>
  </table>
);

const GridRow = ({ contactAddress, onUpdateFormValue, onDeleteContactAddress, onRevertContactAddress }) => (
  <Fragment>
    <tr>
      <td>{contactAddress.isDeleted === true ? 'Deleted Address' : contactAddress.isAdd === true ? 'New Address' : 'Existing Address'}</td>
      <td className='pull-right'>
        {contactAddress.isDirty === true && contactAddress.isAdd !== true &&
          <button className={global.IconButtonMargin} type="button" onClick={(e) => onRevertContactAddress(e, contactAddress.id)}>
            <Icon tooltip='Revert Changes' iconObj={faRotateLeft} />
          </button>
        }
        {contactAddress.isDeleted !== true &&
          <button className={global.IconButtonMargin} type="button" onClick={(e) => onDeleteContactAddress(e, contactAddress.id)}>
            <DeleteIcon toolTipText='Delete Address' />
          </button>
        }
      </td>
    </tr>
    {contactAddress.isDeleted !== true &&
      <tr className={global.Expanded}>
        <td colSpan="2">
          <DetailTable contactAddressId={contactAddress.id} formValues={contactAddress.values} formErrors={contactAddress.errors} onUpdateFormValue={onUpdateFormValue} />
        </td>
      </tr>
    }
  </Fragment>
);

const NTContactSPFormAddresses = ({ contactAddresses, onUpdateFormValue, onAddContactAddress, onDeleteContactAddress, onRevertContactAddress }) => (
  <table className={[global.UsasTable].join(' ')}>
    <thead>
      <tr>
        <th colSpan="2">Contact Addresses</th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(contactAddresses) && contactAddresses.length > 0
        && contactAddresses.map((contactAddress, i) =>
          <GridRow key={i} contactAddress={contactAddress} onUpdateFormValue={onUpdateFormValue} onDeleteContactAddress={onDeleteContactAddress} onRevertContactAddress={onRevertContactAddress} />)
      }
      <tr><td colSpan="2" style={{ textAlign: 'center', paddingTop: '5px' }}><ActionIntraPageButton onClick={onAddContactAddress}><PlusIcon /> Add Address</ActionIntraPageButton></td></tr>
    </tbody>
  </table>
);

export default NTContactSPFormAddresses;