import { useEffect } from 'react';

import validate from './NTSupportStaffEditFormValidation';

import useForm from '../../../../common/utils/UseForm';
import { DEFAULT_PHONE_NUMBER_AREA_CODE } from '../../../../common/utils/Constants';

const INITIAL_FORM_STATE = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: DEFAULT_PHONE_NUMBER_AREA_CODE
};

const useNTSupportStaffEditForm = (supportStaffObj, onSubmitFormCallback, onIsDirtyChanged, onErrorCaught) => {
  const { isDirty, formState, errorState, handleSubmit, updateFormState, setFormData, setErrors
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  useEffect(() => {
    try {
      if (supportStaffObj) {
        setFormData({
          ...INITIAL_FORM_STATE,
          firstName: supportStaffObj.firstName || '',
          lastName: supportStaffObj.lastName || '',
          email: supportStaffObj.email || '',
          phoneNumber: supportStaffObj.phoneNumber || DEFAULT_PHONE_NUMBER_AREA_CODE
        });
      }
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : setErrors({ formError: e?.message });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportStaffObj]);

  useEffect(() => {
    try {
      if (onIsDirtyChanged) {
        onIsDirtyChanged(isDirty);
      }
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : setErrors({ formError: e?.message });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  return {
    isDirty,
    formState,
    errorState,
    handleSubmit,
    updateFormState
  };
};

export default useNTSupportStaffEditForm;

