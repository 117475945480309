import { Fragment } from 'react';

import useNationalTeamSponsors from './UseNationalTeamSponsors';

import NationalTeamSponsorsGrid from './NationalTeamSponsorsGrid';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

const NationalTeamSponsors = () => {
  const {
    title,
    DEFAULT_ID,
    Constants,
    personNationalTeamState,
    modalState,
    onAddEditSponsor,
    onDeleteSponsor,
    onOpenModalClicked,
    onModalCanceled
  } = useNationalTeamSponsors();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{title}</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <ActionIntraPageButton onClick={(e) => onAddEditSponsor(e, DEFAULT_ID)}>Add Sponsor</ActionIntraPageButton>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <NationalTeamSponsorsGrid gridState={personNationalTeamState} onAddEdit={onAddEditSponsor} onDelete={onOpenModalClicked} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={personNationalTeamState.isObjLoading} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p>{modalState.sponsorName}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeleteSponsor}>Yes, Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
}

export default NationalTeamSponsors;