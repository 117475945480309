import { useEffect, useState } from "react";

import useFrequentFlyerTypeData from "../../../state/frequentFlyerType/UseFrequentFlyerTypeData";

import { compareAlphabetical } from "../../../../common/utils/SortingUtils";

const useFrequentFlyerFormGrid = (gridData, personId, onSubmitFormCallback, onIsDirtyChanged, onErrorCaught) => {
  const { frequentFlyerTypeState } = useFrequentFlyerTypeData();
  const [initialFormGridState, setInitialFormGridState] = useState([]);
  const [formGridState, setFormGridState] = useState([]);
  const [isDirtyState, setIsDirtyState] = useState(false);

  const onSubmitFormGrid = (event) => {
    try {
      event?.preventDefault();

      const frequentFlyerDetails = [];

      for (const frequentFlyerFormObj of formGridState) {
        if (frequentFlyerFormObj.hasValue === true) {
          const existingFrequentFlyerValue = gridData.find((x) => x.frequentFlyerTypeId === frequentFlyerFormObj.id)

          if (existingFrequentFlyerValue) {
            frequentFlyerDetails.push({
              ...existingFrequentFlyerValue,
              frequentFlyerNumber: frequentFlyerFormObj.value.trim()
            })
          } else {
            frequentFlyerDetails.push({
              personId,
              frequentFlyerTypeId: frequentFlyerFormObj.id,
              frequentFlyerNumber: frequentFlyerFormObj.value.trim(),
              frequentFlyerType: undefined
            });
          }
        }
      }

      onSubmitFormCallback(frequentFlyerDetails);
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : () => { throw e };
    }
  };

  const onCancelClicked = (event) => {
    try {
      event?.preventDefault();

      const newFormGridState = JSON.parse(JSON.stringify(initialFormGridState));

      setFormGridState(newFormGridState);
      setIsDirtyState(false);
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : () => { throw e };
    }
  };

  const onUpdateFormGridState = (id, newValue) => {
    try {
      const newFormGridState = [...formGridState];

      for (let i = 0; i < newFormGridState.length; i++) {
        if (newFormGridState[i].id === id) {
          newFormGridState[i].value = newValue;
          newFormGridState[i].hasValue = newValue?.trim() ? true : false;
          newFormGridState[i].isDirty = newValue.trim() !== initialFormGridState[i].value.trim();

          break;
        }
      }

      setFormGridState(newFormGridState);
      setIsDirtyState(getIsDirty(newFormGridState));
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : () => { throw e };
    }
  };

  useEffect(() => {
    try {
      if (Array.isArray(gridData) && frequentFlyerTypeState.isLoaded === true) {
        const newFormGridState = createFormGridState(gridData);
        const newInitialFormGridState = JSON.parse(JSON.stringify(newFormGridState));

        setFormGridState(newFormGridState);
        setInitialFormGridState(newInitialFormGridState);
        setIsDirtyState(false);
      }
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : () => { throw e };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridData, frequentFlyerTypeState.isLoaded]);

  useEffect(() => {
    try {
      if (onIsDirtyChanged) {
        onIsDirtyChanged(isDirtyState);
      }
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : () => { throw e };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirtyState]);

  function createFormGridState(gridData) {
    const newFormGridState = [];

    for (const frequentFlyerOption of frequentFlyerTypeState.dataAsOptionsWithNames) {
      if (frequentFlyerOption.id > 0) {
        const existingFrequentFlyerValue = gridData.find((x) => x.frequentFlyerTypeId === frequentFlyerOption.id);

        newFormGridState.push(createFrequentFlyerFormObj(frequentFlyerOption, existingFrequentFlyerValue));
      }
    }

    newFormGridState.sort((a, b) => compareAlphabetical(a.name, b.name) * -1);
    newFormGridState.sort((a) => a.name.includes('TSA') ? 1 : -1);

    return newFormGridState;
  }

  function createFrequentFlyerFormObj(frequentFlyerOption, existingFrequentFlyerValue) {
    return {
      id: frequentFlyerOption.id,
      name: frequentFlyerOption.name,
      value: existingFrequentFlyerValue?.frequentFlyerNumber || '',
      hasValue: existingFrequentFlyerValue?.frequentFlyerNumber ? true : false,
      isDirty: false
    };
  }

  function getIsDirty(newFormGridState) {
    for (const frequentFlyer of newFormGridState) {
      if (frequentFlyer.isDirty === true) {
        return true;
      }
    }

    return false;
  }

  return {
    isLoading: frequentFlyerTypeState.isLoading,
    isDirty: isDirtyState,
    formGridState,
    onUpdateFormGridState,
    onSubmitFormGrid,
    onCancelClicked
  };
};

export default useFrequentFlyerFormGrid;