import { useEffect } from 'react';

import validate from './CoachPersonAddFormValidation';


import useForm from "../../../../common/utils/UseForm";
import { BLANK_DATE_STRING, DEFAULT_ID } from '../../../../common/utils/Constants';

const INITIAL_FORM_STATE = {
  coachPersonId: DEFAULT_ID,
  coachPersonName: '',
  effectiveDate: BLANK_DATE_STRING,
  expirationDate: BLANK_DATE_STRING,
  hasNoExpirationDate: false
};

const useCoachPersonAddForm = (onSubmitFormCallback, onIsDirtyChanged, onErrorCaught) => {
  const { formState, errorState, isDirty, isSubmitting, setErrors, setFormData, handleSubmit,
    updateFormState, onValueTextPairChanged, resetForm
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  useEffect(() => {
    try { // reset form to initial state after add
      if (isDirty === false && isSubmitting === false) {
        setFormData(INITIAL_FORM_STATE);
      }

      if (onIsDirtyChanged) {
        onIsDirtyChanged(isDirty);
      }
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : setErrors({ formError: e?.message });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, isSubmitting]);

  return {
    isDirty,
    formState,
    errorState,
    resetForm,
    handleSubmit,
    updateFormState,
    onValueTextPairChanged
  };
};

export default useCoachPersonAddForm;