import { useEffect, useState } from "react";

const useNTContactSPSuggestionsFormGrid = (multiFormState, addressSuggestions, onSubmitFormCallback) => {
  const [formGridState, setFormGridState] = useState([]);

  const onSubmitFormGrid = (event) => {
    event?.preventDefault();

    const newMultiFormState = JSON.parse(JSON.stringify(multiFormState));

    for (let i = 0; i < newMultiFormState.contactAddresses.length; i++) {
      const addressSuggestion = formGridState.find(x => x.id === newMultiFormState.contactAddresses[i].id);

      if (addressSuggestion) {
        for (const [key, value] of Object.entries(addressSuggestion.isSelectedValues)) {
          if (value === true) {
            newMultiFormState.contactAddresses[i].values[key] = addressSuggestion.suggestedValues[key];
          }
        }
      }
    }

    onSubmitFormCallback(newMultiFormState);
  };

  const onUpdateIsSelected = (contactAddressId, valueKey) => {
    const newFormGridState = JSON.parse(JSON.stringify(formGridState));

    for (let i = 0; i < newFormGridState.length; i++) {
      if (newFormGridState[i].id === contactAddressId) {
        newFormGridState[i].isSelectedValues[valueKey] = !newFormGridState[i].isSelectedValues[valueKey];
      }
    }

    setFormGridState(newFormGridState);
  };

  useEffect(() => {
    const element = document.querySelector(`#suggestion-form-top`);

    if (element) {
      element.scrollIntoView();
    }
  }, []);

  useEffect(() => {
    const newFormGridState = [];

    for (let i = 0; i < addressSuggestions.length; i++) {
      const newAddressSuggestionFormObj = createAddressSuggestionFormObj(addressSuggestions[i]);

      newFormGridState.push(newAddressSuggestionFormObj);
    }

    setFormGridState(newFormGridState);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressSuggestions]);

  const createAddressSuggestionFormObj = (addressSuggestionObj) => {
    const newAddressSuggestionFormObj = {
      ...addressSuggestionObj,
      isSelectedValues: {}
    };

    for (const [key, value] of Object.entries(addressSuggestionObj.suggestedValues)) {
      if (value) {
        newAddressSuggestionFormObj.isSelectedValues[key] = false;
      }
    }

    return newAddressSuggestionFormObj;
  };

  return {
    formGridState,
    onSubmitFormGrid,
    onUpdateIsSelected
  }
};

export default useNTContactSPSuggestionsFormGrid;