import { Fragment } from 'react';

import EditIcon from '../../../../../../common/components/icons/EditIcon';
import PlusIcon from '../../../../../../common/components/icons/PlusIcon';

import { convertNumberToCurrencyString } from '../../../../../../common/utils/CurrencyUtils';
import { formatDate } from '../../../../../../common/utils/DateFunctions';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const DetailGridRow = ({ offeringInstance, additionalInstance, onEditAdditionalInstance }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}>
      &nbsp;
      {additionalInstance.canEditAdditionalInstance === true &&
        <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEditAdditionalInstance(e, offeringInstance, additionalInstance.offeringInstanceOrgUnitId)}>
          <EditIcon toolTipText='Edit Offering Period' />
        </button>
      }
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Start Date - End Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {additionalInstance.registrationStartDate && additionalInstance.registrationEndDate
            ? <span>{formatDate(additionalInstance.registrationStartDate)} &nbsp; - &nbsp; {formatDate(additionalInstance.registrationEndDate)}</span>
            : <span>&nbsp;</span>
          }
        </div>
      </div>
    </div>
  </div>
);

const DetailGrid = ({ offeringInstance, additionalInstances, onEditAdditionalInstance }) => (
  <div className={global.SmallTableDetailTable}>
    {additionalInstances.map((additionalInstance, i) => <DetailGridRow key={i} offeringInstance={offeringInstance} additionalInstance={additionalInstance} onEditAdditionalInstance={onEditAdditionalInstance} />)}
  </div>
);

const GridRow = ({ offeringInstance, onEditInstance, onAddAdditionalInstance, onEditAdditionalInstance }) => (
  <Fragment>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>
        <span>{offeringInstance.offeringName || ''}</span>&nbsp;
        {offeringInstance.canAddAdditionalInstance === true &&
          <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onAddAdditionalInstance(e, offeringInstance)}>
            <PlusIcon toolTipText='Add Offering Period' />
          </button>
        }
        {offeringInstance.canEditInstance === true &&
          <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEditInstance(e, offeringInstance)}>
            <EditIcon toolTipText='Edit Offering' />
          </button>
        }
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Start Date - End Date
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {offeringInstance.registrationStartDate && offeringInstance.registrationEndDate
              ? <span>{formatDate(offeringInstance.registrationStartDate)} &nbsp; - &nbsp; {formatDate(offeringInstance.registrationEndDate)}</span>
              : <span>&nbsp;</span>
            }
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Age Range
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {offeringInstance.ageStart} &nbsp; - &nbsp; {offeringInstance.ageEnd}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Price
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            ${convertNumberToCurrencyString(offeringInstance.feeAmount)}{offeringInstance.renewalFeeAmount && offeringInstance.renewalFeeAmount !== offeringInstance.feeAmount ? ` ($${convertNumberToCurrencyString(offeringInstance.renewalFeeAmount)})` : ''}
          </div>
        </div>
      </div>
    </div>
    {Array.isArray(offeringInstance.offeringInstanceOrgUnit) && offeringInstance.offeringInstanceOrgUnit.length > 0 &&
      <DetailGrid offeringInstance={offeringInstance} additionalInstances={offeringInstance.offeringInstanceOrgUnit} onEditAdditionalInstance={onEditAdditionalInstance} />
    }
  </Fragment>

);

const OfferingInstanceOrgUnitGridSmall = ({ gridData, isLoading, noResultsMessage, onEditInstance,
  onAddAdditionalInstance, onEditAdditionalInstance }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      ) : Array.isArray(gridData) && gridData.length > 0
        ? gridData.map((offeringInstance, i) => <GridRow key={i} offeringInstance={offeringInstance} onEditInstance={onEditInstance} onAddAdditionalInstance={onAddAdditionalInstance} onEditAdditionalInstance={onEditAdditionalInstance} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>{noResultsMessage}</div>
            </div>
          </div>
        )
    }
  </div>
);

export default OfferingInstanceOrgUnitGridSmall;