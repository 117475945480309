import useOfferingInstanceEditForm from "./UseOfferingInstanceEditForm";

import ReadOnly from "../../../../../../common/components/readOnly/ReadOnly";
import DatePicker from "../../../../../../common/components/datepickers/DatePicker";
import CurrencyInput from "../../../../../../common/components/inputs/CurrencyInput";
import PrimaryButton from "../../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../../common/components/buttons/SecondaryButton";

import { convertNumberToCurrencyString } from "../../../../../../common/utils/CurrencyUtils";

const OfferingInstanceEditForm = ({ reportPeriodObj, offeringInstanceObj, submitButtonText = 'Save',
  onSubmitFormCallback, onBackClicked, onDeleteClicked }) => {
  const {
    formState,
    errorState,
    updateFormState,
    handleSubmit
  } = useOfferingInstanceEditForm(onSubmitFormCallback, reportPeriodObj, offeringInstanceObj);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="Season Year"
            name="periodName"
            value={reportPeriodObj.periodName} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="Available Offerings"
            name="offeringName"
            value={offeringInstanceObj.offeringName} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <CurrencyInput
            label="Fee Amount*"
            name="feeAmount"
            value={formState.feeAmount}
            error={errorState.feeAmount}
            message={errorState.feeAmount}
            onChange={(value) => { updateFormState('feeAmount', value); }} />
        </div>
        {formState.isClubOffering === true &&
          <div className="col-xs-12 col-sm-6 col-md-4">
            <CurrencyInput
              label="Renewal Fee Amount*"
              name="renewalFeeAmount"
              value={formState.renewalFeeAmount}
              error={errorState.renewalFeeAmount}
              message={errorState.renewalFeeAmount}
              onChange={(value) => { updateFormState('renewalFeeAmount', value); }} />
          </div>
        }
        <div className="col-xs-12 col-sm-6 col-md-4">
          <DatePicker
            label="Default Registration Start Date*"
            name="defaultRegStartDate"
            value={formState.defaultRegStartDate}
            error={errorState.defaultRegStartDate}
            message={errorState.defaultRegStartDate}
            onChange={(value) => { updateFormState('defaultRegStartDate', value); }}
            countOfYears={35}
            startYearOffset={-20} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <DatePicker
            label="Default Registration End Date*"
            name="defaultRegEndDate"
            value={formState.defaultRegEndDate}
            error={errorState.defaultRegEndDate}
            message={errorState.defaultRegEndDate}
            onChange={(value) => { updateFormState('defaultRegEndDate', value); }}
            countOfYears={35}
            startYearOffset={-20} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <DatePicker
            label="Default Effective Start Date*"
            name="defaultEffectiveDate"
            value={formState.defaultEffectiveDate}
            error={errorState.defaultEffectiveDate}
            message={errorState.defaultEffectiveDate}
            onChange={(value) => { updateFormState('defaultEffectiveDate', value); }}
            countOfYears={35}
            startYearOffset={-20} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <DatePicker
            label="Default Expiration End Date*"
            name="defaultExpirationDate"
            value={formState.defaultExpirationDate}
            error={errorState.defaultExpirationDate}
            message={errorState.defaultExpirationDate}
            onChange={(value) => { updateFormState('defaultExpirationDate', value); }}
            countOfYears={35}
            startYearOffset={-20} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="Maximum LSC Fee Amount"
            name="maxLscFeeAmount"
            value={offeringInstanceObj.maxLscFeeAmount === null
              ? 'No Maximum'
              : `$${convertNumberToCurrencyString(offeringInstanceObj.maxLscFeeAmount)}`
            } />
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type='submit'>{submitButtonText}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>&nbsp;
          <SecondaryButton type="button" onClick={(e) => onDeleteClicked(e, formState)}>Delete</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default OfferingInstanceEditForm;