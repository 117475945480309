import useOfferingInstanceOrgUnitAddAdditionalForm from "./UseOfferingInstanceOrgUnitAddAdditionalForm";

import ReadOnly from "../../../../../../common/components/readOnly/ReadOnly";
import DatePicker from "../../../../../../common/components/datepickers/DatePicker";
import PrimaryButton from "../../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../../common/components/buttons/SecondaryButton";

import { convertNumberToCurrencyString } from "../../../../../../common/utils/CurrencyUtils";

const OfferingInstanceOrgUnitAddAdditionalForm = ({ reportPeriodObj, orgUnitObj, offeringInstanceObj,
  hideOrgUnitName = false, submitButtonText = 'Save', onSubmitFormCallback, onBackClicked }) => {
  const {
    formState,
    errorState,
    updateFormState,
    handleSubmit
  } = useOfferingInstanceOrgUnitAddAdditionalForm(onSubmitFormCallback, reportPeriodObj, orgUnitObj, offeringInstanceObj);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="Season Year"
            name="periodName"
            value={reportPeriodObj?.periodName} />
        </div>
        {hideOrgUnitName !== true &&
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="LSC"
              name="orgUnitName"
              value={orgUnitObj?.orgUnitName} />
          </div>
        }
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="Offering Name"
            name="offeringName"
            value={offeringInstanceObj?.offeringName} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="Maximum Fee Amount"
            name="maxLscFeeAmount"
            value={offeringInstanceObj?.maxLscFeeAmount === null
              ? 'No Maximum'
              : `$${convertNumberToCurrencyString(offeringInstanceObj.maxLscFeeAmount)}`
            } />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="Fee Amount"
            name="feeAmount"
            value={`$${convertNumberToCurrencyString(offeringInstanceObj?.feeAmount)}`} />
        </div>
        {offeringInstanceObj?.isClubRegistrationOffering === true &&
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="Renewal Fee Amount"
              name="renewalFeeAmount"
              value={`$${convertNumberToCurrencyString(offeringInstanceObj?.renewalFeeAmount)}`} />
          </div>
        }
        <div className="col-xs-12 col-sm-6 col-md-4">
          <DatePicker
            label="Registration Start Date*"
            name="registrationStartDate"
            value={formState.registrationStartDate}
            error={errorState.registrationStartDate}
            message={errorState.registrationStartDate}
            onChange={(value) => { updateFormState('registrationStartDate', value); }}
            countOfYears={35}
            startYearOffset={-20} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <DatePicker
            label="Registration End Date*"
            name="registrationEndDate"
            value={formState.registrationEndDate}
            error={errorState.registrationEndDate}
            message={errorState.registrationEndDate}
            onChange={(value) => { updateFormState('registrationEndDate', value); }}
            countOfYears={35}
            startYearOffset={-20} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <DatePicker
            label="Effective Start Date*"
            name="effectiveDate"
            value={formState.effectiveDate}
            error={errorState.effectiveDate}
            message={errorState.effectiveDate}
            onChange={(value) => { updateFormState('effectiveDate', value); }}
            countOfYears={35}
            startYearOffset={-20} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <DatePicker
            label="Expiration End Date*"
            name="expirationDate"
            value={formState.expirationDate}
            error={errorState.expirationDate}
            message={errorState.expirationDate}
            onChange={(value) => { updateFormState('expirationDate', value); }}
            countOfYears={35}
            startYearOffset={-20} />
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type='submit'>{submitButtonText}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default OfferingInstanceOrgUnitAddAdditionalForm;