import { Fragment } from 'react';

import useNationalTeamContactDetail from './UseNationalTeamContactDetail';

// import NTContactForm from '../../../components/forms/nationalTeamContacts/NTContactForm';
import NTContactSPForm from '../../../components/forms/nationalTeamContactSP/NTContactSPForm';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import { LOADING_MSG } from '../../../../common/utils/Constants';

const NationalTeamContactDetail = () => {
  const {
    isLoading,
    contactObj,
    hasOtherSelfContact,
    isSelfContact,
    onCancelClicked,
    onSubmitContactForm
  } = useNationalTeamContactDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{contactObj ? 'Edit Contact' : 'Add Contact'}</Headings.H3>
        </div>
      </div>
      {contactObj !== undefined && (
        // <NTContactForm
        //   contactObj={contactObj}
        //   contactPhonesArray={contactObj?.personNationalTeamContactPhone}
        //   submitButtonText={contactObj ? 'Save' : 'Add Contact'}
        //   isRelationshipTypeReadonly={isSelfContact === true}
        //   excludeSelfRelationshipType={hasOtherSelfContact === true}
        //   onSubmitFormCallback={onSubmitContactForm}
        //   onSecondaryButtonClicked={onCancelClicked} />
        <NTContactSPForm
          contactObj={contactObj}
          submitButtonText={contactObj ? 'Save' : 'Add Contact'}
          isRelationshipTypeReadonly={isSelfContact === true}
          excludeSelfRelationshipType={hasOtherSelfContact === true}
          onSubmitFormCallback={onSubmitContactForm}
          onSecondaryButtonClicked={onCancelClicked} />
      )}
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment >
  );
};

export default NationalTeamContactDetail;