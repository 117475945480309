import { useEffect, useState } from 'react';

import validate from './NTSupportStaffAddRoleFormValidation';

import useSupportStaffTypeData from '../../../state/supportStaffType/UseSupportStaffTypeData';

import useForm from '../../../../common/utils/UseForm';
import { BLANK_DATE_STRING, DEFAULT_ID } from '../../../../common/utils/Constants';
import { REGISTRATION_SUPPORT_STAFF_TYPE_NAMES } from './NTSupportStaffFormConstants';

const INITIAL_FORM_STATE = {
  supportStaffTypeId: DEFAULT_ID,
  supportStaffTypeName: '',
  effectiveDate: BLANK_DATE_STRING,
  expirationDate: BLANK_DATE_STRING,
  hasNoExpirationDate: false
};

const useNTSupportStaffAddRoleForm = (supportStaffObj, onSubmitFormCallback, onIsDirtyChanged, onErrorCaught) => {
  const { supportStaffTypeState } = useSupportStaffTypeData();
  const { isDirty, formState, errorState, handleSubmit, setErrors, updateFormState, onValueTextPairChanged
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);
  const [optionsState, setOptionsState] = useState([]);

  useEffect(() => {
    try {
      if (supportStaffTypeState.dataAsOptionsWithNames) {
        const newOptionsState = supportStaffTypeState.dataAsOptionsWithNames.filter((x) => REGISTRATION_SUPPORT_STAFF_TYPE_NAMES.includes(x.name));

        setOptionsState(newOptionsState);
      }
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : setErrors({ formError: e?.message });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportStaffTypeState.dataAsOptionsWithNames]);

  useEffect(() => {
    try {
      if (onIsDirtyChanged) {
        onIsDirtyChanged(isDirty);
      }
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : setErrors({ formError: e?.message });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  return {
    isDirty,
    formState,
    errorState,
    supportStaffTypeOptions: optionsState,
    handleSubmit,
    updateFormState,
    onValueTextPairChanged
  };
};

export default useNTSupportStaffAddRoleForm;