import Input from '../../../../common/components/inputs/Input';

import global from '../../../../common/components/GlobalStyle.module.css';
import style from './FrequentFlyerFormGrid.module.css';

const GridRow = ({ frequentFlyer, onUpdateFormGridState }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span>{frequentFlyer.name || <span>&nbsp;</span>}</span>&nbsp;
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-12', global.SmallTableRowData].join(' ')}>
          <Input
            label={'Frequent Flyer Number'}
            name={`value${frequentFlyer.id}`}
            value={frequentFlyer.value}
            onChange={(newValue) => { onUpdateFormGridState(frequentFlyer.id, newValue) }} />
        </div>
        <div className={['col-xs-12', global.SmallTableRowData].join(' ')}>
          {frequentFlyer.hasValue === true &&
            <button
              className={style.GridActionButton}
              type="button"
              onClick={() => onUpdateFormGridState(frequentFlyer.id, '')}>
              Clear
            </button>
          }
        </div>
      </div>
    </div>
  </div>
);

const FrequentFlyerFormGridSmall = ({ formGridState, onUpdateFormGridState }) => (
  <div className={[global.SmallTable, style.SmallFormGrid, 'hidden-md hidden-lg'].join(' ')}>
    {Array.isArray(formGridState) && formGridState.length > 0
      ? formGridState.map((frequentFlyer, i) => <GridRow key={i} frequentFlyer={frequentFlyer} onUpdateFormGridState={onUpdateFormGridState} />)
      : (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={[global.SmallTableRowInfo, global.Bold].join(' ')}>
            &nbsp;&nbsp;No Frequent Flyer Info
          </div>
        </div>
      )
    }
  </div>
);

export default FrequentFlyerFormGridSmall;