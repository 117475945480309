import {
  FIELD_NAME_PAIR_BIRTHPLACE_COUNTRY, FIELD_NAME_PAIR_BIRTHPLACE_STATE, FIELD_NAME_PAIR_HIGH_SCHOOL_STATE,
  FIELD_NAME_PAIR_HOMETOWN_STATE, FIELD_NAME_PAIR_RESIDENCE_STATE, FIELD_NAME_PAIRS
} from "./PersonBioInfoFormConstants";
import { COUNTRY_CODE_CANADA, COUNTRY_CODE_MEXICO, COUNTRY_CODE_UNITED_STATES } from "../../../../common/utils/Constants";

const createNewPersonBiosFormState = (personBios, getStateIdByName, getCountryIdByName) => {
  let newFormState = {};

  for (const bio of personBios) {
    const fieldNamePair = FIELD_NAME_PAIRS.find((x) => x.bioEntryName === bio.bioEntry?.entryName);

    if (fieldNamePair && bio.textValue) {
      let comboboxId = null;

      switch (fieldNamePair.formStateName) {
        case FIELD_NAME_PAIR_BIRTHPLACE_STATE.formStateName:
        case FIELD_NAME_PAIR_HIGH_SCHOOL_STATE.formStateName:
        case FIELD_NAME_PAIR_HOMETOWN_STATE.formStateName:
        case FIELD_NAME_PAIR_RESIDENCE_STATE.formStateName:
          comboboxId = getStateIdByName(bio.textValue);

          if (comboboxId) {
            newFormState[fieldNamePair.formStateName] = comboboxId;
            newFormState[`${fieldNamePair.formStateName}Name`] = bio.textValue;
          }
          break;
        case FIELD_NAME_PAIR_BIRTHPLACE_COUNTRY.formStateName:
          comboboxId = getCountryIdByName(bio.textValue);

          if (comboboxId) {
            newFormState[fieldNamePair.formStateName] = comboboxId;
            newFormState[`${fieldNamePair.formStateName}Name`] = bio.textValue;
            newFormState.isBirthCountryUSA = comboboxId === COUNTRY_CODE_UNITED_STATES;
            newFormState.isBirthCountryMexico = comboboxId === COUNTRY_CODE_MEXICO;
            newFormState.isBirthCountryCanada = comboboxId === COUNTRY_CODE_CANADA;
          }
          break;
        default:
          newFormState[fieldNamePair.formStateName] = bio.textValue;
      }
    }
  }

  return newFormState;
};

const createNewPersonBiosArray = (formState, personId, personBiosArray, bioEntryArray) => {
  const newPersonBiosArray = [];

  for (const fieldNamePair of FIELD_NAME_PAIRS) {
    const bioEntryId = bioEntryArray.find((x) => x.entryName === fieldNamePair.bioEntryName)?.bioEntryId;

    if (bioEntryId > 0) {
      const existingPersonBio = personBiosArray.find((x) => x.bioEntryId === bioEntryId);

      switch (fieldNamePair.formStateName) {
        case FIELD_NAME_PAIR_BIRTHPLACE_STATE.formStateName:
        case FIELD_NAME_PAIR_HIGH_SCHOOL_STATE.formStateName:
        case FIELD_NAME_PAIR_HOMETOWN_STATE.formStateName:
        case FIELD_NAME_PAIR_RESIDENCE_STATE.formStateName:
        case FIELD_NAME_PAIR_BIRTHPLACE_COUNTRY.formStateName:
          if (formState[`${fieldNamePair.formStateName}Name`].trim()) {
            if (existingPersonBio) {
              existingPersonBio.textValue = formState[`${fieldNamePair.formStateName}Name`].trim();
              newPersonBiosArray.push(existingPersonBio);
            } else {
              newPersonBiosArray.push({
                personId: personId,
                bioEntryId: bioEntryId,
                textValue: formState[`${fieldNamePair.formStateName}Name`].trim(),
                bioEntry: null
              });
            }
          }
          break;
        default:
          if (formState[fieldNamePair.formStateName].trim()) {
            if (existingPersonBio) {
              existingPersonBio.textValue = formState[fieldNamePair.formStateName].trim();
              newPersonBiosArray.push(existingPersonBio);
            } else {
              newPersonBiosArray.push({
                personId: personId,
                bioEntryId: bioEntryId,
                textValue: formState[fieldNamePair.formStateName].trim(),
                bioEntry: null
              });
            }
          }
      }
    }
  }

  return newPersonBiosArray;
};

export { createNewPersonBiosFormState, createNewPersonBiosArray };