import { useEffect } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

import useTestingBodyData from '../../../state/testingBody/UseTestingBodyData';
import useDrugTestStatusData from '../../../state/drugTestStatus/UseDrugTestStatusData';
import usePersonNationalTeamData from '../../../state/personNationalTeam/UsePersonNationalTeamData';
import useNavRoutes from '../../../../common/state/security/UseNavRoutes';

import validate from './NationalTeamMissedDrugTestsDetailValidation';

const useNationalTeamFrequentFlyerDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { personNationalTeamState, putPersonNationalTeam } = usePersonNationalTeamData();
  const { testingBodyState } = useTestingBodyData();
  const { drugTestStatusState } = useDrugTestStatusData();
  const { formState, errorState, handleSubmit, updateFormState, setFormData, onValueTextPairChanged,
    isDirty
  } = useForm(getInitialFormState(), submitFormCallback, validate);

  const onSaveClicked = (e) => {
    if (isDirty === false && formState.personMissingDrugTestId > 0) {
      onCancelClicked(e);
    }
    else {
      handleSubmit(e);
    }
  };

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_NT_MISSING_DRUG_TESTS?.route);
  }

  useEffect(() => {
    if (testingBodyState.isLoaded === true && drugTestStatusState.isLoaded === true) {
      if (location.state && location.state.personMissingDrugTestId && personNationalTeamState.isObjLoaded === true) {
        const personMissingDrugTestId = location.state.personMissingDrugTestId;
        // Edit
        if (personMissingDrugTestId > 0) {
          const selectedMissingDrugTest = personNationalTeamState.objData.missingDrugTests.find(e => e.personMissingDrugTestId === personMissingDrugTestId);
          setFormData({
            ...formState,
            personMissingDrugTestId: personMissingDrugTestId || Constants.DEFAULT_ID,
            testingBodyId: selectedMissingDrugTest.testingBodyId || Constants.DEFAULT_ID,
            drugTestStatusId: selectedMissingDrugTest.drugTestStatusId || Constants.DEFAULT_ID,
            missingDrugTestDate: selectedMissingDrugTest.missingDrugTestDate ? formatDate(selectedMissingDrugTest.missingDrugTestDate) : Constants.BLANK_DATE_STRING,
            notificationFromTestBodyDate: selectedMissingDrugTest.notificationFromTestBodyDate ? formatDate(selectedMissingDrugTest.notificationFromTestBodyDate) : Constants.BLANK_DATE_STRING,
            athleteNotifiedDate: selectedMissingDrugTest.athleteNotifiedDate ? formatDate(selectedMissingDrugTest.athleteNotifiedDate) : Constants.BLANK_DATE_STRING,
            testAppealedDate: selectedMissingDrugTest.testAppealedDate ? formatDate(selectedMissingDrugTest.testAppealedDate) : Constants.BLANK_DATE_STRING,
            testBodyAppealResponseDate: selectedMissingDrugTest.testBodyAppealResponseDate ? formatDate(selectedMissingDrugTest.testBodyAppealResponseDate) : Constants.BLANK_DATE_STRING,
            comments: selectedMissingDrugTest.comments || ''
          })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, personNationalTeamState, testingBodyState, drugTestStatusState]);

  function createPutPersonNationalTeamObject(formState) {
    const personNationalTeamObjToPut = JSON.parse(JSON.stringify(personNationalTeamState.objData));
    // Is Edit
    if (formState.personMissingDrugTestId > 0) {
      const index = personNationalTeamObjToPut.missingDrugTests.findIndex(e => e.personMissingDrugTestId === formState.personMissingDrugTestId);
      personNationalTeamObjToPut.missingDrugTests[index] =
      {
        ...personNationalTeamObjToPut.missingDrugTests[index],
        testingBodyId: formState.testingBodyId,
        drugTestStatusId: formState.drugTestStatusId,
        missingDrugTestDate: formState.missingDrugTestDate,
        notificationFromTestBodyDate: formState.notificationFromTestBodyDate !== Constants.BLANK_DATE_STRING ? formState.notificationFromTestBodyDate : null,
        athleteNotifiedDate: formState.athleteNotifiedDate !== Constants.BLANK_DATE_STRING ? formState.athleteNotifiedDate : null,
        testAppealedDate: formState.testAppealedDate !== Constants.BLANK_DATE_STRING ? formState.testAppealedDate : null,
        testBodyAppealResponseDate: formState.testBodyAppealResponseDate !== Constants.BLANK_DATE_STRING ? formState.testBodyAppealResponseDate : null,
        comments: formState.comments.trim().length > 0 ? formState.comments.trim() : null,
        drugTestStatus: null,
        testingBody: null
      }
    }
    // Is Add
    else {
      let missingDrugTests = personNationalTeamObjToPut.missingDrugTests;

      missingDrugTests.push({
        personId: personNationalTeamObjToPut.personId,
        testingBodyId: formState.testingBodyId,
        drugTestStatusId: formState.drugTestStatusId,
        missingDrugTestDate: formState.missingDrugTestDate,
        notificationFromTestBodyDate: formState.notificationFromTestBodyDate !== Constants.BLANK_DATE_STRING ? formState.notificationFromTestBodyDate : null,
        athleteNotifiedDate: formState.athleteNotifiedDate !== Constants.BLANK_DATE_STRING ? formState.athleteNotifiedDate : null,
        testAppealedDate: formState.testAppealedDate !== Constants.BLANK_DATE_STRING ? formState.testAppealedDate : null,
        testBodyAppealResponseDate: formState.testBodyAppealResponseDate !== Constants.BLANK_DATE_STRING ? formState.testBodyAppealResponseDate : null,
        comments: formState.comments.trim().length > 0 ? formState.comments.trim() : null,
        drugTestStatus: null,
        testingBody: null
      });

      personNationalTeamObjToPut.missingDrugTests = missingDrugTests;
    }

    return personNationalTeamObjToPut;
  }

  async function submitFormCallback(formState) {
    if (personNationalTeamState.isObjLoading === false) {
      const personNationalTeamObjToPut = createPutPersonNationalTeamObject(formState);
      if (personNationalTeamObjToPut !== null) {
        const putPersonNationalTeamResult = await putPersonNationalTeam(personNationalTeamState.objData.personId, personNationalTeamObjToPut);

        if (putPersonNationalTeamResult) {
          navigate(navRoutes.MEMBER_NT_MISSING_DRUG_TESTS?.route);
        }
      }
    }
  }

  function getInitialFormState() {
    return {
      personMissingDrugTestId: Constants.DEFAULT_ID,
      missingDrugTestDate: Constants.BLANK_DATE_STRING,
      testingBodyId: Constants.DEFAULT_ID,
      drugTestStatusId: Constants.DEFAULT_ID,
      notificationFromTestBodyDate: Constants.BLANK_DATE_STRING,
      athleteNotifiedDate: Constants.BLANK_DATE_STRING,
      testAppealedDate: Constants.BLANK_DATE_STRING,
      testBodyAppealResponseDate: Constants.BLANK_DATE_STRING,
      comments: ''
    };
  }

  return {
    Constants,
    personNationalTeamState,
    formState,
    errorState,
    testingBodyState,
    drugTestStatusState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    onSaveClicked,
    onCancelClicked
  };
};

export default useNationalTeamFrequentFlyerDetail;