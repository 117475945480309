import { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';

import global from '../../../../common/components/GlobalStyle.module.css';

const DetailTable = ({ attachmentDescription }) => (
  <div className={global.SmallTableDetailTable}>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableDetailRowHead}>
        Description
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {attachmentDescription}
          </div>
        </div>
      </div>
    </div>
  </div>
);

const GridRow = ({ attachment, onEditClicked, onDeleteClicked }) => (
  <Fragment>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>
        <span>&nbsp;</span>&nbsp;
        <div className={global.FloatRight}>
          <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onDeleteClicked(e, attachment)}>
            <DeleteIcon />
          </button>
          <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEditClicked(e, attachment)}>
            <EditIcon />
          </button>
        </div>
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            File Type
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {attachment.personNationalAttachmentFileTypeName || <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            File Name
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {attachment.attachmentName || <span>&nbsp;</span>}
          </div>
        </div>
      </div>
    </div>
    {attachment.attachmentDescription && <DetailTable attachmentDescription={attachment.attachmentDescription} />}
  </Fragment>
);

const NTAttachmentsGridSmall = ({ gridData, isLoading, onEditClicked, onDeleteClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      ) : Array.isArray(gridData) && gridData.length > 0
        ? gridData.map((attachment, i) => <GridRow key={i} attachment={attachment} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Attachments</div>
            </div>
          </div>
        )
    }
  </div>
);

export default NTAttachmentsGridSmall;