import { useState, useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import { isPersonObjectDataCurrent } from '../utils/MemberUtils';

import usePersonNationalTeamData from '../../../state/personNationalTeam/UsePersonNationalTeamData';

import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';
import useNavRoutes from '../../../../common/state/security/UseNavRoutes';

const INITIAL_STATE = {
  deleteSupportStaffObj: {},
  deleteSupportStaffRoleObj: {},
  isLastRole: false,
  displayDeleteStaffPopup: false,
  displayDeleteStaffRolePopup: false
};

const useNationalTeamSupportStaff = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { basePersonState } = useBasePersonData();
  const { personNationalTeamState, getPersonNationalTeam, putPersonNationalTeam } = usePersonNationalTeamData();
  const [state, setState] = useState(INITIAL_STATE);

  const onAddSupportStaffClicked = (event) => {
    event?.preventDefault();

    navigate(navRoutes.MEMBER_NT_SUPPORT_STAFF_DETAIL?.route);
  };

  const onAddSupportStaffRoleClicked = (event, supportStaffObj) => {
    event?.preventDefault();

    navigate(navRoutes.MEMBER_NT_SUPPORT_STAFF_TYPE_DETAIL?.route, { state: { supportStaffObj } });
  };

  const onEditSupportStaffClicked = (event, supportStaffObj) => {
    event?.preventDefault();

    navigate(navRoutes.MEMBER_NT_SUPPORT_STAFF_DETAIL?.route, { state: { supportStaffObj } });
  };

  const onEditSupportStaffRoleClicked = (event, supportStaffRoleObj) => {
    event?.preventDefault();
    const supportStaffObj = personNationalTeamState.objData?.supportStaffMembers.find((x) => x.nationalTeamSupportStaffId === supportStaffRoleObj.nationalTeamSupportStaffId);

    navigate(navRoutes.MEMBER_NT_SUPPORT_STAFF_TYPE_DETAIL?.route, { state: { supportStaffObj, supportStaffRoleObj } });
  };

  const onDeleteSupportStaffClicked = (event, supportStaffObj) => {
    event?.preventDefault();

    setState({
      ...state,
      displayDeleteStaffPopup: true,
      deleteSupportStaffObj: supportStaffObj
    });
  };

  const onDeleteSupportStaffRoleClicked = (event, supportStaffRoleObj) => {
    event?.preventDefault();
    const targetSupportStaff = personNationalTeamState.objData?.supportStaffMembers.find((x) => x.nationalTeamSupportStaffId === supportStaffRoleObj.nationalTeamSupportStaffId);

    setState({
      ...state,
      displayDeleteStaffRolePopup: true,
      deleteSupportStaffObj: targetSupportStaff,
      deleteSupportStaffRoleObj: supportStaffRoleObj,
      isLastRole: targetSupportStaff.nationalTeamSupportStaffType.length === 1
    });
  };

  const onDeleteStaffPopupConfirmClicked = (event) => {
    event?.preventDefault();
    const personId = personNationalTeamState.objData.personId;
    const newNTPersonObj = JSON.parse(JSON.stringify(personNationalTeamState.objData));
    const targetIndex = newNTPersonObj.supportStaffMembers.findIndex((x) => x.nationalTeamSupportStaffId === state.deleteSupportStaffObj.nationalTeamSupportStaffId);
    newNTPersonObj.supportStaffMembers.splice(targetIndex, 1);

    setState({ ...INITIAL_STATE });

    putPersonNationalTeam(personId, newNTPersonObj);
  };

  const onDeleteStaffRolePopupConfirmClicked = (event) => {
    event?.preventDefault();
    const personId = personNationalTeamState.objData.personId;
    const newNTPersonObj = JSON.parse(JSON.stringify(personNationalTeamState.objData));
    const targetStaffIndex = newNTPersonObj.supportStaffMembers.findIndex((x) => x.nationalTeamSupportStaffId === state.deleteSupportStaffObj.nationalTeamSupportStaffId);

    if (state.isLastRole === true) {
      newNTPersonObj.supportStaffMembers.splice(targetStaffIndex, 1);
    } else {
      const targetRoleIndex = newNTPersonObj.supportStaffMembers[targetStaffIndex].nationalTeamSupportStaffType.findIndex((x) => x.nationalTeamSupportStaffTypeId === state.deleteSupportStaffRoleObj.nationalTeamSupportStaffTypeId)
      newNTPersonObj.supportStaffMembers[targetStaffIndex].nationalTeamSupportStaffType.splice(targetRoleIndex, 1);
    }

    setState({ ...INITIAL_STATE });

    putPersonNationalTeam(personId, newNTPersonObj);
  };

  const onDeletePopupCancelClicked = (event) => {
    event?.preventDefault();

    setState({ ...INITIAL_STATE });
  };

  useEffect(() => {
    if (personNationalTeamState.isObjLoaded === false
      || !isPersonObjectDataCurrent(basePersonState.objData.personId, personNationalTeamState.objData)) {
      getPersonNationalTeam(basePersonState.objData.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState]);

  return {
    isLoading: personNationalTeamState.isObjLoading,
    gridData: personNationalTeamState.objData?.supportStaffMembers || [],
    state,
    onAddSupportStaffClicked,
    onAddSupportStaffRoleClicked,
    onEditSupportStaffClicked,
    onEditSupportStaffRoleClicked,
    onDeleteSupportStaffClicked,
    onDeleteSupportStaffRoleClicked,
    onDeleteStaffPopupConfirmClicked,
    onDeleteStaffRolePopupConfirmClicked,
    onDeletePopupCancelClicked
  };
};

export default useNationalTeamSupportStaff;