import { BLANK_DATE_STRING } from '../../../../../../common/utils/Constants';
import { convertNumberToCurrencyString } from '../../../../../../common/utils/CurrencyUtils';
import { getTodaysDate } from '../../../../../../common/utils/DateFunctions';
import { isValidDate } from '../../../../../../common/utils/validation';

export const localValidate = (formState) => {
  const today = getTodaysDate();
  const todaysDate = new Date(today);
  const effectiveDate = new Date(formState.effectiveDate);
  const expirationDate = new Date(formState.expirationDate);
  const registrationStartDate = new Date(formState.registrationStartDate);
  const registrationEndDate = new Date(formState.registrationEndDate);
  const reportPeriodStartDate = new Date(formState.reportPeriodStartDate);
  const reportPeriodEndDate = new Date(formState.reportPeriodEndDate);

  let errors = {};

  // Registration Start Date
  if (formState.registrationStartDate === BLANK_DATE_STRING) {
    errors.registrationStartDate = 'Registration Start Date is required';
  } else if (!isValidDate(formState.registrationStartDate)) {
    errors.registrationStartDate = 'Registration Start Date must be a valid date';
  } else if (formState.registrationStartDate === formState.registrationEndDate) {
    errors.registrationStartDate = 'Registration Start and Registration End Date cannot be the same date';
  } else if (todaysDate > registrationStartDate) {
    errors.registrationStartDate = `Registration Start Date cannot be before today's date`;
  } else if (registrationEndDate < registrationStartDate) {
    errors.registrationStartDate = 'Registration Start Date must be before the Registration End Date';
  } else if (registrationStartDate < reportPeriodStartDate || registrationStartDate > reportPeriodEndDate) {
    errors.registrationStartDate = `Registration Start Date cannot fall outside report period(${formState.reportPeriodStartDate} - ${formState.reportPeriodEndDate})`;
  }

  // Registration End Date
  if (formState.registrationEndDate === BLANK_DATE_STRING) {
    errors.registrationEndDate = 'Registration End Date is required';
  } else if (!isValidDate(formState.registrationEndDate)) {
    errors.registrationEndDate = 'Registration End Date must be a valid date';
  } else if (registrationEndDate < registrationStartDate) {
    errors.registrationEndDate = 'Registration End Date must be after the Registration Start Date';
  } else if (registrationEndDate < reportPeriodStartDate || registrationEndDate > reportPeriodEndDate) {
    errors.registrationEndDate = `Registration End Date cannot fall outside report period(${formState.reportPeriodStartDate} - ${formState.reportPeriodEndDate})`;
  }

  // Effective Date
  if (formState.effectiveDate === BLANK_DATE_STRING) {
    errors.effectiveDate = 'Effective Date is required';
  } else if (!isValidDate(formState.effectiveDate)) {
    errors.effectiveDate = 'Effective Date must be a valid date';
  } else if (formState.effectiveDate === formState.expirationDate) {
    errors.effectiveDate = 'Effective Date and Expiration Date cannot be the same date';
  } else if (expirationDate < effectiveDate) {
    errors.effectiveDate = 'Effective Date must be before the Expiration Date';
  }

  // Expiration Date
  if (formState.expirationDate === BLANK_DATE_STRING) {
    errors.expirationDate = 'Expiration Date is required';
  } else if (!isValidDate(formState.expirationDate)) {
    errors.expirationDate = 'Expiration Date must be a valid date';
  } else if (expirationDate < effectiveDate) {
    errors.expirationDate = 'Expiration Date must be after the Effective Date';
  }

  // Existing Registration Dates
  if (Object.keys(errors).length === 0) {
    for (const reg of formState.offeringInstanceOrgUnitRegDates) {
      const existingRegStartDate = new Date(reg.startDate);
      const existingRegEndDate = new Date(reg.endDate);

      if (registrationStartDate <= existingRegEndDate && registrationEndDate >= existingRegStartDate) {
        errors.registrationStartDate = `Registration Dates cannot overlap with existing registration period(${reg.startDate} - ${reg.endDate})`;
        break;
      }
    }
  }

  // Fee Amount
  if (formState.feeAmount < 0) {
    errors.feeAmount = 'Fee Amount must be greater than or equal to 0';
  } else if (formState.feeAmount >= 10000) {
    errors.feeAmount = 'Fee Amount must be less than 10,000';
  } else if (formState.maxLscFeeAmount !== null && formState.feeAmount > formState.maxLscFeeAmount) {
    errors.feeAmount = `Fee Amount exceeds the maximum ($${convertNumberToCurrencyString(formState.maxLscFeeAmount)})`;
  }

  // Renewal Fee Amount
  if (formState.renewalFeeAmount !== null || formState.isClubOffering === true) {
    if (formState.renewalFeeAmount < 0) {
      errors.renewalFeeAmount = 'Renewal Fee Amount must be greater than or equal to 0';
    } else if (formState.renewalFeeAmount >= 10000) {
      errors.renewalFeeAmount = 'Renewal Fee Amount must be less than 10,000';
    } else if (formState.maxLscFeeAmount !== null && formState.renewalFeeAmount > formState.maxLscFeeAmount) {
      errors.renewalFeeAmount = `Renewal Fee Amount exceeds the maximum ($${convertNumberToCurrencyString(formState.maxLscFeeAmount)})`;
    }
  }

  return errors;
}

const OfferingInstanceOrgUnitEditFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default OfferingInstanceOrgUnitEditFormValidation;